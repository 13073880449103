import React, { useEffect, useState } from "react";
import axios from "axios";

const News = () => {
  const [newsArticles, setNewsArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get("https://api.bhk99.com/fetchNews/news");
        setNewsArticles(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []); // This empty dependency array ensures it only runs on initial load

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error fetching news: {error}</p>;

  // Function to determine if the image is base64
  const isBase64 = (str) => {
    const pattern = /^data:image\/[a-zA-Z]+;base64,/;
    return pattern.test(str);
  };

  return (
    <section className="py-6 bg-white">
      <div className="p-4 mt-12">
        <h2 className="text-2xl md:text-4xl font-semibold font-poppins mb-4 md:mb-8 ml-4 md:ml-16 lg:text-center">
          BHK99 in the News!
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 px-4 md:px-16">
          {newsArticles.map((article) => (
            <div
              key={article._id}
              className="bg-white rounded-sm shadow-lg overflow-hidden flex flex-col items-center justify-center p-4 transition-transform transform hover:scale-105 hover:shadow-xl"
            >
              <img
                src={article.imageUrl ? article.imageUrl : "/path/to/default-image.jpg"} // Check if imageUrl is defined
                alt={`News ${article._id}`}
                className="w-[150px] h-[100px] object-cover mb-2"
              />
              <blockquote
                className="text-black italic font-poppins font-semibold text-center px-2 border-l-4 border-gray-400"
                dangerouslySetInnerHTML={{ __html: article.content }} // Render HTML content
              />
            </div>
          ))}
        </div>
        <div className="text-center mt-8 px-4 md:px-16">
          <p className="text-gray-600 font-poppins">
            BHK99, India's No.1 home construction company, provides complete
            start to finish aka a design-to-build service that enables real-time
            tracking, communication, quality checks, and updates on projects
            from day one till the file project delivery day. BHK99 aims to bring
            transparency and trust into the construction space by using tech.
            The quality and progress of projects are mapped using artificial
            intelligence and computer vision, allowing for streamlined
            management architecture and enabling the predictability of the
            entire house construction process.
          </p>
        </div>
      </div>
    </section>
  );
};

export default News;
