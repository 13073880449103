import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddPW = () => {
  const [videoLink, setVideoLink] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.bhk99.com/pw/addPW",
        {
          videoLink,
          description,
        }
      );
      if (response.status === 201) {
        toast.success("Project Walkthrough added successfully!");
        setVideoLink("");
        setDescription("");
      }
    } catch (error) {
      toast.error("Error adding Project Walkthrough");
      console.error("Error:", error);
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        Add Project Walkthrough
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        {/* YouTube Video Link Input */}
        <div>
          <label
            htmlFor="videoLink"
            className="block text-gray-700 font-medium mb-2"
          >
            YouTube Video Link
          </label>
          <input
            type="url"
            id="videoLink"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            placeholder="Enter YouTube video link"
            className="w-full px-4 py-2 border rounded-md text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          />
        </div>

        {/* Description Text Editor */}
        <div>
          <label className="block text-gray-700 font-medium mb-2">
            Description
          </label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            placeholder="Enter a description for the video"
            className="bg-white text-gray-800"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition duration-200"
        >
          Submit
        </button>
      </form>

      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default AddPW;
