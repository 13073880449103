import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ActiveSocialMedia = () => {
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [editingLink, setEditingLink] = useState(null);

  useEffect(() => {
    fetchSocialMediaLinks();
  }, []);

  const fetchSocialMediaLinks = async () => {
    try {
      const response = await axios.get('https://api.bhk99.com/api/social-media-links');
      setSocialMediaLinks(response.data);
    } catch (error) {
      console.error('Error fetching social media links', error);
    }
  };

  const handleEdit = (link) => {
    setEditingLink(link);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://api.bhk99.com/api/social-media-links/${editingLink._id}`, {
        platform: editingLink.platform,
        url: editingLink.url,
      });
      toast.success('Social media link updated successfully');
      setEditingLink(null);
      fetchSocialMediaLinks();
    } catch (error) {
      toast.error('Error updating link');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.bhk99.com/api/social-media-links/${id}`);
      toast.success('Link deleted successfully');
      fetchSocialMediaLinks();
    } catch (error) {
      toast.error('Error deleting link');
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Active Social Media Links</h2>
      <ul className="space-y-4">
        {socialMediaLinks.map((link) => (
          <li key={link._id} className="flex justify-between items-center bg-white p-4 rounded-lg shadow-lg">
            {editingLink && editingLink._id === link._id ? (
              <div className="flex flex-col sm:flex-row items-center w-full gap-2">
                {/* <input
                  type="text"
                  value={editingLink.platform}
                  onChange={(e) => setEditingLink({ ...editingLink, platform: e.target.value })}
                  placeholder="Platform Name"
                  className="flex-grow p-2 border border-gray-300 rounded"
                /> */}
                <input
                  type="url"
                  value={editingLink.url}
                  onChange={(e) => setEditingLink({ ...editingLink, url: e.target.value })}
                  placeholder="Platform URL"
                  className="flex-grow p-2 border border-gray-300 rounded"
                />
                <button onClick={handleUpdate} className="bg-green-500 text-white px-3 py-1 rounded mt-2 sm:mt-0">
                  Save
                </button>
              </div>
            ) : (
              <div className="flex justify-between w-full items-center">
                <span className="text-gray-700 font-medium">{link.platform} link</span>
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEdit(link)}
                    className="bg-orange-500 text-white px-3 py-1 rounded"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(link._id)}
                    className="bg-red-500 text-white px-3 py-1 rounded"
                  >
                    Delete
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
