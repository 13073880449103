import { faHouse, faPerson } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StatsMobile = () => {
  return (
    <div className="px-1 py-2 font-sans text-black">
      <div className="flex flex-wrap justify-between gap-x-[-20px] gap-y-1 max-w-full mx-auto">
        <div className="flex flex-col items-center w-1/6 ml-[20px]">
          <div>
            <h3 className="text-xs font-bold flex items-center">
              <FontAwesomeIcon icon={faHouse} className="mr-0.5 text-xs" />
              <span className="text-[#FE6929] text-xs">3,298</span>
            </h3>
            <p className="text-[8px] text-black mt-0.5"># of Buy Properties</p>
          </div>
        </div>
        <div className="flex flex-col items-center w-1/6 ml-[25px]">
          <div>
            <h3 className="text-xs font-bold flex items-center">
              <FontAwesomeIcon icon={faHouse} className="mr-0.5 text-xs" />
              <span className="text-[#FE6929] text-xs">2,181</span>
            </h3>
            <p className="text-[8px] text-black mt-0.5"># of Sell Properties</p>
          </div>
        </div>
        <div className="flex flex-col items-center w-1/6 ml-[30px]">
          <div>
            <h3 className="text-xs font-bold flex items-center">
              <FontAwesomeIcon icon={faHouse} className="mr-0.5 text-xs" />
              <span className="text-[#FE6929] text-xs">9,316</span>
            </h3>
            <p className="text-[8px] text-black mt-0.5"># of All Properties</p>
          </div>
        </div>
        <div className="flex flex-col items-center w-1/6 mr-[30px]">
          <div>
            <h3 className="text-xs font-bold flex items-center">
              <FontAwesomeIcon icon={faPerson} className="mr-0.5 text-xs" />
              <span className="text-[#FE6929] text-xs">7,191</span>
            </h3>
            <p className="text-[8px] text-black mt-0.5"># of Agents</p>
          </div>
        </div>
      </div>
    </div>
  );
};
