// src/components/Dashboard.js
import React from 'react';
import { FaCogs, FaBox, FaProjectDiagram, FaEnvelope } from 'react-icons/fa';

const Dashboard = () => {
  return (
    <div className="flex flex-wrap gap-6 p-6">
      {/* Active Services */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaCogs className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Active Services</h2>
          <p className="text-xl">15</p>
        </div>
      </div>

      {/* Active Packages */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaBox className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Active Packages</h2>
          <p className="text-xl">8</p>
        </div>
      </div>

      {/* Active Projects */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaProjectDiagram className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Active Projects</h2>
          <p className="text-xl">22</p>
        </div>
      </div>

      {/* Contact Us Messages */}
      <div className="bg-white shadow-md p-6 rounded-lg flex items-center justify-between w-full sm:w-1/2 lg:w-1/4">
        <FaEnvelope className="text-4xl text-[#FE6929]" />
        <div className="text-right">
          <h2 className="text-2xl font-bold">Contact Us</h2>
          <p className="text-xl">12</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
