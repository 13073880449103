import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios"; // Import axios

const AdminTalkToExpert = () => {
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 10; // Set messages per page

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        "https://api.bhk99.com/api/contact-us"
      ); // Use correct backend URL
      setMessages(response.data); // Set the fetched messages to state
    } catch (error) {
      console.error("Error fetching messages:", error);
      toast.error("Failed to load messages.");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  // Function to handle message removal
  const handleRemoveMessage = async (id) => {
    try {
      await axios.delete(
        `https://api.bhk99.com/api/contact-us/${id}`
      ); // Use the correct backend URL
      setMessages(messages.filter((message) => message._id !== id)); // Remove from state
      toast.success("Message removed successfully!");
    } catch (error) {
      console.error("Error removing message:", error);
      toast.error("Failed to remove message.");
    }
  };

  // Function to export all messages to CSV
  const handleExportAllToCSV = () => {
    const csvContent =
      "Full Name,Email,Phone,Meeting Date,Meeting Time\n" +
      messages
        .map(
          (message) =>
            `${message.firstName} ${message.lastName},${message.email},${message.phone},${message.date},${message.time}`
        )
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "messages.csv");
  };

  // Pagination logic
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(
    indexOfFirstMessage,
    indexOfLastMessage
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      <ToastContainer />
      <div className="bg-white p-6 shadow-lg rounded-lg max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-center text-gray-700">
          ContactUs Page Messages
        </h2>

        <ul className="space-y-4">
          {currentMessages.map((message) => (
            <li key={message._id} className="border-b pb-4">
              <p>
                <strong>Name:</strong> {message.firstName} {message.lastName}
              </p>
              <p>
                <strong>Email:</strong> {message.email}
              </p>
              <p>
                <strong>Phone:</strong> {message.phone}
              </p>
              <p>
                <strong>Date:</strong> {message.date}
              </p>
              <p>
                <strong>Time:</strong> {message.time}
              </p>
              <button
                onClick={() => handleRemoveMessage(message._id)} // Remove button functionality
                className="bg-orange-500 text-white px-4 py-2 rounded-lg shadow hover:bg-orange-600 transition"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <nav>
            <ul className="flex space-x-2">
              {Array.from(
                { length: Math.ceil(messages.length / messagesPerPage) },
                (_, index) => (
                  <li key={index} className="cursor-pointer">
                    <button
                      onClick={() => paginate(index + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-orange-500 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  </li>
                )
              )}
            </ul>
          </nav>
        </div>

        <button
          onClick={handleExportAllToCSV}
          className="mt-4 bg-orange-500 text-white px-4 py-2 rounded"
        >
          Export All to CSV
        </button>
      </div>
    </div>
  );
};

export default AdminTalkToExpert;
