import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

let currentProjectId = 0;

const AddProjectForm = ({ addProject }) => {
  const [formData, setFormData] = useState({
    id: null,
    cityName: "",
    title: "",
    propertyType: "",
    plotDimensions: "",
    houseBudget: "",
    roadFacing: "",
    numberOfFloors: "",
    typeOfConstruction: "",
    numberOfBedrooms: "",
    vastuCompliant: false,
    addOnHome: false,
    projectDetails: "",
    threeDModelLink: "",
    propertyImage: null,
    additionalImages: [],
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleQuillChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      projectDetails: value,
    }));
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    setFormData((prevData) => ({
      ...prevData,
      additionalImages: [...prevData.additionalImages, ...files],
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    currentProjectId += 1;
    const projectWithId = { ...formData, id: currentProjectId };

    try {
      // Send the form data to the backend
      const formDataToSend = new FormData();
      for (let key in projectWithId) {
        if (key === "additionalImages") {
          projectWithId[key].forEach((image) => {
            formDataToSend.append("additionalImages", image);
          });
        } else if (key === "propertyImage") {
          // Add propertyImage if it's not null
          if (projectWithId[key]) {
            formDataToSend.append("propertyImage", projectWithId[key]);
          }
        } else {
          formDataToSend.append(key, projectWithId[key]);
        }
      }

      // Log the form data to debug
      for (let pair of formDataToSend.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      await axios.post(
        "https://api.bhk99.com/add/addProject",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Project created successfully!");
    } catch (error) {
      console.error("Error creating project:", error);
      toast.error("Failed to create project");
    }

    setFormData({
      id: null,
      cityName: "",
      title: "",
      propertyType: "",
      plotDimensions: "",
      houseBudget: "",
      roadFacing: "",
      numberOfFloors: "",
      typeOfConstruction: "",
      numberOfBedrooms: "",
      vastuCompliant: false,
      addOnHome: false,
      projectDetails: "",
      threeDModelLink: "",
      propertyImage: null,
      additionalImages: [],
    });
  };

  return (
    <div className="max-w-2xl mx-auto p-6 mt-[50px] bg-white rounded-3xl shadow-md">
      <h2 className="text-2xl font-semibold mb-6 text-center text-[#FE6929]">
        Add a New Project
      </h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {/* City Name */}
          <div>
            <label
              htmlFor="cityName"
              className="block text-sm font-medium text-gray-700"
            >
              City Name
            </label>
            <input
              type="text"
              name="cityName"
              id="cityName"
              value={formData.cityName}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
              placeholder="Enter city name"
              required
            />
          </div>

          {/* Title */}
          <div>
            <label
              htmlFor="title"
              className="block text-sm font-medium text-gray-700"
            >
              Title
            </label>
            <input
              type="text"
              name="title"
              id="title"
              value={formData.title}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
              placeholder="Enter project title"
              required
            />
          </div>

          {/* Bunglow Status */}
          <div>
            <label
              htmlFor="propertyType"
              className="block text-sm font-medium text-gray-700"
            >
              Property Type
            </label>
            <select
              name="propertyType"
              id="propertyType"
              value={formData.propertyType}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            >
              <option value="">Select property type</option>
              <option value="Plot">Plot</option>
              <option value="Studio">Studio</option>
              <option value="Duplex">Duplex</option>
              <option value="Pent House">Pent House</option>
              <option value="Villa">Villa</option>
              <option value="Apartments">Apartments</option>
              <option value="Independent House">Independent House</option>
              <option value="Builder Floor">Builder Floor</option>
            </select>
          </div>

          {/* House Budget */}
          <div>
            <label
              htmlFor="houseBudget"
              className="block text-sm font-medium text-gray-700"
            >
              House Budget
            </label>
            <input
              type="text"
              name="houseBudget"
              id="houseBudget"
              value={formData.houseBudget}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
              placeholder="Enter house budget"
            />
          </div>

          {/* Road Facing */}
          <div>
            <label
              htmlFor="roadFacing"
              className="block text-sm font-medium text-gray-700"
            >
              Road Facing
            </label>
            <select
              name="roadFacing"
              id="roadFacing"
              value={formData.roadFacing}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            >
              <option value="">Select road facing</option>
              <option value="North">North</option>
              <option value="East">East</option>
              <option value="West">West</option>
              <option value="South">South</option>
            </select>
          </div>

          {/* Number of Floors */}
          <div>
            <label
              htmlFor="numberOfFloors"
              className="block text-sm font-medium text-gray-700"
            >
              Number of Floors
            </label>
            <select
              name="numberOfFloors"
              id="numberOfFloors"
              value={formData.numberOfFloors}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            >
              <option value="">Select number of floors</option>
              <option value="G">G</option>
              <option value="G+1">G+1</option>
              <option value="G+2">G+2</option>
              <option value="G+3">G+3</option>
            </select>
          </div>

          {/* Plot Dimensions */}
          <div>
            <label
              htmlFor="plotDimensions"
              className="block text-sm font-medium text-gray-700"
            >
              Plot Dimensions
            </label>
            <select
              name="plotDimensions"
              id="plotDimensions"
              value={formData.plotDimensions}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            >
              <option value="">Select plot dimensions</option>
              <option value="20x20 sqft">20x20 sqft</option>
              <option value="20x30 sqft">20x30 sqft</option>
              <option value="20x40 sqft">20x40 sqft</option>
              <option value="20x50 sqft">20x50 sqft</option>
              <option value="30x20 sqft">30x20 sqft</option>
              <option value="30x30 sqft">30x30 sqft</option>
              <option value="30x40 sqft">30x40 sqft</option>
              <option value="30x50 sqft">30x50 sqft</option>
              <option value="40x20 sqft">40x20 sqft</option>
              <option value="40x30 sqft">40x30 sqft</option>
              <option value="40x40 sqft">40x40 sqft</option>
              <option value="40x50 sqft">40x50 sqft</option>
            </select>
          </div>

          {/* Type of Construction */}
          <div>
            <label
              htmlFor="typeOfConstruction"
              className="block text-sm font-medium text-gray-700"
            >
              Type of Construction
            </label>
            <select
              name="typeOfConstruction"
              id="typeOfConstruction"
              value={formData.typeOfConstruction}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            >
              <option value="">Select type of construction</option>
              <option value="Brick">Brick</option>
              <option value="Concrete">Concrete</option>
              <option value="Wood">Wood</option>
              <option value="Steel">Steel</option>
            </select>
          </div>

          {/* Number of Bedrooms */}
          <div>
            <label
              htmlFor="numberOfBedrooms"
              className="block text-sm font-medium text-gray-700"
            >
              Number of Bedrooms
            </label>
            <select
              name="numberOfBedrooms"
              id="numberOfBedrooms"
              value={formData.numberOfBedrooms}
              onChange={handleChange}
              className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            >
              <option value="">Select number of bedrooms</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4+</option>
            </select>
          </div>

          {/* Vastu Compliant */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="vastuCompliant"
              id="vastuCompliant"
              checked={formData.vastuCompliant}
              onChange={handleChange}
              className="h-5 w-5 text-[#FE6929] border-gray-300 rounded focus:ring-[#FE6929]"
            />
            <label
              htmlFor="vastuCompliant"
              className="ml-2 text-sm font-medium text-gray-700"
            >
              Vastu Compliant
            </label>
          </div>

          {/* Add-On Home */}
          <div className="flex items-center">
            <input
              type="checkbox"
              name="addOnHome"
              id="addOnHome"
              checked={formData.addOnHome}
              onChange={handleChange}
              className="h-5 w-5 text-[#FE6929] border-gray-300 rounded focus:ring-[#FE6929]"
            />
            <label
              htmlFor="addOnHome"
              className="ml-2 text-sm font-medium text-gray-700"
            >
              Add-On Home
            </label>
          </div>
        </div>

        {/* Project Details */}
        <div>
          <label
            htmlFor="projectDetails"
            className="block text-sm font-medium text-gray-700"
          >
            Project Details
          </label>
          <ReactQuill
            value={formData.projectDetails}
            onChange={handleQuillChange}
            className="mt-1"
          />
        </div>

        {/* 3D Model Link */}
        <div>
          <label
            htmlFor="threeDModelLink"
            className="block text-sm font-medium text-gray-700"
          >
            3D Model Link
          </label>
          <input
            type="url"
            name="threeDModelLink"
            id="threeDModelLink"
            value={formData.threeDModelLink}
            onChange={handleChange}
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
            placeholder="Enter 3D model link"
          />
        </div>

        {/* Property Image Upload */}
        <div>
          <label
            htmlFor="propertyImage"
            className="block text-sm font-medium text-gray-700"
          >
            Property Image
          </label>
          <input
            type="file"
            name="propertyImage"
            id="propertyImage"
            accept="image/*"
            onChange={(e) => {
              setFormData((prevData) => ({
                ...prevData,
                propertyImage: e.target.files[0],
              }));
            }}
            className="mt-1 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
          />
        </div>

        {/* Additional Images Upload */}
        <div>
          <label
            htmlFor="additionalImages"
            className="block text-sm font-medium text-gray-700"
          >
            Additional Images
          </label>
          <input
            type="file"
            name="additionalImages"
            id="additionalImages"
            accept="image/*"
            multiple
            onChange={handleAdditionalImagesChange}
            className="mt-1 block w-full border border-gray-300 rounded-md focus:outline-none focus:ring-[#FE6929] focus:border-[#FE6929]"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-[#FE6929] text-white font-semibold py-2 rounded-md hover:bg-[#d86025]"
        >
          Add Project
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddProjectForm;
