import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

const DeleteTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState("");
  const [showDeleteButton, setShowDeleteButton] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchTestimonials/get-testimonials"
        );
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const handleSelectChange = (e) => {
    const selectedId = e.target.value;
    setSelectedTestimonial(selectedId);
    setShowDeleteButton(selectedId !== "");
  };

  const handleDelete = async () => {
    try {
      await axios.delete(
        `https://api.bhk99.com/deleteTestimonials/delete-testimonials/${selectedTestimonial}`
      );
      setTestimonials((prevTestimonials) =>
        prevTestimonials.filter(
          (testimonial) => testimonial._id !== selectedTestimonial
        )
      );
      setSelectedTestimonial("");
      setShowDeleteButton(false);

      // Show success notification
      toast.success("Testimonial deleted successfully!");
    } catch (error) {
      console.error("Error deleting testimonial:", error);
      // Show error notification
      toast.error("Failed to delete testimonial.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Delete Customer Testimonial</h2>
      <div className="mb-4">
        <label
          htmlFor="testimonialSelect"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select a testimonial to delete
        </label>
        <select
          id="testimonialSelect"
          value={selectedTestimonial}
          onChange={handleSelectChange}
          className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400"
        >
          <option value="">-- Select a customer --</option>
          {testimonials.map((testimonial) => (
            <option key={testimonial._id} value={testimonial._id}>
              {testimonial.customerName}
            </option>
          ))}
        </select>
      </div>

      {showDeleteButton && (
        <button
          onClick={handleDelete}
          className="w-full bg-red-600 hover:bg-red-700 text-white font-semibold py-2 rounded-md transition duration-200"
        >
          Delete Testimonial
        </button>
      )}

      {/* ToastContainer for Toast notifications */}
      <ToastContainer />
    </div>
  );
};

export default DeleteTestimonials;
