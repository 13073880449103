import React, { useEffect, useState } from "react";
import axios from "axios";
import ContactMobile from "./ContactMobile";
import ContactUs from "./ContactUs";
import { Stats } from "./Stats";
import { StatsMobile } from "./StatsMobile";

export const Hero = () => {
  const [homeHeroImage, setHomeHeroImage] = useState("");

  useEffect(() => {
    const fetchHeroImages = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/hero/getHero"
        );
        console.log(response.data);
        setHomeHeroImage(`data:image/jpeg;base64,${response.data.homehero}`);
      } catch (error) {
        console.error("Error fetching hero image:", error);
      }
    };

    fetchHeroImages();
  }, []);

  return (
    <>
      <section
        className="relative h-[300px] md:h-[850px] flex flex-col items-start justify-start bg-center"
        style={{
          backgroundImage: `url(${homeHeroImage || "images/hero.jpg"})`,
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-left text-white p-8 lg:mt-16 lg:ml-16 md:mt-40">
          <h1 className="text-4xl tracking-tight leading-10 font-bold font-poppins text-white sm:text-5xl sm:leading-none md:text-6xl lg:text-7xl ">
            Build your
          </h1>
          <span className="block text-4xl tracking-tight leading-10 font-bold font-poppins text-[#FE6929] sm:text-5xl sm:leading-none md:text-6xl lg:text-7xl">
            Dream Home
          </span>
          <span className="block text-base tracking-tight leading-6 font-normal font-poppins text-white sm:text-xs sm:leading-7 md:text-xl lg:text-2xl mt-12 hidden md:block">
            Step into the home you've always dreamed of,
            <br />
            built with the highest standard of quality
          </span>
          <span className="block text-base tracking-tight leading-6 font-normal font-poppins text-white sm:text-xs sm:leading-7 md:text-xl lg:text-2xl mt-12 md:hidden lg:hidden">
            Step into the home you've always dreamed of, built with the highest
            standard of quality
          </span>
        </div>
        <div className="hidden md:block absolute top-4 right-4 z-20 mt-20 mr-16 sm:top-8 sm:right-8 md:mr-[-20px] md:mt-10">
          <ContactUs />
        </div>
        <div className="hidden md:block absolute bottom-0 left-1/2 transform -translate-x-1/2 z-20 mb-8 sm:bottom-16 sm:left-auto sm:right-4 sm:transform-none mb-0 mr-96 md:mb-0 md:ml-60">
          <Stats />
        </div>
      </section>

      <div className="block md:hidden">
        <div className="mt-8">
          <StatsMobile />
        </div>
        <div className="mt-4">
          <ContactMobile />
        </div>
      </div>
    </>
  );
};
