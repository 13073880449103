import React, { useState } from "react";

const FilterDivMobile = ({ onFiltersChange }) => {
  // State to keep track of selected filters
  const [selectedFilters, setSelectedFilters] = useState({});

  // State to keep track of open dropdowns
  const [openDropdowns, setOpenDropdowns] = useState({
    plotDimensions: false,
    houseBudget: false,
    roadFacing: false,
    numberOfFloors: false,
    typeOfConstruction: false,
    numberOfBedrooms: false,
    vastuCompliant: false,
    specialRequirements: false,
  });

  // Filter categories and options
  const filterOptions = {
    plotDimensions: [
      "20x20 sqft",
      "20x30 sqft",
      "20x40 sqft",
      "20x50 sqft",
      "30x20 sqft",
      "30x30 sqft",
      "30x40 sqft",
      "30x50 sqft",
      "40x20 sqft",
      "40x30 sqft",
      "40x40 sqft",
      "40x50 sqft",
    ],
    houseBudget: ["10 Lacs to 50 Lacs", "50 Lacs to 1 Cr", "1 Cr +"],
    roadFacing: ["East", "West", "North", "South"],
    numberOfFloors: ["G", "G+1", "G+2", "G+3"],
    typeOfConstruction: ["Brick", "Concrete", "Wood", "Steel"],
    numberOfBedrooms: ["1", "2", "3", "4+"],
    vastuCompliant: ["Yes", "No"],
    specialRequirements: ["Swimming Pool", "Garden", "Gym", "Basement"],
  };

  // Handle checkbox selection
  const handleCheckboxChange = (filterCategory, filterValue) => {
    setSelectedFilters((prevFilters) => {
      const currentFilters = prevFilters[filterCategory] || [];
      const newFilters = currentFilters.includes(filterValue)
        ? currentFilters.filter((f) => f !== filterValue)
        : [...currentFilters, filterValue];

      const updatedFilters = {
        ...prevFilters,
        [filterCategory]: newFilters,
      };

      onFiltersChange(updatedFilters);
      return updatedFilters;
    });
  };

  // Clear all selected filters
  const clearFilters = () => {
    setSelectedFilters({});
    onFiltersChange({});
  };

  // Toggle the dropdown open/close state
  const toggleDropdown = (category) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <div className="bg-[#FFF5EE] w-full p-4 mt-3 rounded-lg flex flex-wrap overflow-x-auto space-y-4">
      <div className="flex-shrink-0 w-full md:w-[230px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-bold font-poppins mb-2">
            Filters ({Object.values(selectedFilters).flat().length})
          </h2>
          <button
            className="text-base font-bold font-poppins"
            onClick={clearFilters}
          >
            Clear All
          </button>
        </div>
        <hr className="border-black mb-4" />
      </div>

      <div className="flex flex-wrap space-y-4 md:space-y-0 md:space-x-4">
        {/* Filter Dropdowns */}
        {Object.keys(filterOptions).map((category) => (
          <div key={category} className="flex-shrink-0 w-full md:w-[250px]">
            <details
              open={openDropdowns[category]}
              onToggle={() => toggleDropdown(category)}
              className="cursor-pointer mb-4"
            >
              <summary
                className={`cursor-pointer font-semibold font-poppins ${
                  openDropdowns[category]
                    ? "text-[#FE6929]"
                    : "text-black text-sm"
                }`}
              >
                {category.replace(/([A-Z])/g, " $1")}
              </summary>
              <div className="mt-2 space-y-2 bg-[#ffe2d6] p-2 rounded-md">
                {filterOptions[category].map((option) => (
                  <label key={option} className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox mr-2"
                      onChange={() => handleCheckboxChange(category, option)}
                      checked={
                        selectedFilters[category]?.includes(option) || false
                      }
                    />
                    {option}
                  </label>
                ))}
              </div>
            </details>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterDivMobile;
