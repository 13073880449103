import React, { useState } from "react";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS
import axios from "axios"; // Import Axios
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const AddFAQForm = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://api.bhk99.com/addFAQ/add-faqs",
        {
          question,
          answer,
        }
      );

      console.log("FAQ added:", response.data);

      // Show success toast
      toast.success("FAQ added successfully!");

      // Clear the form after submission
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error:", error);

      // Show error toast
      toast.error("Failed to add FAQ. Please try again.");
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <ToastContainer /> {/* Add ToastContainer for notifications */}
      <h2 className="text-2xl font-bold mb-4">Add FAQ</h2>
      <form onSubmit={handleSubmit}>
        {/* Question Input */}
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Question</label>
          <Quill
            value={question}
            onChange={setQuestion}
            placeholder="Enter the Question"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
                ["link", "image"],
                ["clean"],
              ],
            }}
            className="border border-gray-300 rounded-lg"
          />
        </div>

        {/* Answer Input using Quill */}
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Answer</label>
          <Quill
            value={answer}
            onChange={setAnswer}
            placeholder="Enter the answer"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
                ["link", "image"],
                ["clean"],
              ],
            }}
            className="border border-gray-300 rounded-lg"
          />
        </div>

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition duration-200"
        >
          Add FAQ
        </button>
      </form>
    </div>
  );
};

export default AddFAQForm;
