import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FilterDivMobile from "./Our Project/FilterDivMobile";
import axios from "axios";

export const ProjectMobile = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const projectsPerPage = 4;

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetch/projects"
        );
        setProjects(response.data);
        setFilteredProjects(response.data);
      } catch (error) {
        console.error("Error fetching the projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const handleFiltersChange = (filters) => {
    if (Object.values(filters).every((filter) => filter.length === 0)) {
      setFilteredProjects(projects);
      return;
    }

    const filtered = projects.filter((project) => {
      const matchesTitle =
        !filters.title ||
        filters.title.length === 0 ||
        filters.title.includes(project.title);
      const matchesCity =
        !filters.cityName ||
        filters.cityName.length === 0 ||
        filters.cityName.includes(project.cityName);
      const matchesPropertyType =
        !filters.propertyType ||
        filters.propertyType.length === 0 ||
        filters.propertyType.includes(project.propertyType);

      const matchesPlotDimensions =
        !filters.plotDimensions ||
        filters.plotDimensions.length === 0 ||
        filters.plotDimensions.includes(project.plotDimensions);

      const matchesRoadFacing =
        !filters.roadFacing ||
        filters.roadFacing.length === 0 ||
        filters.roadFacing.includes(project.roadFacing);

      const matchesNumberOfFloors =
        !filters.numberOfFloors ||
        filters.numberOfFloors.length === 0 ||
        filters.numberOfFloors.includes(project.numberOfFloors);

      const matchesTypeOfConstruction =
        !filters.typeOfConstruction ||
        filters.typeOfConstruction.length === 0 ||
        filters.typeOfConstruction.includes(project.typeOfConstruction);

      const matchesNumberOfBedrooms =
        !filters.numberOfBedrooms ||
        filters.numberOfBedrooms.length === 0 ||
        filters.numberOfBedrooms.includes(project.numberOfBedrooms);

      return (
        matchesTitle &&
        matchesCity &&
        matchesPropertyType &&
        matchesPlotDimensions &&
        matchesRoadFacing &&
        matchesNumberOfFloors &&
        matchesTypeOfConstruction &&
        matchesNumberOfBedrooms
      );
    });

    setFilteredProjects(filtered);
    setCurrentPage(1); // Reset to first page when filters change
  };

  // Get the current projects for the page
  const indexOfLastProject = currentPage * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = filteredProjects.slice(
    indexOfFirstProject,
    indexOfLastProject
  );

  // Pagination controls
  const totalPages = Math.ceil(filteredProjects.length / projectsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  return (
    <section className="py-20 bg-[#FFFFFF] relative">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-semibold font-poppins mb-3 text-center lg:text-left lg:ml-16">
          Our Projects
        </h2>

        <span className="text-gray-400 text-sm font-poppins mb-24 lg:ml-16">
          With our personalized approach and extensive listing, we'll guide you
          to your ideal abode, where comfort and happiness await.
        </span>

        <FilterDivMobile onFiltersChange={handleFiltersChange} />

        <div className="mt-4">
          <div className="grid grid-cols-2 sm:grid-cols-2 lg:flex lg:space-x-16 overflow-hidden mt-5 gap-4">
            {currentProjects.map((project, index) => (
              <div
                key={index}
                className={`flex-shrink-0 w-full sm:w-[150px] sm:h-[200px] bg-white shadow-md rounded-3xl`}
              >
                <img
                  src={project.propertyImage}
                  alt={project.title}
                  className="w-full h-[100px] object-cover rounded-t-3xl sm:h-[75px]"
                />
                <div className="p-4 sm:p-3">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-[10px] sm:text-[9px] font-semibold font-poppins text-[#FE6929]">
                        {project.title}
                      </h3>
                      <p className="text-[10px] sm:text-[9px] font-extrabold text-black-600 font-poppins">
                        {project.cityName}
                      </p>
                    </div>
                    <p className="text-[9px] sm:text-[8px] font-extrabold text-gray-400 font-poppins">
                      <p>{project.propertyType}</p>
                      <Link to="/project">
                        <button className="mt-2 px-2 py-1 border border-[#FE6929] text-[#FE6929] text-[8px] font-semibold rounded-full hover:bg-[#FE6929] hover:text-white transition-colors duration-300">
                          See Details
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination controls */}
          <div className="flex justify-between mt-8">
            <button
              onClick={handlePrevPage}
              className={`px-4 py-2 border ${
                currentPage === 1
                  ? "text-gray-300 border-gray-300"
                  : "text-[#FE6929] border-[#FE6929]"
              } rounded-lg`}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <p className="text-sm text-gray-600">
              Page {currentPage} of {totalPages}
            </p>
            <button
              onClick={handleNextPage}
              className={`px-4 py-2 border ${
                currentPage === totalPages
                  ? "text-gray-300 border-gray-300"
                  : "text-[#FE6929] border-[#FE6929]"
              } rounded-lg`}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
