import React from "react";

export const InteriorHome = ({ project }) => {
  // Ensure the project object exists and has additionalImages property
  const images = project?.additionalImages || [];

  console.log(images);

  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-white lg:mt-[-30px] md:mt-[-320px] mt-[-170px]">
      <div className="grid grid-cols-3 gap-4 sm:grid-cols-3 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 p-4">
        {images.length > 0 ? (
          images.map((src, index) => (
            <img
              key={index}
              src={src}
              alt={`Image ${index + 1}`}
              className="lg:w-[240px] md:w-[150px] w-[100px] lg:h-[240px] md:h-[150px] h-[100px] object-cover lg:ml-4 md:ml-4"
            />
          ))
        ) : (
          <p className="text-center col-span-3">No images available</p>
        )}
      </div>
      <div className="flex justify-center items-center w-full p-4 mt-4 mb-[-20px]">
        <p className="text-center text-gray-500 max-w-lg leading-relaxed">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
          imperdiet, nulla sit amet lacinia sodales, metus purus feugiat sapien,
          eu vehicula magna nisl at nisi. Donec auctor, lorem sed hendrerit
          cursus, orci nunc venenatis nisl, non efficitur dolor dui et quam.
        </p>
      </div>
    </div>
  );
};
