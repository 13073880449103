export const HeroHouse = () => {
  return (
    <>
      <section
        className="relative h-[300px] md:h-[520px] flex flex-col items-start justify-start bg-center"
        style={{
          backgroundImage: "url('images/houseCalci.png')",
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
        {/* Overlay */}
        <div className="relative z-10 text-left text-white p-8 mt-[70px] lg:mt-[200px] lg:ml-24 md:mt-44 md:ml-14 ">
          <h1 className="text-4xl tracking-tight leading-10 font-bold font-poppins text-white sm:text-5xl sm:leading-none md:text-6xl lg:text-7xl lg:mb-[10px] md:mb-[10px]">
            House Construction Calculator
          </h1>
          <span className="text-gray-400 text-sm font-poppins mt-4 mb-24">
            Estimate the total cost of building a home by factoring in key
            elements like materials, labor, and design.
          </span>
        </div>
      </section>
    </>
  );
};
