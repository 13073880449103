import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ViewWalkthroughs = () => {
  const [walkthroughs, setWalkthroughs] = useState([]);

  useEffect(() => {
    const fetchWalkthroughs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/pw/getAllWalkthroughs"
        );
        setWalkthroughs(response.data);
      } catch (error) {
        toast.error("Failed to fetch walkthroughs");
        console.error("Error fetching walkthroughs:", error);
      }
    };

    fetchWalkthroughs();
  }, []);

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">
        Active Walkthroughs
      </h2>

      {walkthroughs.length > 0 ? (
        <div className="space-y-4">
          {walkthroughs.map((walkthrough) => (
            <div
              key={walkthrough._id}
              className="p-4 border rounded-md bg-gray-50"
            >
              <h3 className="text-lg font-medium text-blue-600 mb-2">
                <a
                  href={walkthrough.videoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Video Link
                </a>
              </h3>
              <p className="text-gray-700">{walkthrough.description}</p>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">No active walkthroughs found.</p>
      )}

      <ToastContainer position="top-right" autoClose={3000} />
    </div>
  );
};

export default ViewWalkthroughs;
