import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faHouseChimney,
} from "@fortawesome/free-solid-svg-icons";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons/faArrowRightLong";
import axios from "axios";

const Pricing = () => {
  const [openFeature, setOpenFeature] = useState({});
  const [selectedCity, setSelectedCity] = useState("Banglore");
  const [plans, setPlans] = useState([]);
  const navigate = useNavigate();

  const toggleFeature = (planIndex, featureIndex) => {
    setOpenFeature((prevState) => ({
      ...prevState,
      [planIndex]: prevState[planIndex] === featureIndex ? null : featureIndex,
    }));
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };

  // Fetch package data from the backend
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/packages"
        );
        const formattedPlans = response.data.map((pkg) => ({
          name: pkg.packageName.replace(/<\/?[^>]+(>|$)/g, ""), // Remove HTML tags
          features: [
            {
              name: "Made for Apartment Owners",
              description: pkg.apartmentOwners,
            },
            { name: "Design and Drawings", description: pkg.designDrawings },
            { name: "Kitchen", description: pkg.kitchen },
            { name: "Bathroom", description: pkg.bathroom },
            { name: "Doors", description: pkg.doors },
            { name: "Painting", description: pkg.painting },
          ],
        }));
        setPlans(formattedPlans);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    fetchPackages();
  }, []);

  return (
    <section className="py-24 bg-[#FFF5EE] mt-1 mb-[-20px] hidden md:block">
      <div className="container mx-auto px-4 mt-4">
        <h2 className="text-3xl md:text-4xl font-semibold font-poppins lg:mb-4 lg:ml-16 md:mb-0 lg:text-center">
          Our Packages
        </h2>
        <span className="text-gray-400 text-sm md:text-base font-poppins lg:mb-24 lg:block lg:text-center">
          Find the best Home construction packages.*
        </span>

        <div className="grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-10 gap-6 p-6 lg:px-20">
          {plans.map((plan, planIndex) => {
            const isOpen = openFeature[planIndex] !== undefined;
            const tileHeight = isOpen ? "h-auto" : "h-[600px] lg:h-[600px] ";

            return (
              <div
                key={planIndex}
                className={`border border-gray-300 rounded-3xl p-6 shadow-lg bg-white lg:w-[300px] lg:ml-[10px]  ${tileHeight} relative`}
              >
                {/* Display package name as bold without HTML tags */}
                <h2 className="bg-[#FE6929] sm:w-[310px]  md:w-[330px] lg:w-[300px] h-[50px] ml-[-25px] mt-[-25px] text-lg sm:text-xl md:text-2xl lg:text-2xl font-medium font-poppins mb-2 text-center text-white rounded-t-[25px] flex justify-center items-center">
                  <strong>{plan.name}</strong>{" "}
                  {/* Bold styling applied directly */}
                </h2>

                <div className="flex justify-center mb-4">
                  <FontAwesomeIcon
                    icon={faHouseChimney}
                    size="6x"
                    className="mt-12"
                  />
                </div>
                <ul className="space-y-4 list-disc pl-5 mt-16">
                  {plan.features.map((feature, featureIndex) => (
                    <li key={featureIndex} className="text-gray-700">
                      <div className="flex justify-between items-center">
                        <span className="text-center">{feature.name}</span>
                        <button
                          onClick={() => toggleFeature(planIndex, featureIndex)}
                        >
                          <FontAwesomeIcon
                            icon={
                              openFeature[planIndex] === featureIndex
                                ? faChevronUp
                                : faChevronDown
                            }
                            className="text-[#FE6929]"
                          />
                        </button>
                      </div>
                      {openFeature[planIndex] === featureIndex && (
                        <p
                          className="mt-2 text-sm text-black p-2 rounded-lg"
                          style={{ backgroundColor: "#FFF5EE" }}
                          dangerouslySetInnerHTML={{
                            __html: feature.description,
                          }} // Render description as HTML
                        />
                      )}
                    </li>
                  ))}
                </ul>
                <button className="mt-6 px-4 py-2 w-full text-[#FE6929] border border-[#FE6929] rounded-full hover:bg-[#FE6929] hover:text-white transition-colors duration-200">
                  Create Your Dream
                </button>
              </div>
            );
          })}
        </div>
        <div className="flex justify-center mt-8">
          <button
            className="px-4 py-2 text-[#FE6929] bg-transparent border-none rounded-full font-poppins font-semibold hover:bg-[#FE6929] hover:text-white transition-colors duration-200"
            onClick={() => navigate("/compare-packages")}
          >
            Compare Packages <FontAwesomeIcon icon={faArrowRightLong} />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
