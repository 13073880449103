import React, { useEffect, useState } from "react";
import axios from "axios";

const Image = () => {
  const [howItWorksImage, setHowItWorksImage] = useState("");

  useEffect(() => {
    const fetchHowItWorksImage = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/hero/getHero"
        );
        setHowItWorksImage(
          `data:image/jpeg;base64,${response.data.howItWorkshero}`
        );
      } catch (error) {
        console.error("Error fetching How It Works image:", error);
      }
    };

    fetchHowItWorksImage();
  }, []);

  return (
    <div className="bg-white">
      {/* Background Image */}
      <div className="relative">
        {/* Background Image with Different Height for Mobile */}
        <img
          src={howItWorksImage || "/images/Howitworks.jpg"}
          alt="Construction worker"
          className="w-full h-72 sm:h-96 md:h-auto object-cover"
        />

        {/* Overlay with Text on the Left */}
        <div className="absolute inset-0 flex items-center justify-start bg-black bg-opacity-40">
          <div className="text-left text-white px-4 sm:px-8 md:px-12 lg:px-20 max-w-lg">
            <h1 className="text-xl sm:text-3xl lg:text-5xl font-bold mb-2 sm:mb-4">
              How It Works
            </h1>

            {/* Mobile Version with Line Break */}
            <p className="text-xs sm:hidden mb-2">
              Our house construction steps are simple <br /> and easy to
              understand:
            </p>

            {/* Tablet and Desktop Version (Single Line) */}
            <p className="hidden sm:block text-lg lg:text-xl mb-4 whitespace-nowrap">
              Our house construction steps are simple and easy to understand:
            </p>

            <p className="text-sm sm:text-xl lg:text-2xl font-semibold">
              Plan - Build - Track - Settle in
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Image;
