import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill CSS
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const EditFAQForm = () => {
  const [faqs, setFaqs] = useState([]);
  const [selectedFAQ, setSelectedFAQ] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  // Fetch FAQs from the server
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchFAQ/faqs"
        ); // Update with your endpoint
        setFaqs(response.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        toast.error("Failed to fetch FAQs!");
      }
    };

    fetchFAQs();
  }, []);

  // Fetch selected FAQ's details when selection changes
  const handleSelectChange = async (e) => {
    const selectedId = e.target.value;
    setSelectedFAQ(selectedId);

    if (selectedId) {
      try {
        const response = await axios.get(
          `https://api.bhk99.com/fetchFAQ/faqs/${selectedId}`
        );

        const { question, answer } = response.data;
        setQuestion(question);
        setAnswer(answer);
      } catch (error) {
        console.error("Error fetching selected FAQ:", error);
        toast.error("Failed to load FAQ details!");
      }
    } else {
      setQuestion("");
      setAnswer("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.put(
        `https://api.bhk99.com/updateFAQ/faqs/${selectedFAQ}`,
        {
          question,
          answer,
        }
      );
      console.log("FAQ updated successfully!");
      toast.success("FAQ updated successfully!");

      // Clear the form after submission
      setSelectedFAQ("");
      setQuestion("");
      setAnswer("");
    } catch (error) {
      console.error("Error updating FAQ:", error);
      toast.error("Failed to update FAQ!");
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <ToastContainer />
      <h2 className="text-2xl font-bold mb-4">Edit FAQ</h2>
      <form onSubmit={handleSubmit}>
        {/* Dropdown for selecting FAQ */}
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Select FAQ</label>
          <select
            value={selectedFAQ}
            onChange={handleSelectChange}
            className="border border-gray-300 rounded-lg p-2 w-full"
          >
            <option value="">Select an FAQ</option>
            {faqs.map((faq) => (
              <option key={faq._id} value={faq._id}>
                {faq.question.replace(/<[^>]+>/g, "")}
              </option>
            ))}
          </select>
        </div>

        {/* Question Input */}
        {selectedFAQ && (
          <div className="mb-4">
            <label className="block text-lg font-medium mb-2">Question</label>
            <input
              type="text"
              value={question.replace(/<[^>]+>/g, "")}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Enter the question"
              className="border border-gray-300 rounded-lg p-2 w-full"
            />
          </div>
        )}

        {/* Answer Input using Quill */}
        {selectedFAQ && (
          <div className="mb-4">
            <label className="block text-lg font-medium mb-2">Answer</label>
            <Quill
              value={answer}
              onChange={setAnswer}
              placeholder="Enter the answer"
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline"],
                  ["link", "image"],
                  ["clean"],
                ],
              }}
              className="border border-gray-300 rounded-lg"
            />
          </div>
        )}

        {/* Submit Button */}
        <button
          type="submit"
          className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition duration-200"
        >
          Update FAQ
        </button>
      </form>
    </div>
  );
};

export default EditFAQForm;
