// GetInTouchEmail.js
import { useState, useEffect } from 'react';
import axios from 'axios';

export const GetInTouchEmail = () => {
  const [emails, setEmails] = useState([]);

  useEffect(() => {
    const fetchEmails = async () => {
      try {
        const response = await axios.get('https://api.bhk99.com/api/emails');
        setEmails(response.data); // Set all emails
      } catch (error) {
        console.error('Error fetching emails');
      }
    };
    fetchEmails();
  }, []);

  return (
    <div className="mt-4">
      {emails.length > 0 ? (
        emails.map((email) => (
          <p key={email._id} className="text-gray-300 text-sm">
            {email.email}
          </p>
        ))
      ) : (
        <p className="text-gray-300 text-sm">No contact emails available.</p>
      )}
    </div>
  );
};
