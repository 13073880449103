import React, { useState, useEffect } from "react";
import FilterDiv from "./FilterDiv"; // Ensure this path is correct
import ThreeDTile from "./ThreeDTile";
import FilterDivTab from "./FilterDivTab";
import FilterDivMobile from "./FilterDivMobile";
import { ThreeDTileMobile } from "./ThreeDTileMobile";
import ThreeDTileTab from "./ThreeDTileTab";

const Layout = () => {
  const [viewport, setViewport] = useState("large");
  const [tilesData, setTilesData] = useState([]);
  const [filteredTilesData, setFilteredTilesData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [loading, setLoading] = useState(true); // Loading state for fetching data
  const [error, setError] = useState(null); // Error state for handling errors

  useEffect(() => {
    const handleReplotDimensions = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleReplotDimensions);
    handleReplotDimensions(); // Initial check
    return () => window.removeEventListener("resize", handleReplotDimensions);
  }, []);

  // Fetch data from the backend API
  useEffect(() => {
    const fetchTilesData = async () => {
      try {
        const response = await fetch(
          "https://api.bhk99.com/fetch/projects"
        ); // Update URL to your backend API
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setTilesData(data);
        setFilteredTilesData(data); // Initialize filtered data with fetched data
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTilesData();
  }, []);

  // Apply filters whenever selectedFilters change
  useEffect(() => {
    const applyFilters = () => {
      const filtered = tilesData.filter((tile) => {
        let isValid = true;
        for (const key in selectedFilters) {
          if (
            selectedFilters[key].length > 0 &&
            !selectedFilters[key].includes(tile[key])
          ) {
            isValid = false;
            break;
          }
        }
        return isValid;
      });
      setFilteredTilesData(filtered);
    };

    applyFilters();
  }, [selectedFilters, tilesData]); // Include tilesData in dependency array

  const handleFiltersChange = (newFilters) => {
    setSelectedFilters(newFilters);
  };

  if (loading) {
    return <div>Loading...</div>; // Display loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Display error message
  }

  return (
    <div className="flex flex-col md:flex-row p-4 gap-4">
      {/* Filter Component */}
      <div className="md:w-1/4 lg:w-1/5 xs:w-[150px] sm:w-[200px]">
        {viewport === "large" && (
          <FilterDiv onFiltersChange={handleFiltersChange} />
        )}
        {viewport === "medium" && (
          <FilterDivTab onFiltersChange={handleFiltersChange} />
        )}
        {viewport === "mobile" && (
          <FilterDivMobile onFiltersChange={handleFiltersChange} />
        )}
      </div>

      {/* Tiles Grid */}
      {viewport === "large" && (
        <div className="md:w-[1000px] lg:w-[1000px] gap-4 gap-x-0 md:ml-[120px] md:mt-[100px]">
          <ThreeDTile tilesData={filteredTilesData} />
        </div>
      )}

      {viewport === "medium" && (
        <div className="md:w-3/4 lg:w-4/5 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 gap-x-0 md:ml-[120px] md:mt-[100px]">
          <ThreeDTileTab tilesData={filteredTilesData} />
        </div>
      )}

      {viewport === "mobile" && (
        <div className="gap-4 mt-4 sm:w-[100px] w-[300px]">
          <ThreeDTileMobile tilesData={filteredTilesData} />
        </div>
      )}
    </div>
  );
};

export default Layout;
