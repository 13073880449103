import React from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Link } from "react-router-dom";

export const OurProjectNavbarTab = () => {
  return (
    <>
      <nav className="bg-white shadow dark:bg-gray-800 w-[810px] h-[100px] sticky top-0 z-50">
        <div className="container flex items-center justify-center p-6 mx-auto text-gray-600 capitalize dark:text-gray-300">
          {/* Logo - always visible */}
          <a href="javascript:void(0)">
            <img
              src="/images/logo1.png"
              alt="logo"
              className="w-12 md:w-16 text-gray-800 dark:text-gray-200 font-poppins hover:text-[#FE6929]"
            />
          </a>

          {/* Nav links - visible only on tablet devices (md: breakpoint) */}
          <div className="hidden md:flex md:items-center md:space-x-6 md:ml-6 lg:hidden">
            <Link
              to={"/"}
              className="text-gray-800 dark:text-gray-200 font-poppins hover:text-[#FE6929]"
            >
              Home
            </Link>

            <Link
              to={"/3d-plans"}
              className="text-gray-800 dark:text-gray-200 font-poppins hover:text-[#FE6929]"
            >
              3D Floor Plans
            </Link>

            <Link
              to="/OurProjects"
              className="border-b-2 border-transparent dark:hover:text-gray-200 font-poppins hover:text-[#FE6929]"
            >
              Our Projects
            </Link>

            <Link
              to={"/calci"}
              className="border-b-2 border-transparent font-poppins hover:text-[#FE6929] dark:hover:text-gray-200"
            >
              Cost Estimator
            </Link>

            <Link
              to="/HowItWorks"
              className="border-b-2 border-transparent font-poppins hover:text-[#FE6929] dark:hover:text-gray-200"
            >
              How it Works
            </Link>

            <Link
              to="/login"
              className="inline-flex items-center px-3 py-2 text-white bg-[#FE6929] rounded-lg hover:bg-[#e45f23] transition-colors duration-300 ease-in-out shadow-lg"
            >
              Log In
              <i className="fas fa-arrow-right ml-3"></i>
            </Link>

            <Link
              to="/ContactUs"
              className="border-b-2 border-transparent hover:text-[#FE6929] dark:hover:text-gray-200"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};
