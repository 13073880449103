// src/components/ContactUs.js
import React, { useState, useEffect } from "react";
import ContactInfo from "./ContactInfo";
import ContactBanner from "./ContactBanner";
import ContactProfit from "./ContactProfit";
import ContactContactForm from "./ContactContactForm";
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import NavbarMobile from "../NavbarMobile";
import { Footer } from "../Footer";
import { Banner } from "./Banner";
import axios from "axios";

function ContactUs() {
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [contactHeroImage, setContactHeroImage] = useState("");

  useEffect(() => {
    const fetchHeroImages = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/hero/getHero"
        );
        console.log(response.data);
        setContactHeroImage(
          `data:image/jpeg;base64,${response.data.contactUshero}`
        );
      } catch (error) {
        console.error("Error fetching hero image:", error);
      }
    };

    fetchHeroImages();
  }, []);

  return (
    <>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <div className="min-h-screen bg-gray-100">
        {/* Header Section */}
        <div
          className="relative h-[60vh] bg-cover bg-center"
          style={{
            backgroundImage: `url(${contactHeroImage || "images/hero.jpg"})`,
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
          <div className="relative text-left text-white flex flex-col justify-center h-full px-6 md:px-12">
            <h1 className="text-4xl font-bold">Contact Us</h1>
            <div className="mt-2 text-sm">
              <a href="/" className="text-gray-300 hover:text-white">
                Home
              </a>{" "}
              {" > "}
              <span className="text-white">Contact</span>
            </div>
          </div>
        </div>

        {/* Content Section */}
        <div className="max-w-6xl mx-auto px-4 py-12 md:flex space-y-8 md:space-y-0">
          <div className="md:w-1/2">
            <ContactInfo />
          </div>
          <div className="md:w-1/2">
            <ContactContactForm />
          </div>
        </div>
      </div>
      <ContactBanner />
      <ContactProfit />
      <Banner />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
}

export default ContactUs;
