import axios from "axios";
import React, { useState } from "react";

const HiwBanner = () => {
  const [image1, setImage1] = useState(null);
  const [imageFile1, setImageFile1] = useState(null);

  const [image2, setImage2] = useState(null);
  const [imageFile2, setImageFile2] = useState(null);

  const handleImageChange = (e, setImage, setImageFile) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setImageFile(file); // Store the file for upload
    }
  };

  const handleAddImages = async () => {
    const formData = new FormData();

    if (imageFile1) {
      formData.append("howItWorksBanner", imageFile1); // For the first banner
    }
    if (imageFile2) {
      formData.append("howItWorksBanner2", imageFile2); // For the second banner
    }

    try {
      const response = await axios.post(
        "https://api.bhk99.com/banner/addBanners",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("Images added successfully!");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
      alert("Failed to add images.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">How It Works Banners</h1>

      {/* Hiw Banner 1 */}
      <div className="mb-6">
        <h6 className="text-2xl font-semibold mb-4">How It Works Banners 1</h6>

        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, setImage1, setImageFile1)}
          className="mb-4"
        />
        {image1 && (
          <div className="mb-4">
            <img
              src={image1}
              alt="Selected Banner 1"
              className="max-w-full h-auto rounded-lg"
            />
          </div>
        )}
      </div>

      {/* Hiw Banner 2 */}
      <div className="mb-6">
        <h6 className="text-2xl font-semibold mb-4">How It Works Banners 2</h6>

        <input
          type="file"
          accept="image/*"
          onChange={(e) => handleImageChange(e, setImage2, setImageFile2)}
          className="mb-4"
        />
        {image2 && (
          <div className="mb-4">
            <img
              src={image2}
              alt="Selected Banner 2"
              className="max-w-full h-auto rounded-lg"
            />
          </div>
        )}
      </div>

      <button
        onClick={handleAddImages}
        className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300"
      >
        Add Images
      </button>
    </div>
  );
};

export default HiwBanner;
