// src/components/ContactInfo.js
import React from 'react';
import { FaMapMarkerAlt, FaClock, FaEnvelope, FaPhoneAlt } from 'react-icons/fa';

function ContactInfo() {
  return (
    <div className="text-gray-800 space-y-6">
      <div className="flex items-start space-x-4">
        <FaMapMarkerAlt className="text-orange-500 text-2xl" />
        <div>
          <h3 className="text-xl font-semibold">Location:</h3>
          <p>Bhk99, Khajri Road, Sinchai Colony, Chhindwara - 480001</p>
        </div>
      </div>

      <div className="flex items-start space-x-4">
        <FaClock className="text-orange-500 text-2xl" />
        <div>
          <h3 className="text-xl font-semibold">Open Hours:</h3>
          <p>Monday - Saturday 11:00 AM - 10:00 PM</p>
        </div>
      </div>

      <div className="flex items-start space-x-4">
        <FaEnvelope className="text-orange-500 text-2xl" />
        <div>
          <h3 className="text-xl font-semibold">Email:</h3>
          <p>contact@bhk99.com</p>
        </div>
      </div>

      <div className="flex items-start space-x-4">
        <FaPhoneAlt className="text-orange-500 text-2xl" />
        <div>
          <h3 className="text-xl font-semibold">Call:</h3>
          <p>7440033070</p>
        </div>
      </div>

      <div className="flex items-start space-x-4">
        <FaPhoneAlt className="text-orange-500 text-2xl" />
        <div>
          <h3 className="text-xl font-semibold">Toll Free Number:</h3>
          <p>10000XXXXXXX</p>
        </div>
      </div>
    </div>
  );
}

export default ContactInfo;
