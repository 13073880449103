export const ProjectHeroHome = ({ project }) => {
  if (!project) {
    return <div>Loading...</div>; // Fallback if no project data is found
  }
  return (
    <>
      <section
        className="relative h-[300px] md:h-[620px] flex flex-col items-start justify-start bg-center"
        style={{
          backgroundImage: `url(${project.propertyImage})`,
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>{" "}
        {/* Overlay */}
        <div className="relative z-10 text-left text-white p-8 mt-[70px] lg:mt-[200px] lg:ml-24 md:mt-44 md:ml-14">
          <h1 className="text-4xl tracking-tight leading-10 font-bold font-poppins text-white sm:text-5xl sm:leading-none md:text-6xl lg:text-7xl ">
            {project.cityName}
          </h1>
          <nav className="mt-4">
            <ul className="flex space-x-4">
              <li>
                <a
                  href="/"
                  className="text-white font-poppins text-sm lg:text-lg md:text-lg"
                >
                  Home /
                </a>
              </li>
              <li>
                <a
                  href="/properties"
                  className="text-white font-poppins text-sm lg:text-lg md:text-lg"
                >
                  Properties /
                </a>
              </li>
              <li>
                <a
                  href="/bunglow-new"
                  className="text-white font-poppins text-sm lg:text-lg md:text-lg"
                >
                  {project.propertyType}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </section>
    </>
  );
};
