import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch all news on component mount
  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchNews/news"
        );
        setNewsList(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };
    fetchNews();
  }, []);

  // Handle the selection of a news item from the dropdown
  const handleSelectChange = (event) => {
    const newsId = event.target.value;
    const news = newsList.find((n) => n._id === newsId);
    setSelectedNews(news);
  };

  // Handle delete news functionality
  const handleDelete = async () => {
    if (!selectedNews) return;

    setLoading(true);
    try {
      await axios.delete(
        `https://api.bhk99.com/deleteNews/news/${selectedNews._id}`
      );
      setNewsList(newsList.filter((n) => n._id !== selectedNews._id));
      setSelectedNews(null);
      toast.success("News deleted successfully!");
    } catch (error) {
      console.error("Error deleting news:", error);
      toast.error("Failed to delete news");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Delete News</h2>

      <div className="mb-4">
        <label className="block text-lg font-medium mb-2">Select News</label>
        <select
          className="w-full p-3 border border-gray-300 rounded"
          value={selectedNews ? selectedNews._id : ""}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            Choose news to delete
          </option>
          {newsList.map((news) => (
            <option key={news._id} value={news._id}>
              {news.content.substring(0, 30)}...
            </option>
          ))}
        </select>
      </div>

      {selectedNews && (
        <div className="text-center">
          <button
            onClick={handleDelete}
            className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-200"
            disabled={loading}
          >
            {loading ? "Deleting..." : "Delete News"}
          </button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default DeleteNews;
