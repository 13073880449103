import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";

export const ThreeDTile = ({ project }) => {
  if (!project) {
    return <div>Loading...</div>; // Fallback if no project data is found
  }
  return (
    <div className="bg-[#FFF5EE] lg:h-[590px] md:h-[420px] lg:w-[800px] md:w-[500px] p-2 rounded-2xl shadow-md mx-auto lg:mt-10 md:mt-[-250px] lg:mb-8 md:mb-6 hidden md:block">
      <div className="relative pb-[56.25%]">
        <iframe
          src={project.threeDModelLink}
          className="absolute top-0 left-0 w-full h-full rounded-t-3xl border-none"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>

      {/* Like and Share Icons */}
      <div className="flex justify-between items-center mt-4">
        <div className="flex items-center gap-2">
          <div className="bg-white text-white p-2 rounded-full">
            <i className="fa fa-thumbs-up text-[#FE6929]"></i>
          </div>
          <p className="font-poppins text-[#FE6929]">49</p>
        </div>
        <div className="bg-white text-[#FE6929] p-2 rounded-full">
          <i className="fa fa-share"></i>
        </div>
      </div>

      {/* New Icon Section */}
      <div className="flex justify-center lg:gap-[200px] md:gap-[100px] mt-4">
        <div>
          <HiOutlineBuildingOffice2 className="text-[#FE6929] text-2xl" />
          <p className="font-poppins text-[#FE6929] lg:text-sm">
            {project.plotDimensions}
          </p>
        </div>
        <div>
          <HiOutlineCurrencyRupee className="text-[#FE6929] text-2xl" />
          <p className="font-poppins text-[#FE6929] lg:text-sm">
            {" "}
            {project.houseBudget}
          </p>
        </div>
        <div>
          <GrSteps className="text-[#FE6929] text-2xl" />
          <p className="font-poppins text-[#FE6929] lg:text-sm">
            {project.numberOfFloors}
          </p>
        </div>
      </div>
    </div>
  );
};