import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const handleSendOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.bhk99.com/forgotPassword/forgot-password/send-otp",
        { email }
      );
      if (response.status === 200) {
        setStep(2);
        setError("");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to send OTP.");
    }
  };

  const handleVerifyOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.bhk99.com/forgotPassword/forgot-password/verify-otp",
        { email, otp }
      );
      if (response.status === 200) {
        setStep(3);
        setError("");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Invalid OTP.");
    }
  };

  const handleChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(
        "https://api.bhk99.com/forgotPassword/forgot-password/change-password",
        { email, newPassword }
      );
      if (response.status === 200) {
        alert("Password changed successfully.");
        navigate("/login");
        setError("");
      }
    } catch (err) {
      setError(err.response?.data?.message || "Failed to change password.");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg">
        <h2 className="text-3xl font-semibold text-center text-gray-900 mb-6">
          Forgot Password
        </h2>

        {error && <p className="text-red-600 text-center mb-4">{error}</p>}

        {step === 1 && (
          <div>
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              onClick={handleSendOtp}
              className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] mt-4"
            >
              Send OTP
            </button>
          </div>
        )}

        {step === 2 && (
          <div>
            <label
              htmlFor="otp"
              className="block text-sm font-medium text-gray-700"
            >
              Enter OTP
            </label>
            <input
              type="text"
              id="otp"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              required
            />
            <button
              onClick={handleVerifyOtp}
              className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] mt-4"
            >
              Verify OTP
            </button>
          </div>
        )}

        {step === 3 && (
          <div>
            <label
              htmlFor="newPassword"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type="password"
              id="newPassword"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />

            <label
              htmlFor="confirmPassword"
              className="block text-sm font-medium text-gray-700 mt-4"
            >
              Confirm Password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="mt-2 block w-full p-3 border border-gray-300 rounded-md"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />

            <button
              onClick={handleChangePassword}
              className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] mt-4"
            >
              Change Password
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
