import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";

export const ThreeDTileMobile = ({ project }) => {
  if (!project) {
    return <div>Loading...</div>; // Fallback if no project data is found
  }
  return (
    <div className="bg-[#FFF5EE] p-2 rounded-2xl shadow-md mt-[-150px] w-[360px] h-[450px] ml-[8px] mb-[20px]">
      <div className="relative pb-[56.25%]">
        <iframe
          src={project.threeDModelLink}
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
      <div className="flex justify-between items-center mt-[130px] ">
        <div className="flex items-center gap-2 mt-2">
          <div className="bg-white text-white p-2 rounded-full ml-[14px]">
            <i className="fa fa-thumbs-up text-[#FE6929] text-xs"></i>
          </div>
          <p className="font-poppins text-[#FE6929] text-xs">49</p>
        </div>
        <div className="bg-white text-[#FE6929] p-2 rounded-full mr-[14px] mt-2">
          <i className="fa fa-share text-xs"></i>
        </div>
      </div>
      <div className="flex justify-center gap-[50px] mt-[5px]">
        <div>
          <HiOutlineBuildingOffice2 className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.plotDimensions}
          </p>
        </div>
        <div>
          <HiOutlineCurrencyRupee className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.houseBudget}
          </p>
        </div>
        <div>
          <GrSteps className="text-[#FE6929] text-base" />
          <p className="font-poppins text-[#FE6929] text-xs">
            {project.numberOfFloors}
          </p>
        </div>
      </div>
    </div>
  );
};