import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddPermission = () => {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedSections, setSelectedSections] = useState([]);
    const [permissionsList, setPermissionsList] = useState([]);

    // Sample users fetched from database (you should replace this with your API call)
    useEffect(() => {
        const fetchUsers = async () => {
            const mockUsers = [
                { id: 1, name: 'John Doe' },
                { id: 2, name: 'Jane Smith' },
                { id: 3, name: 'Alice Johnson' },
                { id: 4, name: 'Bob Brown' },
            ];
            setUsers(mockUsers);
        };
        fetchUsers();
    }, []);

    const sections = [
        { id: 1, name: 'Service Section' },
        { id: 2, name: 'Our Project Section' },
        { id: 3, name: 'Cost Estimator Section' },
        { id: 4, name: 'Packages Section' },
        { id: 5, name: 'Inbox Section' },
        { id: 6, name: 'User Section' },
        { id: 7, name: 'FAQ Section' },
        { id: 8, name: 'Banking Partner Section' },
    ];

    const handleUserSelectionChange = (event) => {
        const value = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedUsers(value);
    };

    const handleSectionSelectionChange = (event) => {
        const value = Array.from(event.target.selectedOptions, option => option.value);
        setSelectedSections(value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (selectedUsers.length === 0 || selectedSections.length === 0) {
            toast.error('Please select at least one user and one section!');
            return;
        }

        const newPermissions = selectedUsers.map(user => ({
            user,
            sections: selectedSections,
        }));

        setPermissionsList(prev => [...prev, ...newPermissions]);
        toast.success('Permissions added successfully!');
        setSelectedUsers([]);
        setSelectedSections([]);
    };

    const handleEditPermissions = (index) => {
        const userPermissions = permissionsList[index].sections;
        setSelectedUsers([permissionsList[index].user]); // Set selected user
        setSelectedSections(userPermissions); // Set their current permissions
        deletePermission(index); // Remove them from the list to edit
    };

    const deletePermission = (index) => {
        setPermissionsList(prev => prev.filter((_, i) => i !== index));
        toast.success('User permissions deleted successfully!');
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-6">
            <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-3xl">
                <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Add User Permissions</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-5">
                        <label htmlFor="users" className="block text-sm font-medium text-gray-700 mb-2">Choose Users:</label>
                        <select
                            id="users"
                            multiple
                            value={selectedUsers}
                            onChange={handleUserSelectionChange}
                            className="border border-gray-300 rounded-md p-3 w-full h-32 resize-none focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-150 ease-in-out"
                        >
                            {users.map(user => (
                                <option key={user.id} value={user.name} className="p-2 hover:bg-orange-100">
                                    {user.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-5">
                        <label htmlFor="sections" className="block text-sm font-medium text-gray-700 mb-2">Select Sections:</label>
                        <select
                            id="sections"
                            multiple
                            value={selectedSections}
                            onChange={handleSectionSelectionChange}
                            className="border border-gray-300 rounded-md p-3 w-full h-32 resize-none focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-150 ease-in-out"
                        >
                            {sections.map(section => (
                                <option key={section.id} value={section.name} className="p-2 hover:bg-orange-100">
                                    {section.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button 
                        type="submit" 
                        className="w-full bg-orange-500 text-white py-2 rounded-md hover:bg-orange-600 transition duration-200"
                    >
                        Add Permissions
                    </button>
                </form>
            </div>

            {/* Displaying selected users and their permissions */}
            <div className="mt-8 bg-white shadow-lg rounded-lg p-4 w-full max-w-3xl">
                <h3 className="text-xl font-semibold text-gray-800 mb-4">Assigned Permissions</h3>
                {permissionsList.length > 0 ? (
                    <ul className="space-y-4">
                        {permissionsList.map((permission, index) => (
                            <li key={index} className="flex justify-between items-center border-b pb-2">
                                <div className="flex-1">
                                    <span className="font-medium text-gray-700">{permission.user}</span>
                                    <div className="text-sm text-gray-600">
                                        <span className="font-medium">Permissions:</span> {permission.sections.join(', ')}
                                    </div>
                                </div>
                                <div className="flex items-center space-x-2">
                                    <button 
                                        onClick={() => handleEditPermissions(index)} 
                                        className="text-blue-600 hover:underline"
                                    >
                                        Edit
                                    </button>
                                    <button 
                                        onClick={() => deletePermission(index)} 
                                        className="text-red-600 hover:underline"
                                    >
                                        Delete
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p className="text-gray-500">No permissions assigned yet.</p>
                )}
            </div>
        </div>
    );
};

export default AddPermission;
