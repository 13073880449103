import { Link } from "react-router-dom";

export const CalciTile = () => {
  return (
    <>
      <div className="flex flex-col items-center min-h-screen md:min-h-[700px] bg-[#FFF5EE]">
        <h2 className="text-2xl md:text-3xl font-semibold font-poppins mb-8 text-center md:text-left mt-[30px]">
          Cost Estimator
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-[70px] p-6 lg:w-[700px] lg:h-[600px] md:w-[600px] md:h-[600px]">
          {/* House Construction Calculator Tile */}
          <div className="bg-white shadow-lg rounded-3xl overflow-hidden">
            <img
              src="images/houseCalci.png"
              alt="House Construction Calculator"
              className="w-full h-[300px] object-cover"
            />
            <div className="p-6">
              <h2 className="lg:text-sm md:text-xs font-semibold mb-4 font-poppins">
                House Construction Calculator
              </h2>
              <p className="text-gray-600 mb-4 font-poppins">
                Calculate the cost of constructing your dream home easily with
                our House Construction Calculator.
              </p>
              <Link
                to={"/house-calci"}
                href="/house-construction-calculator"
                className=" text-white px-4 py-2 rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
              >
                Try Now
              </Link>
            </div>
          </div>

          {/* EMI Calculator Tile */}
          <div className="bg-white shadow-lg rounded-3xl overflow-hidden">
            <img
              src="images/emiCalci.jpg"
              alt="EMI Calculator"
              className="w-full h-[300px] object-cover"
            />
            <div className="p-6">
              <h2 className="lg:text-sm md:text-xs font-semibold mb-4 font-poppins">
                EMI Calculator
              </h2>
              <p className="text-gray-600 mb-4 font-poppins">
                Plan your finances by calculating your loan EMIs with our
                easy-to-use EMI Calculator.
              </p>
              <Link
                to={"/emi-calci"}
                className=" text-white px-4 py-2 rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
              >
                Try Now
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
