// MainComparePackages.js
import React, { useState, useEffect } from "react";
import ComparePackages from './ComparePackage';
import { Navbar2 } from '../Navbar2';
import { NavbarTab } from '../NavbarTab';
import { Footer } from "../Footer";
import NavbarMobile from "../NavbarMobile";

const MainComparePackages = () => {
    const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
   
    <div className="main-compare-packages">
         {viewport === "large" && <Navbar2 />}
         {viewport === "medium" && <NavbarTab />}
    
      <ComparePackages />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}{" "}
    </div>
  );
};

export default MainComparePackages;
