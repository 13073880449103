import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const ActiveEmail = () => {
  const [emails, setEmails] = useState([]);
  const [editingEmail, setEditingEmail] = useState(null);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = async () => {
    try {
      const response = await axios.get('https://api.bhk99.com/api/emails');
      setEmails(response.data);
    } catch (error) {
      console.error('Error fetching emails', error);
    }
  };

  const handleEdit = (id) => {
    const email = emails.find((email) => email._id === id);
    setEditingEmail({ ...email });
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://api.bhk99.com/api/update-email/${editingEmail._id}`, {
        email: editingEmail.email,
      });
      toast.success('Email updated successfully');
      setEditingEmail(null);
      fetchEmails();
    } catch (error) {
      toast.error('Error updating email');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.bhk99.com/api/delete-email/${id}`);
      toast.success('Email deleted successfully');
      fetchEmails();
    } catch (error) {
      toast.error('Error deleting email');
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-md mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Active Emails</h2>
      <ul className="space-y-3">
        {emails.map((email) => (
          <li
            key={email._id}
            className="flex flex-col sm:flex-row justify-between items-center p-4 bg-white rounded-lg shadow-lg"
          >
            {editingEmail && editingEmail._id === email._id ? (
              <input
                type="email"
                value={editingEmail.email}
                onChange={(e) =>
                  setEditingEmail((prev) => ({ ...prev, email: e.target.value }))
                }
                className="p-2 border border-gray-300 rounded w-full sm:w-auto mb-2 sm:mb-0"
              />
            ) : (
              <span className="text-gray-800 text-sm sm:text-base">{email.email}</span>
            )}
            <div className="flex space-x-3 mt-2 sm:mt-0">
              {editingEmail && editingEmail._id === email._id ? (
                <button
                  onClick={handleUpdate}
                  className="bg-green-600 hover:bg-green-700 px-3 py-1 text-white rounded-lg text-sm"
                >
                  Save
                </button>
              ) : (
                <>
                  <button
                    onClick={() => handleEdit(email._id)}
                    className="bg-orange-500 hover:bg-orange-600 px-3 py-1 text-white rounded-lg text-sm"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDelete(email._id)}
                    className="bg-red-500 hover:bg-red-600 px-3 py-1 text-white rounded-lg text-sm"
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
