import { faHouse, faPerson } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Stats = () => {
  return (
    <div className="px-6 py-10 lg:py-20 font-sans text-white mb-[-80px]">
      <div className="grid lg:grid-cols-4 md:grid-cols-4 gap-x-16 gap-y-12 max-w-6xl mx-auto flex-nowrap">
        <div className="flex flex-col items-center lg:items-start md:gap-4 lg:gap-8 lg:mb-6 md:mb-16 md:w-[250px] md:ml-[-100px]">
          <div>
            <h3 className="text-3xl md:text-4xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faHouse}
                className="mr-2 lg:text-3xl md:text-sm"
              />
              <span className="text-[#FE6929] lg:text-3xl md:text-sm">
                3,298
              </span>
            </h3>
            <p className="text-gray-300 mt-2 text-base lg:text-lg md:text-sm">
              # of Buy Properties
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-start gap-4 lg:gap-8 lg:mb-6  md:w-[250px] md:ml-[-10px]">
          <div>
            <h3 className="text-3xl md:text-4xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faHouse}
                className="mr-2 lg:text-3xl md:text-sm"
              />
              <span className="text-[#FE6929] lg:text-3xl md:text-sm">
                2,181
              </span>
            </h3>
            <p className="text-gray-300 mt-2 text-base lg:text-lg md:text-sm">
              # of Sell Properties
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-start gap-4 lg:gap-8 lg:mb-6  md:w-[250px] md:ml-[70px]">
          <div>
            <h3 className="text-3xl md:text-4xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faHouse}
                className="mr-2 lg:text-3xl md:text-sm"
              />
              <span className="text-[#FE6929] lg:text-3xl md:text-sm">
                9,316
              </span>
            </h3>
            <p className="text-gray-300 mt-2 text-base lg:text-lg md:text-sm">
              # of All Properties
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center lg:items-start gap-4 lg:gap-8 lg:mb-6  md:w-[250px] md:ml-[140px]">
          <div>
            <h3 className="text-3xl md:text-4xl font-bold flex items-center">
              <FontAwesomeIcon
                icon={faPerson}
                className="mr-2 lg:text-3xl md:text-sm"
              />
              <span className="text-[#FE6929] lg:text-3xl md:text-sm">
                7,191
              </span>
            </h3>
            <p className="text-gray-300 mt-2 text-base lg:text-lg md:text-sm">
              # of Agents
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
