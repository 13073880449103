import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const ActivePackages = () => {
  const [packages, setPackages] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPackage, setCurrentPackage] = useState({
    id: "",
    packageName: "",
    apartmentOwners: "",
    designDrawings: "",
    kitchen: "",
    bathroom: "",
    doors: "",
    painting: "",
  });

  useEffect(() => {
    // Fetch packages from the backend
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/packages"
        );
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };
    fetchPackages();
  }, []);

  const handleDeletePackage = async (id) => {
    try {
      await axios.delete(
        `https://api.bhk99.com/api/packages/${id}`
      );
      setPackages(packages.filter((pkg) => pkg._id !== id));
      toast.error("Package deleted successfully!");
    } catch (error) {
      toast.error("Error deleting package.");
      console.error(error);
    }
  };

  const handleEditPackage = (pkg) => {
    setCurrentPackage(pkg);
    setIsEditing(true);
  };

  const handleQuillChange = (name, value) => {
    setCurrentPackage({ ...currentPackage, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        `https://api.bhk99.com/api/packages/${currentPackage._id}`,
        currentPackage
      );
      setPackages(
        packages.map((pkg) =>
          pkg._id === currentPackage._id ? response.data : pkg
        )
      );
      setIsEditing(false);
      toast.success("Package updated successfully!");
    } catch (error) {
      toast.error("Error updating package.");
      console.error(error);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg max-w-3xl mx-auto mt-10">
      <h2 className="text-4xl font-bold mb-8 text-center text-gray-800">
        Active Packages
      </h2>

      {isEditing ? (
        <div className="bg-white p-6 rounded-lg shadow-md mb-6">
          <h2 className="text-xl font-semibold mb-4">Edit Package</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                className="block text-sm font-medium mb-1"
                htmlFor="packageName"
              >
                Package Name:
              </label>
              <input
                className="border border-gray-300 rounded-md w-full p-2"
                type="text"
                id="packageName"
                name="packageName"
                value={currentPackage.packageName}
                onChange={(e) =>
                  setCurrentPackage({
                    ...currentPackage,
                    packageName: e.target.value,
                  })
                }
                required
              />
            </div>

            {[
              "apartmentOwners",
              "designDrawings",
              "kitchen",
              "bathroom",
              "doors",
              "painting",
            ].map((field) => (
              <div className="mb-4" key={field}>
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor={field}
                >
                  {field.charAt(0).toUpperCase() +
                    field.slice(1).replace(/([A-Z])/g, " $1")}
                  :
                </label>
                <ReactQuill
                  value={currentPackage[field]}
                  onChange={(value) => handleQuillChange(field, value)}
                  theme="snow"
                  className="bg-white rounded-md"
                />
              </div>
            ))}

            <button
              className="bg-orange-500 text-white font-semibold rounded-md py-2 px-4 w-full hover:bg-orange-600 transition duration-300"
              type="submit"
            >
              Update Package
            </button>
          </form>
        </div>
      ) : (
        <table className="table-auto w-full">
          <thead>
            <tr>
              <th className="px-4 py-2">Package Name</th>
              <th className="px-4 py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {packages.map((pkg) => (
              <tr key={pkg._id}>
                <td className="border px-4 py-2">
                  {pkg.packageName.replace(/<\/?strong>/g, "")}
                </td>
                <td className="border px-4 py-2 flex justify-center space-x-4">
                  <button
                    onClick={() => handleEditPackage(pkg)}
                    className="bg-orange-500 text-white font-semibold rounded-lg py-2 px-4  hover:bg-orange-600 transition duration-300"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => handleDeletePackage(pkg._id)}
                    className="bg-orange-500 text-white font-semibold rounded-lg py-2 px-4  hover:bg-orange-600 transition duration-300"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <ToastContainer />
    </div>
  );
};

export default ActivePackages;
