import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ActiveBankingPartners = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPartner, setEditingPartner] = useState(null);
  const [editBankName, setEditBankName] = useState('');
  const [editLogo, setEditLogo] = useState(null); // State for the uploaded logo

  useEffect(() => {
    const fetchPartners = async () => {
      try {
        const response = await axios.get('https://api.bhk99.com/api/bankingpartners');
        if (response.status === 200) {
          setPartners(response.data);
        } else {
          throw new Error('Failed to fetch banking partners');
        }
      } catch (error) {
        console.error('Error fetching banking partners:', error);
        setError('Failed to fetch banking partners');
        toast.error('Error fetching banking partners');
      } finally {
        setLoading(false);
      }
    };

    fetchPartners();
  }, []);

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this banking partner?')) {
      try {
        await axios.delete(`https://api.bhk99.com/api/bankingpartners/${id}`);
        setPartners(partners.filter((partner) => partner._id !== id));
        toast.success('Banking Partner deleted successfully!');
      } catch (error) {
        console.error('Error deleting banking partner:', error);
        toast.error('Error deleting banking partner');
      }
    }
  };

  const handleEditClick = (partner) => {
    setEditingPartner(partner._id);
    setEditBankName(partner.bankName);
    setEditLogo(null); // Reset the logo when editing
  };

  const handleEditSubmit = async (id) => {
    const formData = new FormData();
    formData.append('bankName', editBankName);

    // Only append logo if a new one is uploaded
    if (editLogo) {
      formData.append('logo', editLogo); // Add the file itself, not Base64 string
    }

    try {
      const response = await axios.put(
        `https://api.bhk99.com/api/bankingpartners/${id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      const updatedPartners = partners.map((partner) =>
        partner._id === id
          ? { ...partner, bankName: editBankName, logoUrl: response.data.logoUrl || partner.logoUrl }
          : partner
      );
      setPartners(updatedPartners);
      setEditingPartner(null);
      toast.success('Banking Partner updated successfully!');
    } catch (error) {
      console.error('Error updating banking partner:', error);
      toast.error('Error updating banking partner');
    }
  };

  if (loading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4 text-center">Active Banking Partners</h2>

      {partners.length === 0 ? (
        <p className="text-center">No active banking partners to display.</p>
      ) : (
        <div className="flex flex-col space-y-4">
          {partners.map((partner) => (
            <div key={partner._id} className="flex items-center justify-between bg-white shadow-md p-4 rounded-lg">
              {editingPartner === partner._id ? (
                <div className="flex-1">
                  <input
                    type="text"
                    value={editBankName}
                    onChange={(e) => setEditBankName(e.target.value)}
                    className="border p-2 rounded w-full mb-2"
                    placeholder="Edit Bank Name"
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => setEditLogo(e.target.files[0])} // Handle logo upload
                    className="border p-2 rounded w-full mb-2"
                  />
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditSubmit(partner._id)}
                      className="bg-orange-500 text-white hover:bg-orange-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Save Changes
                    </button>
                    <button
                      onClick={() => setEditingPartner(null)}
                      className="bg-gray-500 text-white hover:bg-gray-600 text-white font-bold py-2 px-4 rounded"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <img
                    src={partner.logoUrl}
                    alt={partner.bankName}
                    className="w-16 h-16 object-cover rounded-full"
                  />
                  <span>{partner.bankName}</span>
                  <div className="space-x-2">
                    <button
                      onClick={() => handleEditClick(partner)}
                      className="bg-orange-500 text-white hover:bg-orange-600 py-2 px-4 rounded"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(partner._id)}
                      className="bg-red-500 text-white hover:bg-red-600 py-2 px-4 rounded"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ActiveBankingPartners;
