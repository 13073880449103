import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddServiceDetailsHome from "../Admin Panel/AddServiceDetailsHome"; // New import
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import NavbarMobile from "../NavbarMobile";
import { Footer } from "../Footer";
import ServiceDetails from "./ServiceDetails";

const MainServicePage = () => {
  const { serviceId } = useParams(); // Capture serviceId from the route
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      
      {/* Use AddServiceDetailsHome to fetch and pass service data */}
      <AddServiceDetailsHome>
        {({ service }) => <ServiceDetails service={service} />}
      </AddServiceDetailsHome>
      
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}{" "}
    </div>
  );
};

export default MainServicePage;
