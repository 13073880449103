import React, { useState, useEffect } from "react";
import axios from "axios";

const ComparePackage = () => {
  const [packages, setPackages] = useState([]);
  const [firstPackage, setFirstPackage] = useState(null);
  const [secondPackage, setSecondPackage] = useState(null);
  const [compareData, setCompareData] = useState(null);

  // Fetch packages from the server
  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/packages"
        );
        setPackages(response.data);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  // Strip HTML tags
  const stripHtmlTags = (text) => {
    return text ? text.replace(/<\/?[^>]+(>|$)/g, "") : "";
  };

  // Handle compare button click
  const handleCompare = () => {
    if (firstPackage && secondPackage) {
      const package1 = packages.find((pkg) => pkg._id === firstPackage);
      const package2 = packages.find((pkg) => pkg._id === secondPackage);
      setCompareData({ package1, package2 });
    } else {
      alert("Please select both packages to compare.");
    }
  };

  return (
    <div className="container mx-auto py-12 px-4 lg:px-20">
      <h2 className="text-5xl font-extrabold text-center mb-12 text-[#FE6929] tracking-wide">
        Compare Our Packages
      </h2>

      <div className="flex flex-col md:flex-row gap-10 justify-center items-center mb-12">
        <div className="w-full md:w-1/2 lg:w-1/3">
          <label className="block text-lg font-medium mb-2 text-gray-800">
            Select First Package
          </label>
          <select
            className="block w-full p-4 border border-gray-300 rounded-lg focus:ring-[#FE6929] focus:border-[#FE6929] transition duration-300 ease-in-out shadow-sm hover:shadow-lg"
            value={firstPackage}
            onChange={(e) => setFirstPackage(e.target.value)}
          >
            <option value="">-- Select Package --</option>
            {packages.map((pkg) => (
              <option key={pkg._id} value={pkg._id}>
                {stripHtmlTags(pkg.packageName)}
              </option>
            ))}
          </select>
        </div>

        <div className="w-full md:w-1/2 lg:w-1/3">
          <label className="block text-lg font-medium mb-2 text-gray-800">
            Select Second Package
          </label>
          <select
            className="block w-full p-4 border border-gray-300 rounded-lg focus:ring-[#FE6929] focus:border-[#FE6929] transition duration-300 ease-in-out shadow-sm hover:shadow-lg"
            value={secondPackage}
            onChange={(e) => setSecondPackage(e.target.value)}
          >
            <option value="">-- Select Package --</option>
            {packages.map((pkg) => (
              <option key={pkg._id} value={pkg._id}>
                {stripHtmlTags(pkg.packageName)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="px-10 py-4 bg-[#FE6929] text-white font-semibold rounded-full shadow-lg hover:bg-[#e15d22] hover:shadow-2xl transform hover:scale-105 transition duration-300 ease-in-out"
          onClick={handleCompare}
        >
          Compare Packages
        </button>
      </div>

      {compareData && (
        <div className="mt-12 bg-white shadow-2xl rounded-lg overflow-hidden">
          <h3 className="text-3xl font-semibold text-center text-[#FE6929] py-6">
            Package Comparison
          </h3>
          <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200">
              <thead>
                <tr className="bg-[#FE6929] text-white text-left">
                  <th className="p-5 border border-gray-200">Feature</th>
                  <th className="p-5 border border-gray-200">
                    {stripHtmlTags(compareData.package1.packageName)}
                  </th>
                  <th className="p-5 border border-gray-200">
                    {stripHtmlTags(compareData.package2.packageName)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {[
                  "apartmentOwners",
                  "designDrawings",
                  "kitchen",
                  "bathroom",
                  "doors",
                  "painting",
                ].map((feature, index) => (
                  <tr
                    key={index}
                    className="hover:bg-gray-100 transition duration-200"
                  >
                    <td className="p-5 text-lg border border-gray-200 capitalize font-medium text-gray-700">
                      {feature.replace(/([A-Z])/g, " $1").trim()}
                    </td>
                    <td className="p-5 text-lg border border-gray-200 text-gray-600">
                      {stripHtmlTags(compareData.package1[feature])}
                    </td>
                    <td className="p-5 text-lg border border-gray-200 text-gray-600">
                      {stripHtmlTags(compareData.package2[feature])}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComparePackage;
