import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export const Projects = () => {
  const [projects, setProjects] = useState([]);
  const scrollAmount = 300;
  const containerRef = useRef(null);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetch/projectsHome"
        );
        console.log(response.data);
        setProjects([...response.data, ...response.data]); // Duplicate for infinite scrolling
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      if (direction === "left") {
        containerRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: "smooth",
        });
      } else if (direction === "right") {
        const newScrollLeft = scrollLeft + scrollAmount;
        if (newScrollLeft >= scrollWidth - clientWidth) {
          setTimeout(() => {
            containerRef.current.scrollTo({ left: 0, behavior: "smooth" });
          }, 300);
        } else {
          containerRef.current.scrollTo({
            left: newScrollLeft,
            behavior: "smooth",
          });
        }
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleScroll("right");
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="py-20 bg-[#FFFFFF] relative hidden md:block">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-semibold font-poppins mb-3 lg:ml-[620px]">
          Our Projects
        </h2>
        <span className="text-gray-400 text-sm font-poppins mb-24 block lg:ml-[300px] md:text-wrap">
          With our personalized approach and extensive listing, we'll guide you
          to your ideal abode, where comfort and happiness await.
        </span>
        <div className="relative mt-4 lg:ml-20">
          <div
            ref={containerRef}
            className="flex space-x-4 lg:space-x-16 overflow-hidden mt-5"
          >
            {projects.map((project, index) => (
              <div
                key={index}
                className={`flex-shrink-0 w-full h-80px sm:w-80 md:w-[280px] md:h-[280px] bg-white shadow-md rounded-3xl ${
                  index === 0
                    ? "ml-0"
                    : "ml-4 sm:ml-6 md:ml-8 lg:mb-[20px] md:mb-[20px]"
                }`}
              >
                <img
                  src={project.propertyImage || "images/placeholder.png"}
                  alt={project.title}
                  className="lg:w-full lg:h-2/3 object-cover rounded-t-3xl"
                />
                <div className="p-4 sm:p-6 mb-6">
                  <div className="flex items-center justify-between">
                    <div className="mb-12">
                      <h3 className="text-sm sm:text-lg font-semibold font-poppins text-[#FE6929]">
                        {project.title}
                      </h3>
                      <p className="text-md sm:text-xl font-extrabold text-black-600 font-poppins">
                        {project.cityName}
                      </p>
                    </div>
                    <p className="text-xs sm:text-xs font-extrabold text-gray-400 font-poppins lg-mb-7 md:mb-16">
                      <p>{project.propertyType}</p>
                      <Link to={`/project2/${project._id}`}>
                        <button className="mt-4 px-4 py-2 border border-[#FE6929] text-[#FE6929] font-semibold rounded-full hover:bg-[#FE6929] hover:text-white transition-colors duration-300">
                          See Details
                        </button>
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="absolute inset-x-0 flex justify-center space-x-2 md:bottom-[-80px] lg:bottom-[-100px]">
            <button
              className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => handleScroll("left")}
              aria-label="Scroll left"
            >
              <FontAwesomeIcon
                icon={faArrowLeft}
                className="text-sm sm:text-lg"
              />
            </button>
            <button
              className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
              onClick={() => handleScroll("right")}
              aria-label="Scroll right"
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-sm sm:text-lg"
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
