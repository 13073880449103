// AddEmail.js
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const AddEmail = () => {
  const [email, setEmail] = useState('');

  const handleAddEmail = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.bhk99.com/api/add-email', { email });
      toast.success('Email added successfully');
      setEmail('');
    } catch (error) {
      toast.error('Error adding email');
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold text-black mb-4">Add Email</h2>
      <form onSubmit={handleAddEmail}>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter email"
          className="w-full p-2 mb-4 text-black"
          required
        />
        <button type="submit" className="bg-orange-500 text-white px-4 py-2 rounded">
          Add Email
        </button>
      </form>
    </div>
  );
};
