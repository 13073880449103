import { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const ActiveTermsPolicy = () => {
  const [termsPolicies, setTermsPolicies] = useState([]);
  const [editName, setEditName] = useState('');
  const [editLink, setEditLink] = useState('');
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchTermsPolicies();
  }, []);

  const fetchTermsPolicies = async () => {
    try {
      const response = await axios.get('https://api.bhk99.com/api/terms-policy');
      setTermsPolicies(response.data);
    } catch (error) {
      toast.error('Error fetching Terms and Policy');
    }
  };

  const handleEdit = (id, name, link) => {
    setEditingId(id);
    setEditName(name);
    setEditLink(link);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://api.bhk99.com/api/terms-policy/${editingId}`, { name: editName, link: editLink });
      toast.success('Terms and Policy updated successfully');
      setEditingId(null);
      setEditName('');
      setEditLink('');
      fetchTermsPolicies();
    } catch (error) {
      toast.error('Error updating Terms and Policy');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://api.bhk99.com/api/terms-policy/${id}`);
      toast.success('Terms and Policy deleted successfully');
      fetchTermsPolicies();
    } catch (error) {
      toast.error('Error deleting Terms and Policy');
    }
  };

  return (
    <div className="p-8 bg-gradient-to-r from-blue-100 to-purple-100 shadow-lg rounded-lg max-w-3xl mx-auto mt-8">
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Active Terms and Policy</h2>
      {termsPolicies.length > 0 ? (
        <ul className="space-y-4">
          {termsPolicies.map((policy) => (
            <li
              key={policy._id}
              className="bg-white p-4 rounded-lg shadow-md flex flex-col md:flex-row md:items-center justify-between hover:bg-gray-50"
            >
              {editingId === policy._id ? (
                <div className="w-full flex flex-col md:flex-row md:items-center md:space-x-4">
                  <input
                    type="text"
                    value={editName}
                    onChange={(e) => setEditName(e.target.value)}
                    placeholder="Link Name"
                    className="w-full md:w-1/3 p-2 border border-gray-300 rounded mb-2 md:mb-0 focus:outline-none focus:ring focus:border-blue-300"
                  />
                  <input
                    type="text"
                    value={editLink}
                    onChange={(e) => setEditLink(e.target.value)}
                    placeholder="Link URL"
                    className="w-full md:w-2/3 p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
                  />
                  <div className="flex space-x-2 mt-2 md:mt-0">
                    <button
                      onClick={handleUpdate}
                      className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
                    >
                      Save
                    </button>
                    <button
                      onClick={() => setEditingId(null)}
                      className="px-4 py-2 bg-gray-400 text-white rounded hover:bg-gray-500"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="w-full flex flex-col md:flex-row md:items-center md:justify-between">
                  <div className="flex flex-col">
                    <span className="font-semibold text-gray-800">{policy.name}</span>
                    <a
                      href={policy.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:underline"
                    >
                      {policy.link}
                    </a>
                  </div>
                  <div className="flex space-x-2 mt-2 md:mt-0">
                    <button
                      onClick={() => handleEdit(policy._id, policy.name, policy.link)}
                      className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(policy._id)}
                      className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </div>
                </div>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className="text-center text-gray-600">No Terms and Policy available.</p>
      )}
    </div>
  );
};
