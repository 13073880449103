import React, { useState } from 'react';

const ServiceDetails = ({ service }) => {
  const [imageError, setImageError] = useState(false);

  // Display message if service is not found
  if (!service) {
    return <div className="text-center text-red-500">Service not found</div>;
  }

  // Determine the image source based on whether it's base64 or a URL
  const isBase64 = (str) => {
    const pattern = /^data:image\/[a-zA-Z]+;base64,/;
    return pattern.test(str);
  };

  const imageUrl = isBase64(service.image) ? service.image : `data:image/jpeg;base64,${service.image}`;

  // Log the image URL for debugging
  console.log("Image URL:", imageUrl);

  // Function to convert HTML to plain text
  const convertHtmlToText = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  return (
    <div className="service-details">
      <div className="relative">
        <img
          src={imageUrl} // Use the computed image URL
          alt={service.title}
          className={`w-full h-[100vh] object-cover ${imageError ? 'hidden' : ''}`}
          onError={() => setImageError(true)} // Set error state if image fails to load
        />
        {imageError && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200">
            <p className="text-lg text-gray-500">Image could not be loaded.</p>
          </div>
        )}
        <h1 className="absolute left-0 top-1/2 transform -translate-y-1/2 text-white text-4xl font-bold bg-black bg-opacity-50 px-2 py-2 rounded-2xl md:ml-4 lg:ml-8">
          {service.title}
        </h1>
      </div>

      <div className="container mx-auto px-4 py-8 text-left">
        <h2 className="text-3xl font-semibold mb-4 text-center">{service.title}</h2>
        <div className="text-center lg:ml-16 lg:mr-16">{convertHtmlToText(service.description)}</div>
      </div>
    </div>
  );
};

export default ServiceDetails;
