import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "./components/Home";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { Main } from "./components/Calculators/Main";
import OurProject from "./components/Our Project/OurProject";
import ContactUs from "./components/Contact/ContactUs";
import HowItWorks from "./components/HowItWorks/HowItWorks";
import MainPage from "./components/ProjectDetails/MainPage";
import HouseCalci from "./components/Calculators/HouseCalci";
import EmiCalci from "./components/Calculators/EMICalci";
import ThreeDMain from "./components/ThreeDPlans/ThreeDMain";
import MainServicePage from "./components/Service Details/MainServicePage";
import ScrollToTop from "./components/ScrollToTop";
import AdminLogin from "./components/Admin Panel/AdminLogin";
import AdminLayout from "./components/Admin Panel/AdminLayout";
import AddProjectForm from "./components/Admin Panel/AddProjectForm";
import EditProjectForm from "./components/Admin Panel/EditProjectForm";
import HouseConstructorCalculator from "./components/Admin Panel/HouseConstructorCalculator";
import { HouseCalciComp } from "./components/Calculators/HouseCalciComp";
import { HouseCalciCompTab } from "./components/Calculators/HouseCalciCompTab";
import { HouseCalciCompMobile } from "./components/Calculators/HouseCalciCompMobile";
import MainComparePackages from "./components/ComparePackage/MainComparePackages";
import ForgotPassword from "./components/Admin Panel/ForgotPassword";
import UserLayout from "./components/Admin Panel/UserLayout";
import MainPage2 from "./components/ProjectDetails/MainPage2";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/calci" element={<Main />} />
        <Route path="/OurProjects" element={<OurProject />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/HowItWorks" element={<HowItWorks />} />
        <Route path="/project" element={<MainPage />} />
        <Route path="/project2/:id" element={<MainPage2 />} />
        <Route path="/emi-calci" element={<EmiCalci />} />
        <Route path="/3d-plans" element={<ThreeDMain />} />
        <Route path="/services/:serviceId" element={<MainServicePage />} />
        <Route path="/house-calci" element={<HouseCalci />} />
        <Route path="/admin-layout" element={<AdminLayout />} />
        <Route path="/login" element={<AdminLogin />} />
        <Route path="/add-project" element={<AddProjectForm />} />
        <Route path="/edit-project" element={<EditProjectForm />} />
        <Route path="/houseCalci" element={<HouseCalciComp />} />
        <Route path="/houseCalciTab" element={<HouseCalciCompTab />} />
        <Route path="/houseCalciMob" element={<HouseCalciCompMobile />} />
        <Route
          path="/admin-houseCalci"
          element={<HouseConstructorCalculator />}
        />
        <Route path="/compare-packages" element={<MainComparePackages />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/user-layout" element={<UserLayout />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
