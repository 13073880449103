import axios from "axios";
import React, { useState } from "react";

const ThreeDBanner = () => {
  const [image, setImage] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setImageFile(file); // Store the file for upload
    }
  };

  const handleAddImage = async () => {
    if (!imageFile) {
      alert("Please select an image first.");
      return;
    }

    const formData = new FormData();
    formData.append("floor3DBanner", imageFile); // Change field name to floor3DBanner

    try {
      const response = await axios.post(
        "https://api.bhk99.com/banner/addBanners",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("3D Banner added successfully!");
      }
    } catch (error) {
      console.error("Error uploading image:", error);
      alert("Failed to add image.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">3D Banner</h1>

      <input
        type="file"
        accept="image/*"
        onChange={handleImageChange}
        className="mb-4"
      />

      {image && (
        <div className="mb-4">
          <img
            src={image}
            alt="Selected"
            className="max-w-full h-auto rounded-lg"
          />
        </div>
      )}

      <button
        onClick={handleAddImage}
        className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300"
      >
        Add 3D Banner
      </button>
    </div>
  );
};

export default ThreeDBanner;
