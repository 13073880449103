import React, { useState, useEffect } from "react";
import Banner3 from "./Banner3";
import { CalciTile } from "./CalciTile";
import { HeroCalci } from "./HeroCalci";
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import { Footer } from "../Footer";
import NavbarMobile from "../NavbarMobile";

export const Main = () => {
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <HeroCalci />
      <CalciTile />
      <Banner3 />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
};
