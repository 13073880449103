// InformationLinks.js
import { useEffect, useState } from 'react';
import axios from 'axios';

export const InformationLinks = () => {
  const [links, setLinks] = useState([]);

  useEffect(() => {
    const fetchInformationLinks = async () => {
      try {
        const response = await axios.get('https://api.bhk99.com/api/terms-policy');
        if (response.data && response.data.length > 0) {
          setLinks(response.data);
        }
      } catch (error) {
        console.error('Error fetching information links:', error);
      }
    };
    fetchInformationLinks();
  }, []);

  return (
    <ul className="space-y-4 mt-4 text-gray-300 text-sm">
      {links.map((item, index) => (
        <li key={index}>
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-[#FE6929]"
          >
            {item.name}
          </a>
        </li>
      ))}
    </ul>
  );
};

export default InformationLinks;
