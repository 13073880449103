import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddNews = () => {
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    // Create form data to send the content and image
    const formData = new FormData();
    formData.append("content", content);
    formData.append("image", image);

    try {
      const response = await axios.post(
        "https://api.bhk99.com/addNews/add",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 201) {
        toast.success("News added successfully!");
      }
    } catch (error) {
      toast.error("Failed to add news. Please try again!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Add News</h2>

      {message && (
        <div
          className={`mb-4 text-center ${
            message.includes("successfully") ? "text-green-500" : "text-red-500"
          }`}
        >
          {message}
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Quill Editor */}
        <div>
          <label className="block text-lg font-medium mb-2">News Content</label>
          <ReactQuill
            value={content}
            onChange={handleContentChange}
            theme="snow"
            placeholder="Write your news content here..."
          />
        </div>

        <div>
          <label className="block text-lg font-medium mb-2">Upload Image</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="w-full p-2 border border-gray-300 rounded"
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            disabled={loading}
            className={`w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-200 ${
              loading ? "opacity-50 cursor-not-allowed" : ""
            }`}
          >
            {loading ? "Submitting..." : "Add News"}
          </button>
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddNews;