import React, { useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AddUser = () => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    password: "",
    designation: "",
    permissions: [], // New field for permissions
  });

  const [otpSent, setOtpSent] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [enteredOtp, setEnteredOtp] = useState("");

  const permissionsOptions = [
    "Service",
    "Our Project",
    "Cost Estimator",
    "Packages",
    "Inbox",
    "Users",
    "FAQ",
    "News",
    "Extras",
    "Banking partners",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "permissions") {
      const updatedPermissions = formData.permissions.includes(value)
        ? formData.permissions.filter((perm) => perm !== value)
        : [...formData.permissions, value];
      setFormData({ ...formData, permissions: updatedPermissions });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSendOTP = async () => {
    try {
      const response = await axios.post(
        "https://api.bhk99.com/send-otp",
        {
          userEmail: formData.email,
        }
      );
      setOtp(response.data.otp);
      setOtpSent(true);
      toast.success("OTP sent to admin email!");
    } catch (error) {
      console.error("Error sending OTP", error);
      toast.error("Failed to send OTP");
    }
  };

  const handleVerifyOTP = () => {
    if (enteredOtp === otp.toString()) {
      setOtpVerified(true);
      toast.success("OTP verified successfully!");
    } else {
      toast.error("Invalid OTP. Please try again.");
    }
  };

  const handleRegisterUser = async (e) => {
    e.preventDefault();

    if (!otpVerified) {
      toast.error("Please verify OTP before registering");
      return;
    }

    try {
      const response = await axios.post(
        "https://api.bhk99.com/api/users/register-user",
        {
          fullName: formData.fullName,
          email: formData.email,
          phone: formData.phone,
          password: formData.password,
          designation: formData.designation,
          permissions: formData.permissions, // Include permissions in the request
        }
      );

      toast.success(response.data.message);
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        password: "",
        designation: "",
        permissions: [], // Reset permissions
      });
      setOtpSent(false);
      setOtpVerified(false);
    } catch (error) {
      if (error.response) {
        console.error("Error registering user", error.response.data);
        toast.error(`Error: ${error.response.data.message}`);
      } else {
        console.error("Error", error.message);
        toast.error("Failed to register user. Please check your input.");
      }
    }
  };

  return (
    <div className="p-8 bg-white max-w-md mx-auto shadow-md rounded-lg">
      <h1 className="text-xl font-bold mb-4">Add User</h1>
      <form onSubmit={handleRegisterUser}>
        <input
          type="text"
          name="fullName"
          placeholder="Full Name"
          value={formData.fullName}
          onChange={handleInputChange}
          required
          className="border rounded p-2 mb-4 w-full"
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={formData.email}
          onChange={handleInputChange}
          required
          className="border rounded p-2 mb-4 w-full"
        />
        <input
          type="text"
          name="phone"
          placeholder="Phone"
          value={formData.phone}
          onChange={handleInputChange}
          required
          className="border rounded p-2 mb-4 w-full"
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={formData.password}
          onChange={handleInputChange}
          required
          className="border rounded p-2 mb-4 w-full"
        />
        <input
          type="text"
          name="designation"
          placeholder="Designation"
          value={formData.designation}
          onChange={handleInputChange}
          required
          className="border rounded p-2 mb-4 w-full"
        />

        <h3 className="font-bold mb-2">Permissions</h3>
        {permissionsOptions.map((perm) => (
          <label key={perm} className="flex items-center mb-2">
            <input
              type="checkbox"
              name="permissions"
              value={perm}
              checked={formData.permissions.includes(perm)}
              onChange={handleInputChange}
              className="mr-2"
            />
            {perm}
          </label>
        ))}

        <button
          type="button"
          onClick={handleSendOTP}
          className="bg-blue-500 text-white py-2 px-4 rounded mb-4"
        >
          Send OTP
        </button>
        {otpSent && (
          <div>
            <input
              type="text"
              value={enteredOtp}
              onChange={(e) => setEnteredOtp(e.target.value)}
              placeholder="Enter OTP"
              className="border rounded p-2 mb-4 w-full"
            />
            <button
              type="button"
              onClick={handleVerifyOTP}
              className="bg-green-500 text-white py-2 px-4 rounded mb-4"
            >
              Verify OTP
            </button>
          </div>
        )}

        <button
          type="submit"
          className="bg-green-600 text-white py-2 px-4 rounded"
        >
          Register User
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddUser;
