import React, { useEffect, useState } from "react";
import axios from "axios";

const EditPW = () => {
  const [walkthroughs, setWalkthroughs] = useState([]);
  const [selectedWalkthrough, setSelectedWalkthrough] = useState(null);
  const [videoLink, setVideoLink] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    // Fetch active walkthroughs from the backend
    const fetchWalkthroughs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/pw/getAllWalkthroughs"
        );
        setWalkthroughs(response.data); // Set all fetched data directly
      } catch (error) {
        console.error("Error fetching walkthrough data:", error);
      }
    };

    fetchWalkthroughs();
  }, []);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selected = walkthroughs.find(
      (walkthrough) => walkthrough._id === selectedId
    );

    if (selected) {
      setSelectedWalkthrough(selected);
      setVideoLink(selected.videoLink || ""); // Handle potential undefined videoLink
      setDescription(selected.description || ""); // Handle potential undefined description
    } else {
      // Reset state if no walkthrough is selected
      setSelectedWalkthrough(null);
      setVideoLink("");
      setDescription("");
    }
  };

  const handleSave = async () => {
    if (!selectedWalkthrough) return; // Prevent saving if nothing is selected

    try {
      await axios.put(
        `https://api.bhk99.com/pw/updateWalkthrough/${selectedWalkthrough._id}`, // Use _id here
        {
          videoLink,
          description,
        }
      );
      alert("Walkthrough updated successfully!");
      setSelectedWalkthrough(null);
      setVideoLink("");
      setDescription("");
    } catch (error) {
      console.error("Error updating walkthrough:", error);
      alert("Failed to update walkthrough.");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Edit Walkthrough</h2>

      {/* Dropdown to select walkthrough */}
      <label htmlFor="walkthrough-select" className="block font-semibold mb-2">
        Select a Walkthrough:
      </label>
      <select
        id="walkthrough-select"
        className="w-full p-2 border border-gray-300 rounded mb-4"
        value={selectedWalkthrough ? selectedWalkthrough._id : ""} // Use _id for value
        onChange={handleSelectChange}
      >
        <option value="">Select a walkthrough</option>
        {walkthroughs.map((walkthrough) => (
          <option key={walkthrough._id} value={walkthrough._id}>
            {" "}
            {/* Use _id */}
            {walkthrough.description}
          </option>
        ))}
      </select>

      {/* Edit form */}
      {selectedWalkthrough && (
        <div>
          <label className="block font-semibold mb-2">Video Link:</label>
          <input
            type="text"
            className="w-full p-2 border border-gray-300 rounded mb-4"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
          />

          <label className="block font-semibold mb-2">Description:</label>
          <textarea
            className="w-full p-2 border border-gray-300 rounded mb-4"
            rows="4"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></textarea>

          <button
            onClick={handleSave}
            className="w-full bg-orange-500 text-white py-3 rounded-lg hover:bg-orange-600 transition duration-200"
          >
            Save Changes
          </button>
        </div>
      )}
    </div>
  );
};

export default EditPW;
