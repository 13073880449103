import React, { useEffect, useState } from "react";
import axios from "axios";

export const HeroCalci = () => {
  const [calciHeroImage, setCalciHeroImage] = useState("");

  useEffect(() => {
    const fetchCalciHeroImage = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/hero/getHero"
        );
        setCalciHeroImage(
          `data:image/jpeg;base64,${response.data.costEstimatorhero}`
        );
      } catch (error) {
        console.error("Error fetching Cost Estimator hero image:", error);
      }
    };

    fetchCalciHeroImage();
  }, []);

  return (
    <>
      <section
        className="relative h-[300px] md:h-[520px] flex flex-col items-start justify-start bg-center"
        style={{
          backgroundImage: `url(${calciHeroImage || "images/calciHero.jpg"})`,
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-left text-white p-8 mt-[70px] lg:mt-[200px] lg:ml-24 md:mt-44 md:ml-14">
          <h1 className="text-4xl tracking-tight leading-10 font-bold font-poppins text-white sm:text-5xl sm:leading-none md:text-6xl lg:text-7xl lg:mb-[10px] md:mb-[10px]">
            Cost Estimator
          </h1>
          <span className="text-gray-400 text-sm font-poppins mt-4 mb-24">
            Calculate your EMI or house construction costs using the calculators
            below.
          </span>
        </div>
      </section>
    </>
  );
};
