import React, { useState, useEffect } from "react";

const ProjectTable = () => {
  const [projects, setProjects] = useState([]); // State to hold projects
  const [currentPage, setCurrentPage] = useState(1); // Current page number
  const itemsPerPage = 5; // Number of items per page
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await fetch(
          "https://api.bhk99.com/fetch/projects"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        setProjects(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Calculate total pages
  const totalPages = Math.ceil(projects.length / itemsPerPage);

  // Get the current items based on the currentPage and itemsPerPage
  const currentItems = projects.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Handle page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="max-w-4xl mx-auto mt-10 px-4">
      <h3 className="text-xl font-semibold mb-4 text-center text-[#FE6929]">
        Project List
      </h3>
      <table className="min-w-full bg-white shadow-lg rounded-lg overflow-hidden">
        <thead className="bg-[#FE6929] text-white">
          <tr>
            <th className="py-3 px-4 text-left text-sm font-semibold uppercase">
              City
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold uppercase">
              Title
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold uppercase">
              House Budget
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold uppercase">
              Number of Bedrooms
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold uppercase">
              Plot Dimensions
            </th>
            <th className="py-3 px-4 text-left text-sm font-semibold uppercase">
              Property Type
            </th>
            <th className="py-3 px-4 text-center text-sm font-semibold uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((project, index) => (
            <tr
              key={index}
              className={`border-b ${
                index % 2 === 0 ? "bg-gray-100" : "bg-white"
              } hover:bg-gray-200`}
            >
              <td className="py-3 px-4">{project.cityName}</td>
              <td className="py-3 px-4">{project.title}</td>
              <td className="py-3 px-4">{project.houseBudget}</td>
              <td className="py-3 px-4">{project.numberOfBedrooms}</td>
              <td className="py-3 px-4">{project.plotDimensions}</td>
              <td className="py-3 px-4">{project.propertyType}</td>
              <td className="py-3 px-4 text-center">
                <button className="text-blue-600 hover:underline focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 rounded">
                  View
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 mx-1 text-sm text-gray-700 bg-gray-300 rounded hover:bg-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed"
        >
          Previous
        </button>

        {/* Render page numbers dynamically */}
        {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
          <button
            key={page}
            onClick={() => handlePageChange(page)}
            className={`px-3 py-1 mx-1 text-sm ${
              page === currentPage
                ? "text-white bg-[#FE6929]"
                : "text-gray-700 bg-gray-300 hover:bg-gray-400"
            } rounded`}
          >
            {page}
          </button>
        ))}

        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 mx-1 text-sm text-gray-700 bg-gray-300 rounded hover:bg-gray-400 disabled:bg-gray-200 disabled:cursor-not-allowed"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ProjectTable;
