import { faQuoteLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { Pagination } from "swiper/modules";
import axios from "axios";

const OurProjectWalkthroughsSection = () => {
  const [walkthroughs, setWalkthroughs] = useState([]);

  // Function to strip HTML tags
  const stripHtmlTags = (text) => {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
  };

  useEffect(() => {
    // Fetch walkthrough data from the backend
    const fetchWalkthroughs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/pw/getAllWalkthroughs"
        ); // Replace with your API endpoint
        // console.log(response.data);
        setWalkthroughs(response.data);
      } catch (error) {
        console.error("Error fetching walkthrough data:", error);
      }
    };
    fetchWalkthroughs();
  }, []);

  return (
    <section className="py-12 bg-white">
      <div className="container mx-auto px-8 lg:px-16 text-center">
        <h2 className="section-title font-bold text-4xl">
          Walkthroughs - Video tour
        </h2>
        <span className="text-gray-400 text-sm font-poppins">
          Our 800 happy family members are part of BHK99 family.
        </span>

        {/* Desktop View */}
        <div className="hidden lg:flex lg:flex-row lg:gap-16 mt-6 lg:px-16">
          {walkthroughs.map((item, index) => (
            <div key={index} className="walkthrough-card w-1/3">
              <iframe
                width="100%"
                height="350"
                src={item.videoLink}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
              <div className="p-6">
                <span className="text-black font-poppins font-normal text-sm">
                  <FontAwesomeIcon
                    icon={faQuoteLeft}
                    className="text-[#FE6929]"
                  />{" "}
                  <p className="text-black font-poppins font-normal text-sm">
                    {stripHtmlTags(item.description)}
                  </p>
                  <p className="text-black font-poppins font-semibold text-sm mt-2">
                    {item.userName}
                  </p>
                </span>
              </div>
            </div>
          ))}
        </div>

        {/* Swiper for Mobile and Tablet view */}
        <div className="lg:hidden">
          <Swiper
            spaceBetween={20}
            slidesPerView={1}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            breakpoints={{
              640: { slidesPerView: 1, spaceBetween: 20 },
              768: { slidesPerView: 2, spaceBetween: 20 },
            }}
          >
            {walkthroughs.map((item, index) => (
              <SwiperSlide key={index}>
                <div className="bg-white rounded-lg shadow-lg overflow-hidden flex flex-col">
                  <div className="flex-grow">
                    <iframe
                      width="100%"
                      height="300"
                      src={item.videoUrl}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerPolicy="strict-origin-when-cross-origin"
                      allowFullScreen
                    ></iframe>
                  </div>
                  <div className="p-4 bg-transparent">
                    <span className="text-black font-poppins font-normal text-sm">
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className="text-[#FE6929]"
                      />{" "}
                      <p className="text-black font-poppins font-normal text-sm">
                        {stripHtmlTags(item.description)}
                      </p>
                      <p className="text-black font-poppins font-semibold text-sm mt-2">
                        {item.userName}
                      </p>
                    </span>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};

export default OurProjectWalkthroughsSection;
