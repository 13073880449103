import React, { useState } from "react";
import {
  FaTachometerAlt,
  FaClipboardList,
  FaProjectDiagram,
  FaCalculator,
  FaSuitcase,
  FaEnvelopeOpenText,
  FaBuilding,
} from "react-icons/fa";
import { RiSettings5Fill } from "react-icons/ri";
import {
  MdOutlineKeyboardArrowDown,
  MdOutlineKeyboardArrowUp,
} from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";
import { IoNewspaper } from "react-icons/io5";
import { FaQ } from "react-icons/fa6";

const UserSideBar = ({
  isOpen,
  toggleSidebar,
  setActiveSection,
  userPermissions,
}) => {
  const [isServiceSectionOpen, setIsServiceSectionOpen] = useState(false);
  const [isInboxOpen, setIsInboxOpen] = useState(false);
  const [isProjectSectionOpen, setIsProjectSectionOpen] = useState(false);
  const [isCostEstimatorOpen, setIsCostEstimatorOpen] = useState(false);
  const [isPackagesSectionOpen, setIsPackagesSectionOpen] = useState(false);
  const [isExtraSectionOpen, setIsExtraSectionOpen] = useState(false);
  const [isFAQOpen, setIsFAQOpen] = useState(false);
  const [isNewsOpen, setIsNewsOpen] = useState(false);
  const [isBankingPartnerOpen, setIsBankingPartnerOpen] = useState(false); // State for Cost Estimator Dropdown

  return (
    <aside
      className={`bg-[#FE6929] text-white min-h-screen z-40 transition-transform transform ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      } md:translate-x-0 md:w-72 w-64 fixed md:static`}
    >
      {/* Close button for mobile view */}
      {isOpen && (
        <button
          className="absolute top-4 right-4 text-white"
          onClick={toggleSidebar}
        >
          <AiOutlineClose />
        </button>
      )}

      {/* Company Logo and Admin Panel Heading */}
      <div className="flex items-center p-6">
        <img
          src="/images/logo1.png"
          alt="Company Logo"
          className="w-16 h-16 object-contain mr-2 -mt-6"
        />
        <h1 className="text-xl font-bold text-center -mt-6">User Panel</h1>
      </div>

      {/* Navigation Items */}
      <nav className="mt-12">
        <ul className="space-y-8">
          {/* Dashboard */}
          <li className="flex items-center p-3 rounded hover:bg-[#ff8654] transition duration-200 cursor-pointer">
            <FaTachometerAlt className="text-xl mr-2" />
            <Link to="#" onClick={() => setActiveSection("dashboard")}>
              Dashboard
            </Link>
          </li>

          {/* Service Section with Dropdown */}
          {userPermissions?.includes("Service") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsServiceSectionOpen(!isServiceSectionOpen)}
              >
                <div className="flex items-center">
                  <FaClipboardList className="mr-2 text-xl" />
                  <span>Service Section</span>
                </div>
                {isServiceSectionOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isServiceSectionOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("serviceManage");
                        toggleSidebar();
                      }}
                    >
                      Active Services
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("serviceForm");
                        toggleSidebar();
                      }}
                    >
                      Addition of Service
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}
          {/* Our Projects Section with Dropdown */}

          {userPermissions?.includes("Our Project") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsProjectSectionOpen(!isProjectSectionOpen)}
              >
                <div className="flex items-center">
                  <FaProjectDiagram className="mr-2 text-xl" />
                  <span>Our Projects</span>
                </div>
                {isProjectSectionOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isProjectSectionOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("addProject");
                        toggleSidebar();
                      }}
                    >
                      Add a new project
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("editProject");
                        toggleSidebar();
                      }}
                    >
                      Edit a project
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("deleteProject");
                        toggleSidebar();
                      }}
                    >
                      Delete a project
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("projectTable");
                        toggleSidebar();
                      }}
                    >
                      Active Projects
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* Cost Estimator Section with Dropdown */}
          {userPermissions?.includes("Cost Estimator") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsCostEstimatorOpen(!isCostEstimatorOpen)}
              >
                <div className="flex items-center">
                  <FaCalculator className="mr-2 text-xl" />
                  <span>Cost Estimator</span>
                </div>
                {isCostEstimatorOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isCostEstimatorOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("houseCalculator");
                        toggleSidebar();
                      }}
                    >
                      House Construction Calculator
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("emiCalculator");
                        toggleSidebar();
                      }}
                    >
                      EMI Calculator
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* Other Navigation Items */}
          {/* Packages Section with Dropdown */}
          {userPermissions?.includes("Packages") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsPackagesSectionOpen(!isPackagesSectionOpen)}
              >
                <div className="flex items-center">
                  <FaSuitcase className="mr-2 text-xl" />
                  <span>Packages Section</span>
                </div>
                {isPackagesSectionOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isPackagesSectionOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("ActivePackages");
                        toggleSidebar();
                      }}
                    >
                      Active Packages
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("AddPackage");
                        toggleSidebar();
                      }}
                    >
                      Addition of Package
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* Inbox Section with Dropdown */}
          {userPermissions?.includes("Inbox") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsInboxOpen(!isInboxOpen)}
              >
                <div className="flex items-center">
                  <FaEnvelopeOpenText className="mr-2 text-xl" />
                  <span>Inbox</span>
                </div>
                {isInboxOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isInboxOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("ContactUsAdmin");
                        toggleSidebar();
                      }}
                    >
                      Talk To Expert
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("TalkToExpert");
                        toggleSidebar();
                      }}
                    >
                      Contact Us
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {userPermissions?.includes("FAQ") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsFAQOpen(!isFAQOpen)}
              >
                <div className="flex items-center">
                  <FaQ className="mr-2 text-xl" />
                  <span>FAQ</span>
                </div>
                {isFAQOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isFAQOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("addFAQs");
                        toggleSidebar();
                      }}
                    >
                      Add FAQs
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("editFAQs");
                        toggleSidebar();
                      }}
                    >
                      Edit FAQs
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("deleteFAQs");
                        toggleSidebar();
                      }}
                    >
                      Delete FAQs
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {userPermissions?.includes("News") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsNewsOpen(!isNewsOpen)}
              >
                <div className="flex items-center">
                  <IoNewspaper className="mr-2 text-xl" />
                  <span>News</span>
                </div>
                {isNewsOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isNewsOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("addNews");
                        toggleSidebar();
                      }}
                    >
                      Add News
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("editNews");
                        toggleSidebar();
                      }}
                    >
                      Edit News
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("deleteNews");
                        toggleSidebar();
                      }}
                    >
                      Delete News
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {userPermissions?.includes("Extras") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsExtraSectionOpen(!isExtraSectionOpen)}
              >
                <div className="flex items-center">
                  <RiSettings5Fill className="mr-2 text-xl" />
                  <span>Extras</span>
                </div>
                {isExtraSectionOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isExtraSectionOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("addCustomerTestimonials");
                        toggleSidebar();
                      }}
                    >
                      Add Customer Testimonials
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("deleteCustomerTestimonials");
                        toggleSidebar();
                      }}
                    >
                      Delete Customer Testimonials
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("editCustomerTestimonials");
                        toggleSidebar();
                      }}
                    >
                      Edit Customer Testimonials
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}

          {/* <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsFAQOpen(!isFAQOpen)}
            >
              <div className="flex items-center">
                <FaQ className="mr-2 text-xl" />
                <span>FAQ</span>
              </div>
              {isFAQOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isFAQOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addFAQs");
                      toggleSidebar();
                    }}
                  >
                    Add FAQs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editFAQs");
                      toggleSidebar();
                    }}
                  >
                    Edit FAQs
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteFAQs");
                      toggleSidebar();
                    }}
                  >
                    Delete FAQs
                  </Link>
                </li>
              </ul>
            )}
          </li> */}

          {/* <li className="p-3 rounded">
            <div
              className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
              onClick={() => setIsNewsOpen(!isNewsOpen)}
            >
              <div className="flex items-center">
                <IoNewspaper className="mr-2 text-xl" />
                <span>News</span>
              </div>
              {isNewsOpen ? (
                <MdOutlineKeyboardArrowUp />
              ) : (
                <MdOutlineKeyboardArrowDown />
              )}
            </div>
            {isNewsOpen && (
              <ul className="ml-6 mt-2 space-y-2">
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("addNews");
                      toggleSidebar();
                    }}
                  >
                    Add News
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("editNews");
                      toggleSidebar();
                    }}
                  >
                    Edit News
                  </Link>
                </li>
                <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                  <Link
                    to="#"
                    onClick={() => {
                      setActiveSection("deleteNews");
                      toggleSidebar();
                    }}
                  >
                    Delete News
                  </Link>
                </li>
              </ul>
            )}
          </li> */}

          {/* Banking Section with Dropdown */}
          {userPermissions?.includes("Banking partners") && (
            <li className="p-3 rounded">
              <div
                className="flex items-center justify-between hover:bg-[#ff8654] rounded p-2"
                onClick={() => setIsBankingPartnerOpen(!isBankingPartnerOpen)}
              >
                <div className="flex items-center">
                  <FaBuilding className="mr-2 text-xl" />
                  <span>Banking Partners</span>
                </div>
                {isBankingPartnerOpen ? (
                  <MdOutlineKeyboardArrowUp />
                ) : (
                  <MdOutlineKeyboardArrowDown />
                )}
              </div>
              {isBankingPartnerOpen && (
                <ul className="ml-6 mt-2 space-y-2">
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("ActiveBankingPartners");
                        toggleSidebar();
                      }}
                    >
                      Active Banking Partners
                    </Link>
                  </li>
                  <li className="flex items-center p-2 rounded hover:bg-[#ff8654]">
                    <Link
                      to="#"
                      onClick={() => {
                        setActiveSection("AddBankingPartner");
                        toggleSidebar();
                      }}
                    >
                      Add Banking Partner
                    </Link>
                  </li>
                </ul>
              )}
            </li>
          )}
        </ul>
      </nav>
    </aside>
  );
};

export default UserSideBar;
