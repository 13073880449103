import { HouseCalciComp } from "./HouseCalciComp";
import React, { useState, useEffect } from "react";
import { HouseCalciCompTab } from "./HouseCalciCompTab";
import { HouseCalciCompMobile } from "./HouseCalciCompMobile";
import ContactUs from "./ContactUs";
import ContactMobile from "../ContactMobile";
import FAQ from "../FAQ";
import FAQMobile from "./FAQMobile";
import { Footer } from "../Footer";
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import NavbarMobile from "../NavbarMobile";
import { HeroHouse } from "./HeroHouse";

export const HouseCalci = () => {
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <HeroHouse />
      {viewport === "large" && <HouseCalciComp />}
      {viewport === "medium" && <HouseCalciCompTab />}
      {viewport === "mobile" && <HouseCalciCompMobile />}
      <ContactUs />
      {viewport === "mobile" && <ContactMobile />}
      <FAQ />
      {viewport === "mobile" && <FAQMobile />}
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
};

export default HouseCalci;
