import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill"; // Import Quill
import "react-quill/dist/quill.snow.css"; // Import Quill CSS

const EditTestimonials = () => {
  const [testimonials, setTestimonials] = useState([]);
  const [selectedTestimonial, setSelectedTestimonial] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [details, setDetails] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [showEditButton, setShowEditButton] = useState(false);

  useEffect(() => {
    const fetchTestimonials = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchTestimonials/get-testimonials"
        );
        setTestimonials(response.data);
      } catch (error) {
        console.error("Error fetching testimonials:", error);
      }
    };

    fetchTestimonials();
  }, []);

  const handleSelectChange = async (e) => {
    const selectedId = e.target.value;
    setSelectedTestimonial(selectedId);

    if (selectedId) {
      // Fetch the selected testimonial details
      const selectedTestimonial = testimonials.find(
        (testimonial) => testimonial._id === selectedId
      );
      setCustomerName(selectedTestimonial.customerName);
      setDetails(selectedTestimonial.details);
      setVideoLink(selectedTestimonial.videoLink); // Assume this field exists in your data
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
      setCustomerName("");
      setDetails("");
      setVideoLink("");
    }
  };

  const handleEdit = async () => {
    try {
      await axios.put(
        `https://api.bhk99.com/updateTestimonials/update-testimonials/${selectedTestimonial}`,
        {
          customerName,
          details,
          videoLink, // Include the YouTube link in the update
        }
      );

      // Update the testimonials list
      setTestimonials((prevTestimonials) =>
        prevTestimonials.map((testimonial) =>
          testimonial._id === selectedTestimonial
            ? { ...testimonial, customerName, details, videoLink }
            : testimonial
        )
      );

      // Clear fields
      setSelectedTestimonial("");
      setCustomerName("");
      setDetails("");
      setVideoLink("");
      setShowEditButton(false);

      // Show success notification
      toast.success("Testimonial updated successfully!");
    } catch (error) {
      console.error("Error updating testimonial:", error);
      // Show error notification
      toast.error("Failed to update testimonial.");
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold mb-4">Edit Customer Testimonial</h2>
      <div className="mb-4">
        <label
          htmlFor="testimonialSelect"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select a testimonial to edit
        </label>
        <select
          id="testimonialSelect"
          value={selectedTestimonial}
          onChange={handleSelectChange}
          className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400"
        >
          <option value="">-- Select a customer --</option>
          {testimonials.map((testimonial) => (
            <option key={testimonial._id} value={testimonial._id}>
              {testimonial.customerName}
            </option>
          ))}
        </select>
      </div>

      {showEditButton && (
        <>
          <div className="mb-4">
            <label
              htmlFor="customerName"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Customer Name
            </label>
            <input
              type="text"
              id="customerName"
              value={customerName}
              onChange={(e) => setCustomerName(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="videoLink"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              YouTube Link
            </label>
            <input
              type="text"
              id="videoLink"
              value={videoLink}
              onChange={(e) => setVideoLink(e.target.value)}
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-400"
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="details"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Testimonial Text
            </label>
            <ReactQuill
              id="details"
              value={details}
              onChange={setDetails}
              className="border border-gray-300 rounded-md"
              theme="snow"
            />
          </div>

          <button
            onClick={handleEdit}
            className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929]"
          >
            Update Testimonial
          </button>
        </>
      )}

      <ToastContainer />
    </div>
  );
};

export default EditTestimonials;
