import React, { useEffect, useState } from "react";
import axios from "axios";

// Utility function to strip HTML tags
const stripHtmlTags = (html) => {
  const tmp = document.createElement("DIV");
  tmp.innerHTML = html;
  return tmp.innerText || tmp.textContent || "";
};

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [faqData, setFaqData] = useState([]);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchFAQ/faqs"
        );
        setFaqData(response.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFAQs();
  }, []);

  return (
    <section className="py-12 md:py-24 bg-[#FFF5EE] flex justify-center items-center hidden md:block lg:px-20">
      <div className="max-w-3xl mx-auto p-4 ml-16">
        <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold font-poppins mb-6 md:mb-12 lg:mb-7">
          FAQs
        </h2>
        <div className="space-y-0 lg:ml-[-140px]">
          {faqData.map((item, index) => (
            <div
              key={index}
              className="border rounded-sm shadow-sm w-full lg:w-[600px] mx-auto"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex items-center justify-between p-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
              >
                <span className="text-base font-medium text-[#FE6929] font-poppins">
                  {stripHtmlTags(item.question)}
                </span>
                <svg
                  className={`w-6 h-6 transition-transform text-[#FE6929] ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openIndex === index && (
                <div className="p-4 bg-white border-t font-poppins">
                  {Array.isArray(item.answer) ? (
                    <ul className="list-disc pl-5">
                      {item.answer.map((point, i) => (
                        <li key={i} className="mb-2">
                          {stripHtmlTags(point)} {/* Strip HTML tags */}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>{stripHtmlTags(item.answer)}</p> // Strip HTML tags
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;
