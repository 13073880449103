import { useState, useEffect } from "react";
import { FaLocationDot } from "react-icons/fa6";
import { PiRuler } from "react-icons/pi";
import { IoUmbrellaOutline } from "react-icons/io5";
import { FaCar } from "react-icons/fa";
import { PiWall } from "react-icons/pi";
import { TbCurrencyRupee } from "react-icons/tb";
import axios from "axios";

export const HouseCalciComp = () => {
  const [pincode, setPincode] = useState("");
  const [superBuiltupArea, setSuperBuiltupArea] = useState("");
  const [balconyArea, setBalconyArea] = useState("");
  const [carParkingArea, setCarParkingArea] = useState("");
  const [boundaryArea, setBoundaryArea] = useState("");

  const [result, setResult] = useState({
    superBuiltupCost: 0,
    balconyCost: 0,
    parkingCost: 0,
    boundaryCost: 0,
    totalCost: 0,
  });

  const [superBuiltupCostFactor, setSuperBuiltupCostFactor] = useState(1600);
  const [balconyCostFactor, setBalconyCostFactor] = useState(1325);
  const [parkingCostFactor, setParkingCostFactor] = useState(1340);
  const [boundaryCostFactor, setBoundaryCostFactor] = useState(1800);

  useEffect(() => {
    const fetchCostFactors = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/houseCalci/get-house-calci"
        );
        const { superBuiltupCost, balconyCost, parkingCost, boundaryCost } =
          response.data.data;
        setSuperBuiltupCostFactor(superBuiltupCost);
        setBalconyCostFactor(balconyCost);
        setParkingCostFactor(parkingCost);
        setBoundaryCostFactor(boundaryCost);
      } catch (error) {
        console.error("Error fetching cost factors", error);
      }
    };

    fetchCostFactors();
  }, []);

  const calculateCost = () => {
    const superBuiltupCost = superBuiltupArea * superBuiltupCostFactor;
    const balconyCost = balconyArea * balconyCostFactor;
    const parkingCost = carParkingArea * parkingCostFactor;
    const boundaryCost = boundaryArea * boundaryCostFactor;
    const totalCost =
      superBuiltupCost + balconyCost + parkingCost + boundaryCost;

    setResult({
      superBuiltupCost,
      balconyCost,
      parkingCost,
      boundaryCost,
      totalCost,
    });
  };

  return (
    <div className="lg:w-[800px] lg:h-[1000px] mx-auto p-6 rounded-lg shadow-md lg:mt-[100px] lg:mb-[40px] bg-[#FFF5EE]">
      <h2 className="text-2xl font-semibold mb-6 font-poppins lg:mb-[40px]">
        Cost Estimator
      </h2>

      {/* Pincode Input */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <FaLocationDot className=" text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">Pincode</label>
        </div>
        <input
          type="text"
          value={pincode}
          onChange={(e) => setPincode(e.target.value)}
          placeholder="Enter Pincode"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Super Built-up Area Input */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <PiRuler className=" text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Super Built-up Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={superBuiltupArea}
          onChange={(e) => setSuperBuiltupArea(e.target.value)}
          placeholder="Enter Super Built-up Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Balcony Area Input */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <IoUmbrellaOutline className=" text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Balcony Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={balconyArea}
          onChange={(e) => setBalconyArea(e.target.value)}
          placeholder="Enter Balcony Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Car Parking Area Input */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <FaCar className=" text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Car Parking Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={carParkingArea}
          onChange={(e) => setCarParkingArea(e.target.value)}
          placeholder="Enter Car Parking Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Boundary Wall Area Input */}
      <div className="mb-4">
        <div className="flex items-center space-x-2 lg:ml-2">
          <PiWall className=" text-[#FE6929]" />
          <label className="text-gray-700 font-poppins">
            Boundary Wall Area (sq.ft)
          </label>
        </div>
        <input
          type="number"
          value={boundaryArea}
          onChange={(e) => setBoundaryArea(e.target.value)}
          placeholder="Enter Boundary Wall Area"
          className="w-full px-3 py-2 mt-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      {/* Calculate Button */}
      <button
        onClick={calculateCost}
        className="lg:w-[150px] font-poppins mt-6 px-4 py-2 text-white font-normal lg:text-sm rounded-full bg-[#FE6929] border border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-300 ease-in-out"
      >
        Calculate Cost
      </button>

      {/* Result Section */}
      <div className="mt-8 p-4 bg-[#ffe2d6] rounded-3xl shadow-md lg:w-[350px] mx-auto">
        <h3 className="text-xl font-semibold mb-4 font-poppins">
          Estimated Cost
        </h3>
        <div className="mb-2 flex items-center space-x-2 lg:ml-2">
          <span className="font-poppins font-semibold">Per sq.ft:</span>
          <TbCurrencyRupee className=" text-[#FE6929]" />
          <span className="text-gray-700">{superBuiltupCostFactor}</span>
        </div>
        <div className="mb-2 flex items-center space-x-2 lg:ml-2">
          <span className="font-poppins font-semibold">
            Super Built-up Cost:
          </span>
          <TbCurrencyRupee className=" text-[#FE6929]" />
          <span className="text-gray-700">{result.superBuiltupCost}</span>
        </div>
        <div className="mb-2 flex items-center space-x-2 lg:ml-2">
          <span className="font-poppins font-semibold">Balcony Cost:</span>
          <TbCurrencyRupee className=" text-[#FE6929]" />
          <span className="text-gray-700">{result.balconyCost}</span>
        </div>
        <div className="mb-2 flex items-center space-x-2 lg:ml-2">
          <span className="font-poppins font-semibold">Parking Cost:</span>
          <TbCurrencyRupee className=" text-[#FE6929]" />
          <span className="text-gray-700">{result.parkingCost}</span>
        </div>
        <div className="mb-2 flex items-center space-x-2 lg:ml-2">
          <span className="font-poppins font-semibold">
            Boundary Wall Cost:
          </span>
          <TbCurrencyRupee className=" text-[#FE6929]" />
          <span className="text-gray-700">{result.boundaryCost}</span>
        </div>
        <div className="mt-4 flex items-center space-x-2 lg:ml-2">
          <span className="font-poppins font-semibold">Total Cost:</span>
          <TbCurrencyRupee className=" text-[#FE6929]" />
          <span className="text-gray-700 font-bold">{result.totalCost}</span>
        </div>
      </div>
    </div>
  );
};
