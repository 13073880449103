import React, { useState } from "react";
import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";
import { SlCompass } from "react-icons/sl";
import { LiaBedSolid } from "react-icons/lia";
import { BsBricks } from "react-icons/bs";

const ThreeDTile = ({ tilesData }) => {
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const tilesPerPage = 6;

  // Calculate the index of the first and last tile on the current page
  const indexOfLastTile = currentPage * tilesPerPage;
  const indexOfFirstTile = indexOfLastTile - tilesPerPage;
  const currentTiles = tilesData.slice(indexOfFirstTile, indexOfLastTile);

  // Calculate total pages
  const totalPages = Math.ceil(tilesData.length / tilesPerPage);

  // Handler for page change
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="container mx-auto px-4">
      {/* Grid layout for tiles */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {/* Map through currentTiles to render each tile */}
        {currentTiles.map((tile) => (
          <div
            key={tile.autoIncrementId} // Use autoIncrementId as the key
            className="bg-[#FFF5EE] h-[400px] w-[400px] p-4 rounded-2xl shadow-md mx-auto"
          >
            <div className="relative pb-[56.25%]">
              <iframe
                src={tile.threeDModelLink} // Use the 3D model link here
                className="absolute top-0 left-0 w-full h-full rounded-t-3xl border-none"
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>

            {/* Like and Share Icons */}
            <div className="flex justify-between items-center mt-3">
              <div className="flex items-center gap-2">
                <div className="bg-white text-white p-1 rounded-full">
                  <i className="fa fa-thumbs-up text-[#FE6929] text-sm"></i>
                </div>
                <p className="font-poppins text-[#FE6929] text-sm">
                  {tile.likes || 0}{" "}
                  {/* Ensure this field exists or set a default */}
                </p>
              </div>
              <div className="bg-white text-[#FE6929] p-1 rounded-full">
                <i className="fa fa-share text-sm"></i>
              </div>
            </div>

            {/* New Icon Section in Grid with 3 icons per row */}
            <div className="grid grid-cols-3 gap-6 mt-3">
              <div className="flex flex-col items-center">
                <HiOutlineBuildingOffice2 className="text-[#FE6929] text-xl" />
                <p className="font-poppins text-[#FE6929] text-xs">
                  {tile.plotDimensions}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <HiOutlineCurrencyRupee className="text-[#FE6929] text-xl" />
                <p className="font-poppins text-[#FE6929] text-xs">
                  {tile.houseBudget}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <GrSteps className="text-[#FE6929] text-xl" />
                <p className="font-poppins text-[#FE6929] text-xs">
                  {tile.numberOfFloors}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <LiaBedSolid className="text-[#FE6929] text-xl" />
                <p className="font-poppins text-[#FE6929] text-xs">
                  {tile.numberOfBedrooms}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <SlCompass className="text-[#FE6929] text-xl" />
                <p className="font-poppins text-[#FE6929] text-xs">
                  {tile.roadFacing}
                </p>
              </div>
              <div className="flex flex-col items-center">
                <BsBricks className="text-[#FE6929] text-xl" />
                <p className="font-poppins text-[#FE6929] text-xs">
                  {tile.typeOfConstruction}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`mx-1 px-3 py-1 rounded-lg ${
              currentPage === index + 1
                ? "bg-[#FE6929] text-white"
                : "bg-gray-300 text-black"
            }`}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default ThreeDTile;
