import React, { useEffect, useState } from "react";
import { saveAs } from "file-saver";
import { toast, ToastContainer } from "react-toastify"; // Toastify import
import "react-toastify/dist/ReactToastify.css"; // Toastify styles
import axios from "axios";

const ContactUsAdmin = () => {
  const [messages, setMessages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const messagesPerPage = 10; // Set messages per page

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        "https://api.bhk99.com/api/TalkToExpert"
      ); // Use correct backend URL
      setMessages(response.data); // Set the fetched messages to state
    } catch (error) {
      console.error("Error fetching messages:", error);
      toast.error("Failed to load messages.");
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  // Remove message from database and state
  const handleRemoveMessage = async (id) => {
    try {
      await axios.delete(
        `https://api.bhk99.com/api/TalkToExpert/${id}`
      ); // Use correct backend URL for deleting
      setMessages(messages.filter((message) => message._id !== id)); // Remove from UI
      toast.success("Message removed successfully!");
    } catch (error) {
      console.error("Error removing message:", error);
      toast.error("Failed to remove message.");
    }
  };

  // Pagination logic
  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(
    indexOfFirstMessage,
    indexOfLastMessage
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Export messages to CSV
  const handleExportAllToCSV = () => {
    const csvContent =
      "Name,Phone,Email,Location\n" +
      messages
        .map(
          (message) =>
            `${message.Name},${message.phone},${message.email},${message.location}`
        )
        .join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    saveAs(blob, "messages.csv");
  };

  return (
    <div className="min-h-screen bg-gray-100 p-8">
      {/* Toastify container */}
      <ToastContainer />

      {/* Messages List */}
      <div className="p-4 bg-white shadow-md rounded-lg max-w-4xl mx-auto">
        <h2 className="text-2xl font-bold mb-4">Talk To Expert Messages</h2>

        <ul className="space-y-4">
          {currentMessages.map((message) => (
            <li key={message._id} className="border-b pb-4">
              <p>
                <strong>Name:</strong> {message.Name}
              </p>
              <p>
                <strong>Phone:</strong> {message.phone}
              </p>
              <p>
                <strong>Email:</strong> {message.email}
              </p>
              <p>
                <strong>Location:</strong> {message.location}
              </p>
              <button
                onClick={() => handleRemoveMessage(message._id)} // Remove button functionality
                className="bg-orange-500 text-white px-4 py-2 rounded-lg shadow hover:bg-orange-600 transition"
              >
                Remove
              </button>
            </li>
          ))}
        </ul>

        {/* Pagination */}
        <div className="flex justify-center mt-4">
          <nav>
            <ul className="flex space-x-2">
              {Array.from(
                { length: Math.ceil(messages.length / messagesPerPage) },
                (_, index) => (
                  <li key={index} className="cursor-pointer">
                    <button
                      onClick={() => paginate(index + 1)}
                      className={`px-3 py-1 rounded ${
                        currentPage === index + 1
                          ? "bg-orange-500 text-white"
                          : "bg-gray-200"
                      }`}
                    >
                      {index + 1}
                    </button>
                  </li>
                )
              )}
            </ul>
          </nav>
        </div>

        {messages.length > 0 && (
          <div className="flex justify-end mt-4">
            <button
              onClick={handleExportAllToCSV}
              className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600"
            >
              Export to CSV
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactUsAdmin;
