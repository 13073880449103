import React, { useEffect, useState } from "react";
import axios from "axios";
import { StatsForBanner } from "../StatsForBanner";

export const Banner = () => {
  const [hiwBanner, setHiwBanner] = useState("");

  useEffect(() => {
    const fetchBanner = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/banner/getBanner"
        );
        console.log(response.data);
        if (response.data && response.data.projectsBanner) {
          setHiwBanner(
            `data:image/jpeg;base64,${response.data.howItWorksBanner2}`
          );
        } else {
          console.error("No projects banner found");
        }
      } catch (error) {
        console.error("Error fetching banner data:", error);
      }
    };

    fetchBanner();
  }, []);

  return (
    <section className="py-32 bg-[#FFF5EE] relative lg:px-20 ">
      <div className="container mx-auto px-4 mt-4">
        <h2 className="text-3xl font-semibold font-poppins mb-6 ml-0 lg:text-5xl lg:ml-[30px]">
          Hire the best house
          <br />
          construction service
        </h2>
        <StatsForBanner />
      </div>

      {hiwBanner && (
        <img
          src={hiwBanner}
          alt="Projects Banner"
          className="absolute right-0 bottom-0 lg:h-[500px] lg:w-[500px] md:w-[200px] md:h-[200px] lg:translate-y-[-100px] md:translate-y-[-220px] mr-[20px] w-[200px] top-[340px] md:w-[300px] lg:top-[100px]"
        />
      )}
    </section>
  );
};
