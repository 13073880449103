import React from "react";

const Banner3 = () => {
  return (
    <section className="py-24 bg-white">
      <div className="container mx-auto px-4 md:px-8 lg:px-16 mt-4 flex flex-col lg:flex-row items-center">
        <div className="lg:w-1/2">
          <h2 className="text-2xl md:text-3xl lg:text-4xl font-semibold font-poppins mb-6 md:mb-12 lg:mb-20">
            We construct your dream home.
            <br />
            You track progress on app.
          </h2>
          <span className="text-gray-400 text-sm md:text-base lg:text-sm font-poppins mb-4">
            Track House construction project progress, raise queries, view
            <br />
            <p className="text-gray-400 text-xs md:text-sm font-poppins mb-8 lg:mb-12">
              inspection reports and more.
            </p>
          </span>
          <ul className="space-y-3 md:space-y-4 lg:space-y-5">
            <li className="flex items-start md:items-center text-gray-700">
              <span className="w-5 h-5 md:w-6 md:h-6 flex items-center justify-center mr-3 bg-white text-[#FE6929] border border-[#FE6929] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 md:w-4 md:h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-400 text-xs md:text-sm font-poppins">
                View packages, floor plans, design recommendations
              </p>
            </li>
            <li className="flex items-start md:items-center text-gray-700">
              <span className="w-5 h-5 md:w-6 md:h-6 flex items-center justify-center mr-3 bg-white text-[#FE6929] border border-[#FE6929] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 md:w-4 md:h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-400 text-xs md:text-sm font-poppins">
                Capture house construction progress in 3D
              </p>
            </li>
            <li className="flex items-start md:items-center text-gray-700">
              <span className="w-5 h-5 md:w-6 md:h-6 flex items-center justify-center mr-3 bg-white text-[#FE6929] border border-[#FE6929] rounded-full">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-3 h-3 md:w-4 md:h-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </span>
              <p className="text-gray-400 text-xs md:text-sm font-poppins">
                Real-time tracking until project completion
              </p>
            </li>
          </ul>
        </div>
        <div className="lg:w-1/2 relative mt-6 lg:mt-0">
          <div className="absolute inset-0 bg-[#FFF5EE] rounded-lg shadow-lg opacity-75"></div>
          <div className="relative z-10 bg-[#FFF5EE] p-6 lg:p-12 rounded-lg shadow-lg">
            <img
              src="images/phone.png" // Replace with your image URL
              alt="Construction"
              className="w-full h-auto object-cover rounded-lg shadow-md"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner3;
