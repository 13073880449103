import React, { useState, useEffect } from "react";
import axios from "axios";

const UserDetails = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");

  useEffect(() => {
    const email = localStorage.getItem("userEmail");

    // Fetch user data using the email
    const fetchUserData = async () => {
      try {
        const response = await axios.get(
          `https://api.bhk99.com/api/users/users/${email}`
        );
        setUserData(response.data);
        console.log(response.data);
      } catch (err) {
        setError(err.response?.data?.message || "Failed to fetch user data.");
      }
    };

    if (email) {
      fetchUserData();
    }
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white shadow-lg rounded-lg p-6 sm:p-8 md:max-w-lg lg:max-w-xl">
        <h2 className="text-xl sm:text-2xl font-bold mb-6 text-center">
          User Details
        </h2>

        <div className="mb-4">
          <h3 className="text-gray-700 font-semibold text-sm sm:text-base">
            Name:
          </h3>
          <p className="text-gray-600 text-base sm:text-lg">
            {userData ? userData.fullName : "Loading..."}
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-gray-700 font-semibold text-sm sm:text-base">
            Phone Number:
          </h3>
          <p className="text-gray-600 text-base sm:text-lg">
            {userData ? userData.phone : "Loading..."}
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-gray-700 font-semibold text-sm sm:text-base">
            Email Address:
          </h3>
          <p className="text-gray-600 text-base sm:text-lg">
            {userData ? userData.email : "Loading..."}
          </p>
        </div>

        <div className="mb-4">
          <h3 className="text-gray-700 font-semibold text-sm sm:text-base">
            Designation:
          </h3>
          <p className="text-gray-600 text-base sm:text-lg">
            {userData ? userData.designation : "Loading..."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default UserDetails;
