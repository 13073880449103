import React, { useEffect, useState } from "react";
import axios from "axios";

const DeletePW = () => {
  const [walkthroughs, setWalkthroughs] = useState([]);
  const [selectedWalkthrough, setSelectedWalkthrough] = useState(null);

  useEffect(() => {
    // Fetch active walkthroughs from the backend
    const fetchWalkthroughs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/pw/getAllWalkthroughs"
        );
        setWalkthroughs(response.data); // Set all fetched data directly
      } catch (error) {
        console.error("Error fetching walkthrough data:", error);
      }
    };

    fetchWalkthroughs();
  }, []);

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    const selected = walkthroughs.find(
      (walkthrough) => walkthrough._id === selectedId
    );
    setSelectedWalkthrough(selected || null);
  };

  const handleDelete = async () => {
    if (!selectedWalkthrough) return; // Prevent deletion if nothing is selected

    const confirmDelete = window.confirm(
      `Are you sure you want to delete the walkthrough "${selectedWalkthrough.description}"?`
    );

    if (confirmDelete) {
      try {
        await axios.delete(
          `https://api.bhk99.com/pw/deleteWalkthrough/${selectedWalkthrough._id}`
        );
        alert("Walkthrough deleted successfully!");

        // Refresh the list after deletion
        setWalkthroughs((prev) =>
          prev.filter(
            (walkthrough) => walkthrough._id !== selectedWalkthrough._id
          )
        );
        setSelectedWalkthrough(null);
      } catch (error) {
        console.error("Error deleting walkthrough:", error);
        alert("Failed to delete walkthrough.");
      }
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Delete Walkthrough</h2>

      {/* Dropdown to select walkthrough */}
      <label htmlFor="walkthrough-select" className="block font-semibold mb-2">
        Select a Walkthrough to Delete:
      </label>
      <select
        id="walkthrough-select"
        className="w-full p-2 border border-gray-300 rounded mb-4"
        value={selectedWalkthrough ? selectedWalkthrough._id : ""} // Use _id for value
        onChange={handleSelectChange}
      >
        <option value="">Select a walkthrough</option>
        {walkthroughs.map((walkthrough) => (
          <option key={walkthrough._id} value={walkthrough._id}>
            {walkthrough.description} {/* Display description or title */}
          </option>
        ))}
      </select>

      {/* Delete button */}
      <button
        onClick={handleDelete}
        className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-200"
      >
        Delete Walkthrough
      </button>
    </div>
  );
};

export default DeletePW;
