import React, { useState } from "react";

const FAQMobile = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqData = [
    {
      question: "Does BHK99 business charge on advance payment?",
      answer:
        "Yes, BHK99 collects a booking amount about 2% of the total home construction cost. Alongside this, we perform soil tests, and create a floor plan.",
    },
    {
      question:
        "What is the estimated time it will take to build a full house?",
      answer:
        "It takes 6 to 14 months to complete a house construction project. Generally, a 5 month period to build the first floor and 1.5 months for each one after that is the standard. However, this depends on the space, built-up area, location, design, construction type (foundation or core), etc. You can track your project, including updates, inspection reports, payment schedules, etc., on the customer app.",
    },
    {
      question: "Do I have to pay the full amount before the project starts?",
      answer: `No. You will have to pay different amounts based on the personal payment schedule curated based on construction stages of your project. We charge 2% booking amount, 8% of the total amount is collected once the floor plan is finalized. Our construction company then moves forward with structural drawings, MEP drawings, 3D elevation drawings, and the initiation of contractor allocation. After the completion of the design phase, the finalized product is handed over to the project management team. This is where you have to pay 15% of the project value until the plinth is constructed. From here on, the remaining payment will be based on stages specific to the project.`,
    },
    {
      question:
        "What to consider when constructing a home by a builder or constructor?",
      answer: [
        "Conduct a background check: Talk with previous clients of home builders and confirm the credibility.",
        "Look for solution-oriented and systematic approach: Check if the construction company is efficiently designed to handle unexpected situations.",
        "Look for transparency and seamless payment processes: Always ensure you can gain insights regarding the construction process and building materials cost every step of the way.",
        "Ask for real-time updates: Choose a construction company that keeps you posted regarding the project progress from time to time.",
        "Ensure communication is smooth: Ensure a process for project approvals, inspections, and effective communication is in place.",
      ],
    },
  ];

  return (
    <section className="py-8 md:py-12 bg-[#FFF5EE] flex justify-center items-center">
      <div className="max-w-3xl mx-auto p-4">
        <h2 className="text-xl md:text-2xl lg:text-3xl font-semibold font-poppins mb-6">
          FAQs
        </h2>
        <div className="space-y-4">
          {faqData.map((item, index) => (
            <div key={index} className="border rounded-sm shadow-sm w-full">
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex items-center justify-between p-4 bg-gray-100 hover:bg-gray-200 focus:outline-none"
              >
                <span className="text-base font-medium text-[#FE6929] font-poppins">
                  {item.question}
                </span>
                <svg
                  className={`w-5 h-5 transition-transform text-[#FE6929] ${
                    openIndex === index ? "rotate-180" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              {openIndex === index && (
                <div className="p-4 bg-white border-t font-poppins">
                  {Array.isArray(item.answer) ? (
                    <ul className="list-disc pl-5">
                      {item.answer.map((point, i) => (
                        <li key={i} className="mb-2">
                          {point}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>{item.answer}</p>
                  )}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQMobile;
