import React from "react";
import { GrProjects } from "react-icons/gr";
import { IoMdHome } from "react-icons/io";
import { RiMoneyRupeeCircleLine } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { VscProject } from "react-icons/vsc";
import { Link } from "react-router-dom";

const NavbarMobile = () => {
  return (
    <nav className="bg-white text-white sticky bottom-0 left-0 right-0 z-50">
      <div className="container mx-auto px-2 py-2 flex justify-between items-center">
        {/* Navbar Links */}
        <div className="flex justify-between w-full">
          {/* Projects Link */}
          <Link
            to="/OurProjects"
            className="text-[#FE6929] flex flex-col items-center space-y-1 sm:space-y-0"
          >
            <VscProject className="text-lg sm:text-xl" />
            <p className="text-[10px] sm:text-xs whitespace-nowrap">Projects</p>
          </Link>
          {/* Let's Build Link */}
          <Link
            to="/3d-plans"
            className="text-[#FE6929] flex flex-col items-center space-y-1 sm:space-y-0"
          >
            <GrProjects className="text-lg sm:text-xl" />
            <p className="text-[10px] sm:text-xs whitespace-nowrap">
              3-D Floor Plans
            </p>
          </Link>

          {/* Home Link */}
          <Link
            to="/"
            className="text-[#FE6929] flex flex-col items-center space-y-1 sm:space-y-0"
          >
            <IoMdHome className="text-lg sm:text-xl" />
            <p className="text-[10px] sm:text-xs whitespace-nowrap">Home</p>
          </Link>

          {/* Cost Estimator Link */}
          <Link
            to="/calci"
            className="text-[#FE6929] flex flex-col items-center space-y-1 sm:space-y-0"
          >
            <RiMoneyRupeeCircleLine className="text-lg sm:text-xl" />
            <p className="text-[10px] sm:text-xs whitespace-nowrap">
              Cost Estimator
            </p>
          </Link>

          {/* More Link */}
          <a
            href="#"
            className="text-[#FE6929] flex flex-col items-center space-y-1 sm:space-y-0"
          >
            <RxHamburgerMenu className="text-lg sm:text-xl" />
            <p className="text-[10px] sm:text-xs whitespace-nowrap">More</p>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default NavbarMobile;
