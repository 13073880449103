import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom"; // Import useParams to access URL parameters
import { Navbar2 } from "../Navbar2";
import { NavbarTab } from "../NavbarTab";
import { ProjectHeroHome } from "./ProjectHeroHome";
import { InteriorHome } from "./InteriorHome";
import { ThreeDTileHome } from "./ThreeDTileHome";
import { ThreeDTileMobileHome } from "./ThreeDTileMobileHome";
import Banner3 from "./Banner3";
import { Footer } from "../Footer";
import NavbarMobile from "../NavbarMobile";
import axios from "axios";

export const MainPage2 = () => {
  const { id } = useParams(); // Get the project ID from the URL
  const [viewport, setViewport] = useState("large");
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setViewport(width < 768 ? "mobile" : width < 1024 ? "medium" : "large");
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Fetch the project data using the ID from the URL
    const fetchProject = async () => {
      try {
        const response = await axios.get(
          `https://api.bhk99.com/fetch/projects/${id}`
        );
        setProjectData(response.data);
      } catch (error) {
        console.error("Error fetching project data:", error);
      }
    };

    fetchProject();
  }, [id]);

  if (!projectData) {
    return <div>Loading...</div>; // Show a loading indicator while data is being fetched
  }

  return (
    <>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <ProjectHeroHome project={projectData} />
      <InteriorHome project={projectData} />
      {viewport === "mobile" && <ThreeDTileMobileHome project={projectData} />}
      <ThreeDTileHome project={projectData} />
      <Banner3 />
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
};

export default MainPage2;
