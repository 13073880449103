import React, { useState, useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EditNews = () => {
  const [newsList, setNewsList] = useState([]);
  const [selectedNews, setSelectedNews] = useState(null);
  const [updatedContent, setUpdatedContent] = useState("");
  const [updatedImage, setUpdatedImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchNews/news"
        );
        setNewsList(response.data);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };
    fetchNews();
  }, []);

  const handleSelectChange = (event) => {
    const newsId = event.target.value;
    const news = newsList.find((n) => n._id === newsId);
    setSelectedNews(news);
    setUpdatedContent(news ? news.content : "");
    setUpdatedImage(null);
  };

  const handleImageChange = (event) => {
    setUpdatedImage(event.target.files[0]);
  };

  const handleUpdate = async () => {
    if (!selectedNews) return;

    setLoading(true);
    const formData = new FormData();
    formData.append("content", updatedContent);
    if (updatedImage) {
      formData.append("image", updatedImage);
    }

    try {
      await axios.put(
        `https://api.bhk99.com/updateNews/news/${selectedNews._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setNewsList(
        newsList.map((n) =>
          n._id === selectedNews._id
            ? {
                ...n,
                content: updatedContent,
                imageUrl: updatedImage
                  ? URL.createObjectURL(updatedImage)
                  : n.imageUrl,
              }
            : n
        )
      );

      // Clear input fields after successful update
      setUpdatedContent("");
      setUpdatedImage(null);
      setSelectedNews(null); // Clear selected news as well

      toast.success("News updated successfully!");
    } catch (error) {
      console.error("Error updating news:", error);
      toast.error("Failed to update news");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-8 bg-white rounded shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-center">Edit News</h2>

      <div className="mb-4">
        <label className="block text-lg font-medium mb-2">Select News</label>
        <select
          className="w-full p-3 border border-gray-300 rounded"
          value={selectedNews ? selectedNews._id : ""}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            Choose news to edit
          </option>
          {newsList.map((news) => (
            <option key={news._id} value={news._id}>
              {news.content.substring(0, 30)}...
            </option>
          ))}
        </select>
      </div>

      {selectedNews && (
        <div>
          <label className="block text-lg font-medium mb-2">Edit Content</label>
          <ReactQuill
            className="mb-4"
            value={updatedContent}
            onChange={setUpdatedContent}
            theme="snow"
          />

          <label className="block text-lg font-medium mb-2">Change Image</label>
          <input
            type="file"
            className="w-full p-3 border border-gray-300 rounded mb-4"
            accept="image/*"
            onChange={handleImageChange}
          />

          <div className="text-center">
            <button
              onClick={handleUpdate}
              className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929] transition duration-200"
              disabled={loading}
            >
              {loading ? "Updating..." : "Update News"}
            </button>
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default EditNews;