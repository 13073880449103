import React from "react";

const ContactUsMobile = () => {
  return (
    <div className="my-8 p-4 bg-white rounded-3xl border border-gray-200 mx-4 sm:mx-auto sm:w-full sm:max-w-lg">
      <h2 className="text-lg sm:text-xl font-semibold mb-4 text-center font-poppins">
        Talk to our Expert
      </h2>
      <form className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="text"
            id="name"
            name="name"
            required
            placeholder="Name*"
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <label
            htmlFor="mobile"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="text"
            id="mobile"
            name="mobile"
            required
            placeholder="Mobile Number*"
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Email*"
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="text"
            id="location"
            name="location"
            required
            placeholder="Location of your Plot*"
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-[#FE6929] text-white font-semibold rounded-md shadow-sm"
          >
            Book FREE CONSULTATION
          </button>
        </div>
        <p className="text-xs sm:text-sm font-bold text-center mt-4">
          *By submitting this form, I confirm that I have read and agreed to
          accept BHK99 privacy policy
        </p>
      </form>
    </div>
  );
};

export default ContactUsMobile;
