import React, { useEffect, useState } from "react";
import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";
import { SlCompass } from "react-icons/sl";
import { LiaBedSolid } from "react-icons/lia";
import { BsBricks } from "react-icons/bs";

export const ThreeDTileMobile = ({ tilesData }) => {
  const [currentIndex, setCurrentIndex] = useState(0); // State for the current tile index
  const [currentTile, setCurrentTile] = useState(tilesData[0]); // State for the current tile data

  useEffect(() => {
    setCurrentTile(tilesData[currentIndex]); // Update the current tile whenever the index changes
  }, [currentIndex, tilesData]);

  const handleNext = () => {
    if (currentIndex < tilesData.length - 1) {
      setCurrentIndex((prevIndex) => prevIndex + 1); // Move to the next tile
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1); // Move to the previous tile
    }
  };

  return (
    <div className="flex flex-col items-center">
      <div className="grid grid-cols-1 sm:grid-cols-1 gap-4">
        <div
          key={currentTile.autoIncrementId}
          className="bg-[#FFF5EE] p-1 rounded-2xl shadow-md w-[340px] h-[460px] mb-[20px] ml-[1px]"
        >
          <div className="relative pb-[56.25%]">
            <iframe
              src={currentTile.threeDModelLink} // Assuming you use the 3D model link from the data
              className="absolute top-0 left-0 rounded-t-2xl border-none w-[320px] h-[280px] ml-[5px] mt-[5px]"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
          <div className="flex justify-between items-center mt-[115px]">
            <div className="flex items-center gap-2 mt-2">
              <div className="bg-white text-white p-1 rounded-full ml-[10px]">
                <i className="fa fa-thumbs-up text-[#FE6929] text-xs"></i>
              </div>
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.likes || 0}{" "}
                {/* Default to 0 if likes are not available */}
              </p>
            </div>
            <div className="bg-white text-[#FE6929] p-1 rounded-full mr-[10px] mt-2">
              <i className="fa fa-share text-xs"></i>
            </div>
          </div>

          <div className="grid grid-cols-3 gap-y-4 justify-center mt-[5px]">
            <div className="flex flex-col items-center">
              <HiOutlineBuildingOffice2 className="text-[#FE6929] text-sm" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.plotDimensions}{" "}
                {/* Assuming this is the correct field */}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <HiOutlineCurrencyRupee className="text-[#FE6929] text-sm" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.houseBudget} {/* Correct field */}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <GrSteps className="text-[#FE6929] text-sm" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.numberOfFloors} {/* Correct field */}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <LiaBedSolid className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.numberOfBedrooms} {/* Correct field */}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <SlCompass className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.roadFacing} {/* Correct field */}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <BsBricks className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {currentTile.typeOfConstruction} {/* Correct field */}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Pagination Controls */}
      <div className="flex justify-between w-full px-4 mt-4">
        <button
          onClick={handlePrevious}
          disabled={currentIndex === 0}
          className="bg-[#FE6929] text-white px-4 py-2 rounded disabled:bg-gray-300"
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentIndex === tilesData.length - 1}
          className="bg-[#FE6929] text-white px-4 py-2 rounded disabled:bg-gray-300"
        >
          Next
        </button>
      </div>
    </div>
  );
};
