import { useRef, useEffect } from "react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import AddServiceHome from "./Admin Panel/AddServiceHome";
import { Link } from "react-router-dom";

const OurServices = () => {
  const scrollAmount = 300;
  const containerRef = useRef(null);

  const handleScroll = (direction) => {
    if (containerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
      if (direction === "left") {
        containerRef.current.scrollTo({
          left: Math.max(scrollLeft - scrollAmount, 0),
          behavior: "smooth",
        });
      } else if (direction === "right") {
        containerRef.current.scrollTo({
          left: Math.min(scrollLeft + scrollAmount, scrollWidth - clientWidth),
          behavior: "smooth",
        });
      }
    }
  };

  // Auto-scroll effect
  useEffect(() => {
    const interval = setInterval(() => {
      if (containerRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;

        if (scrollLeft + scrollAmount < scrollWidth - clientWidth) {
          containerRef.current.scrollTo({
            left: scrollLeft + scrollAmount,
            behavior: "smooth",
          });
        } else {
          containerRef.current.scrollTo({
            left: 0,
            behavior: "smooth",
          });
        }
      }
    }, 3000);

    return () => clearInterval(interval);
  }, [scrollAmount]);

  // Function to determine if the image is base64
  const isBase64 = (str) => {
    const pattern = /^data:image\/[a-zA-Z]+;base64,/;
    return pattern.test(str);
  };

  return (
    <AddServiceHome>
      {({ services }) => (
        <section className="py-28 bg-[#FFF5EE]">
          <div className="container mx-auto px-4 relative">
            <h2 className="text-4xl font-semibold font-poppins mb-8 text-center">
              Our Services
            </h2>
            <span className="text-gray-400 text-sm font-poppins mb-24 block text-center">
              At BHK99 Realty, we believe in turning your real estate dreams
              into reality.
              <br />
              With our dedicated team of experts and personalized approach,
              we're here to guide you
              <br />
              through every step of your real estate journey with confidence and
              ease.
            </span>
            <div
              className="flex overflow-x-auto space-x-4 mt-16 scrollbar-hide"
              ref={containerRef}
            >
              {services.map((service, index) => {
                // Determine the image source based on whether it's base64 or a URL
                const imageUrl = isBase64(service.image)
                  ? service.image
                  : `data:image/jpeg;base64,${service.image}`;

                return (
                  <div
                    key={index}
                    className="flex-shrink-0 w-72 h-80 bg-white p-6 rounded-3xl shadow-lg transition-transform transform hover:scale-105 mb-6"
                  >
                    <div className="flex justify-center items-center w-36 h-36 overflow-hidden rounded-full mx-auto mb-4 border-4 border-gray-200 bg-gray-100">
                      <img
                        src={imageUrl} // Use the computed image URL
                        alt={service.title}
                        className="w-full h-full object-cover"
                      />
                    </div>
                    <div className="text-center">
                      <h3 className="text-md font-semibold mb-2 font-poppins">
                        {service.name}
                      </h3>
                      <Link to={`/services/${service._id}`}>
                        <button className="text-white bg-orange-500 hover:bg-orange-600 py-2 px-4 rounded-full">
                          See Details
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="absolute inset-x-0 flex justify-center space-x-2 md:bottom-[-80px] lg:bottom-[-100px]">
              <button
                className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
                onClick={() => handleScroll("left")}
                aria-label="Scroll left"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-sm sm:text-lg"
                />
              </button>
              <button
                className="bg-[#FE6929] text-white p-2 sm:p-4 rounded-full transition-transform duration-300 ease-in-out"
                onClick={() => handleScroll("right")}
                aria-label="Scroll right"
              >
                <FontAwesomeIcon
                  icon={faArrowRight}
                  className="text-sm sm:text-lg"
                />
              </button>
            </div>
          </div>
        </section>
      )}
    </AddServiceHome>
  );
};

export default OurServices;
