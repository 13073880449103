import React from "react";

const Cards = () => {
    return (
        <section className="py-28 bg-[#FFF5EE] min-h-screen flex items-center justify-center">
          <div className="container mx-auto px-4 lg:px-16 flex flex-col items-center">
            
            <div className="flex flex-col gap-40 items-center">
              {/* Tile 1 */}
              <div className="relative h-[200px] w-[350px] md:h-[290px] lg:h-[350px] w-[300px] md:w-[600px] lg:w-[700px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center p-4">
                <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl lg:text-4xl font-bold w-10 md:w-12 lg:w-16 h-10 md:h-12 lg:h-16 rounded-full flex items-center justify-center z-0">
                  1
                </div>
                <img
                  src="images/how1.png"
                  alt="Step 1"
                  className="w-[120px] md:w-[200px] lg:w-[250px] h-[120px] md:h-[200px] lg:h-[250px] rounded-full mx-4 ml-4 md:ml-16 z-10 relative"
                />
                <div className="text-left p-2 md:p-4">
                  <h3 className="text-xs md:text-2xl lg:text-2xl font-semibold mb-2 font-poppins text-center">
                    SITE ASSESSMENT
                  </h3>
                  <span className="text-gray-400 text-[10px] md:text-sm lg:text-sm font-poppins text-center">
                    We analyze your problem
                    <br />
                    and develop a strategy.
                  </span>
                </div>
              </div>
              {/* Tile 2 */}
              <div className="relative h-[200px] w-[350px] md:h-[290px] lg:h-[350px] w-[300px] md:w-[600px] lg:w-[700px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center p-4">
                <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl lg:text-4xl font-bold w-10 md:w-12 lg:w-16 h-10 md:h-12 lg:h-16 rounded-full flex items-center justify-center z-0">
                  2
                </div>
                <img
                  src="images/how2.png"
                  alt="Step 2"
                  className="w-[120px] md:w-[200px] lg:w-[250px] h-[120px] md:h-[200px] lg:h-[250px] rounded-full mx-4 z-10"
                />
                <div className="text-left p-2 md:p-4">
                  <h3 className="text-xs md:text-2xl lg:text-2xl font-semibold mb-2 font-poppins text-center">
                    DESIGN & BLUEPRINT
                  </h3>
                  <span className="text-gray-400 text-[10px] md:text-sm lg:text-sm font-poppins text-center">
                    We analyze your problem
                    <br />
                    and develop a strategy.
                  </span>
                </div>
              </div>
              {/* Tile 3 */}
              <div className="relative h-[200px] w-[350px] md:h-[290px]  lg:h-[350px] w-[300px] md:w-[600px] lg:w-[700px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center p-4">
                <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl lg:text-4xl font-bold w-10 md:w-12 lg:w-16 h-10 md:h-12 lg:h-16 rounded-full flex items-center justify-center z-0">
                  3
                </div>
                <img
                  src="images/how3.png"
                  alt="Step 3"
                  className="w-[120px] md:w-[200px] lg:w-[250px] h-[120px] md:h-[200px] lg:h-[250px] rounded-full mx-4 z-10"
                />
                <div className="text-left p-2 md:p-4">
                  <h3 className="text-xs md:text-2xl lg:text-2xl font-semibold mb-2 font-poppins text-left">
                    CONSTRUCTION PHASE
                  </h3>
                  <span className="text-gray-400 text-[10px] md:text-sm lg:text-sm font-poppins text-center">
                    We analyze your problem
                    <br />
                    and develop a strategy.
                  </span>
                </div>
              </div>
              {/* Tile 4 */}
              <div className="relative h-[200px] w-[350px] md:h-[290px] lg:h-[350px] w-[300px] md:w-[600px] lg:w-[700px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center p-4">
                <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl lg:text-4xl font-bold w-10 md:w-12 lg:w-16 h-10 md:h-12 lg:h-16 rounded-full flex items-center justify-center z-0">
                  4
                </div>
                <img
                  src="images/how4.png"
                  alt="Step 4"
                  className="w-[120px] md:w-[200px] lg:w-[250px] h-[120px] md:h-[200px] lg:h-[250px] rounded-full mx-4 z-10"
                />
                <div className="text-left p-2 md:p-4">
                  <h3 className="text-xs md:text-2xl lg:text-2xl font-semibold mb-2 font-poppins text-left">
                    QUALITY CHECK AND
                    <br />
                    HANDOVER
                  </h3>
                  <span className="text-gray-400 text-[10px] md:text-sm lg:text-sm font-poppins text-center">
                    We analyze your problem
                    <br />
                    and develop a strategy.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
};

export default Cards;
