// components/admin/AddTermsPolicy.jsx
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const AddTermsPolicy = () => {
  const [name, setName] = useState('');
  const [link, setLink] = useState('');

  const handleAddTermsPolicy = async () => {
    try {
        await axios.post('https://api.bhk99.com/api/terms-policy', { name,  link });
      toast.success('Terms and Policy added successfully');
      setName('');
      setLink('');
    } catch (error) {
      toast.error('Error adding Terms and Policy');
    }
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-md max-w-md mx-auto mt-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Add Terms and Policy</h2>
      <input
        type="text"
        placeholder="Enter Link Name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
      />
      <input
        type="text"
        placeholder="Enter Terms and Policy link"
        value={link}
        onChange={(e) => setLink(e.target.value)}
        className="w-full mb-4 p-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
      />
      <button
        onClick={handleAddTermsPolicy}
        className="w-full bg-orange-500 hover:bg-orange-600 text-white font-semibold py-2 rounded"
      >
        Add
      </button>
    </div>
  );
};
