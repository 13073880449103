import React, { useEffect, useState } from 'react';
import axios from 'axios';

const AddBankingHome = () => {
  const [bankingPartners, setBankingPartners] = useState([]);

  useEffect(() => {
    const fetchBankingPartners = async () => {
      try {
        const response = await axios.get('https://api.bhk99.com/api/bankingpartners');

        if (response.status === 200) {
          console.log('Banking partners fetched:', response.data); // Log response data
          setBankingPartners(response.data);
        } else {
          console.error('Failed to fetch banking partners');
        }
      } catch (error) {
        console.error('Error fetching banking partners:', error);
      }
    };

    fetchBankingPartners();
  }, []);

  return (
    <>
      {bankingPartners.map((partner) => (
        <img
          key={partner._id}
          src={partner.logoUrl} // Directly use the Base64 encoded image
          alt="Banking Partner Logo"
          className="w-64 md:w-72 h-28 md:h-36 rounded-md mx-auto bg-white"
          onError={(e) => {
            e.target.onerror = null; // Prevents looping
            e.target.src = ''; // Remove image if there's an error
            console.log('Image not found or invalid Base64:', partner.logoUrl); // Log the error for debugging
          }}
        />
      ))}
    </>
  );
};

export default AddBankingHome;
