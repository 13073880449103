import React, { useState } from "react";
import axios from "axios"; // Import axios for API calls
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AddTestimonials = () => {
  const [customerName, setCustomerName] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [details, setDetails] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send the form data to the backend
      const response = await axios.post(
        "https://api.bhk99.com/addTestimonials/testimonials",
        {
          customerName,
          videoLink,
          details,
        }
      );

      // Show success toast notification
      toast.success(response.data.message);

      // Clear the form fields after submission
      setCustomerName("");
      setVideoLink("");
      setDetails("");
    } catch (error) {
      // Handle any errors from the API
      if (error.response) {
        toast.error(error.response.data.message || "Error adding testimonial");
      } else {
        toast.error("Error adding testimonial");
      }
    }
  };

  return (
    <div className="max-w-lg mx-auto p-6 bg-white border border-gray-300 rounded-lg shadow-lg mt-10">
      <h2 className="text-2xl font-bold mb-4 text-gray-800">
        Add Customer Testimonial
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="customerName"
          >
            Customer's Name
          </label>
          <input
            type="text"
            id="customerName"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-600 transition"
            placeholder="Enter customer's name"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="videoLink"
          >
            YouTube Video Link
          </label>
          <input
            type="url"
            id="videoLink"
            value={videoLink}
            onChange={(e) => setVideoLink(e.target.value)}
            className="mt-1 block w-full border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-blue-600 transition"
            placeholder="Enter YouTube video link"
            required
          />
        </div>
        <div className="mb-4">
          <label
            className="block text-sm font-medium text-gray-700"
            htmlFor="details"
          >
            Testimonial Details
          </label>
          <ReactQuill
            id="details"
            value={details}
            onChange={setDetails}
            className="mt-1 border border-gray-300 rounded-md"
            placeholder="Enter testimonial details"
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline"],
                ["link", "image"],
                ["clean"], // remove formatting button
              ],
            }}
            formats={["header", "bold", "italic", "underline", "link", "image"]}
            required
          />
        </div>
        <button
          type="submit"
          className="w-full p-2 bg-[#FE6929] text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-[#FE6929] hover:border-[#FE6929]"
        >
          Add Testimonials
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddTestimonials;
