import React from "react";

export const HowItWorksMobile = () => {
  return (
    <section className="py-28 bg-[#FFF5EE] mt-12">
      <div className="container mx-auto px-4 lg:ml-16 sm:ml-24">
        <h2 className="text-4xl font-semibold font-poppins mb-4">
          How It Works
        </h2>
        <span className="text-gray-400 text-sm font-poppins mb-24">
          Our House construction steps are simple and easy to understand: Plan -
          Build - Track - Settle in
        </span>
        <div className="relative">
          <div className="overflow-x-auto whitespace-nowrap flex flex-col gap-8 md:flex-row md:gap-16 scrollbar-hide mt-12">
            {/* Tile 1 */}
            <div className="relative h-[240px] md:h-[290px] w-full md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-4 md:mb-0">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                1
              </div>
              <img
                src="images/how1.png"
                alt="Step 1"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 ml-4 md:ml-16 z-10 relative"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  SITE ASSESSMENT
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyse your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
            {/* Tile 2 */}
            <div className="relative h-[240px] md:h-[290px] w-full md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-4 md:mb-0">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                2
              </div>
              <img
                src="images/how2.png"
                alt="Step 2"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 z-10"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  DESIGN & BLUEPRINT
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyse your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
            {/* Tile 3 */}
            <div className="relative h-[240px] md:h-[290px] w-full md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-4 md:mb-0">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                3
              </div>
              <img
                src="images/how3.png"
                alt="Step 3"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 z-10"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  CONSTRUCTION PHASE
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyse your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
            {/* Tile 4 */}
            <div className="relative h-[240px] md:h-[290px] w-full md:w-[570px] bg-white shadow-lg rounded-3xl flex-shrink-0 flex items-center mb-4 md:mb-0">
              <div className="absolute top-4 left-4 bg-[#FA8072] text-white text-xl md:text-3xl font-bold w-10 h-10 md:w-12 md:h-12 rounded-full flex items-center justify-center z-0">
                4
              </div>
              <img
                src="images/how4.png"
                alt="Step 4"
                className="w-[120px] md:w-[200px] h-[120px] md:h-[200px] rounded-full mx-4 z-10"
              />
              <div className="text-left p-2 md:p-4">
                <h3 className="text-xs md:text-2xl sm:text-sm font-semibold mb-2 font-poppins">
                  QUALITY CHECK &
                  <br />
                  HANDOVER
                </h3>
                <span className="text-gray-400 text-[10px] lg:text-sm sm:text-[10px] md:text-sm font-poppins">
                  We analyse your problem
                  <br />
                  and develop a strategy.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
