import React, { useState } from "react";

const FilterDivTab = ({ onFiltersChange }) => {
  // State to keep track of selected filters (as an object per category)
  const [selectedFilters, setSelectedFilters] = useState({});

  // State to keep track of open dropdowns
  const [openDropdowns, setOpenDropdowns] = useState({
    plotDimensions: false,
    houseBudget: false,
    roadFacing: false,
    numberOfFloors: false,
    typeOfConstruction: false,
    numberOfBedrooms: false,
    vastuCompliant: false,
    specialRequirements: false,
  });

  // Filter categories and options
  const filterOptions = {
    plotDimensions: [
      "20x20 sqft",
      "20x30 sqft",
      "20x40 sqft",
      "20x50 sqft",
      "30x20 sqft",
      "30x30 sqft",
      "30x40 sqft",
      "30x50 sqft",
      "40x20 sqft",
      "40x30 sqft",
      "40x40 sqft",
      "40x50 sqft",
    ],
    houseBudget: ["10 Lacs to 50 Lacs", "50 Lacs to 1 Cr", "1 Cr +"],
    roadFacing: ["East", "West", "North", "South"],
    numberOfFloors: ["G", "G+1", "G+2", "G+3"],
    typeOfConstruction: ["Brick", "Concrete", "Wood", "Steel"],
    numberOfBedrooms: ["1", "2", "3", "4+"],
    vastuCompliant: ["Yes", "No"],
    specialRequirements: ["Swimming Pool", "Garden", "Gym", "Basement"],
  };

  // Handle checkbox selection
  const handleCheckboxChange = (filterCategory, filterValue) => {
    setSelectedFilters((prevFilters) => {
      const currentFilters = prevFilters[filterCategory] || [];
      const newFilters = currentFilters.includes(filterValue)
        ? currentFilters.filter((f) => f !== filterValue)
        : [...currentFilters, filterValue];

      const updatedFilters = {
        ...prevFilters,
        [filterCategory]: newFilters,
      };

      onFiltersChange(updatedFilters);
      return updatedFilters;
    });
  };

  // Clear all filters
  const clearFilters = () => {
    setSelectedFilters({});
    onFiltersChange({});
  };

  // Toggle dropdown visibility
  const toggleDropdown = (category) => {
    setOpenDropdowns((prevState) => ({
      ...prevState,
      [category]: !prevState[category],
    }));
  };

  return (
    <div className="bg-[#FFF5EE] w-[300px] h-[700px] left-0 p-4 mt-3 ml-[-16px] overflow-y-auto rounded-r-2xl">
      {/* Filters Title */}
      <div className="justify-between items-center mb-4">
        <h2 className="text-lg font-bold font-poppins mb-2 mt-4">
          Filters ({Object.values(selectedFilters).flat().length})
        </h2>
        <button
          className="text-base font-bold font-poppins"
          onClick={clearFilters}
        >
          Clear All
        </button>
      </div>

      <hr className="border-black mb-4" />

      {/* Render filter dropdowns */}
      {Object.keys(filterOptions).map((category) => (
        <div key={category} className="mb-4">
          <details
            className="mb-4"
            open={openDropdowns[category]}
            onToggle={() => toggleDropdown(category)}
          >
            <summary
              className={`cursor-pointer font-semibold font-poppins ${
                openDropdowns[category] ? "text-[#FE6929]" : "text-black"
              }`}
            >
              {category.replace(/([A-Z])/g, " $1")}{" "}
              {/* Convert camelCase to spaced words */}
            </summary>
            <div className="ml-4 mt-2 space-y-2 bg-[#ffe2d6]">
              {filterOptions[category].map((option) => (
                <label key={option} className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox mr-2 ml-2"
                    onChange={() => handleCheckboxChange(category, option)}
                    checked={
                      selectedFilters[category]?.includes(option) || false
                    }
                  />
                  {option}
                </label>
              ))}
            </div>
          </details>
          <hr className="border-black mb-4" />
        </div>
      ))}
    </div>
  );
};

export default FilterDivTab;
