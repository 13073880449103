import axios from "axios";
import React, { useState } from "react";

const HiwHero = () => {
  const [hiwImage, setHiwImage] = useState(null);
  const [hiwHeroFile, setHiwHeroFile] = useState(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setHiwImage(URL.createObjectURL(file)); // Preview image
      setHiwHeroFile(file); // Store file for upload
    }
  };

  const handleAddHeroImage = async () => {
    const formData = new FormData();
    formData.append("howItWorkshero", hiwHeroFile);

    try {
      const response = await axios.post(
        "https://api.bhk99.com/hero/addHero",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        alert("Home hero image added successfully!");
      }
    } catch (error) {
      console.error("Error uploading home hero image:", error);
      alert("Failed to add home hero image.");
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-4">
        Upload How it works Hero Image
      </h1>

      <div className="mb-6">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="mb-4"
        />
        {hiwImage && (
          <div className="mb-4">
            <img
              src={hiwImage}
              alt="Selected Hero Image"
              className="max-w-full h-auto rounded-lg"
            />
          </div>
        )}
      </div>

      <button
        onClick={handleAddHeroImage}
        className="px-4 py-2 bg-orange-500 text-white rounded-md hover:bg-orange-600 transition duration-300"
      >
        Add How it works Hero Image
      </button>
    </div>
  );
};

export default HiwHero;
