import { useState, useEffect } from "react";
import axios from "axios";
import { StatsForBanner } from "./StatsForBanner";

export const Banner = () => {
  const [homeBannerImage, setHomeBannerImage] = useState(null);

  useEffect(() => {
    const fetchBannerImage = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/banner/getBanner"
        );
        if (response.status === 200) {
          // Assuming `homeBanner` is a Base64 string in the response
          setHomeBannerImage(
            `data:image/jpeg;base64,${response.data.homeBanner}`
          );
        }
      } catch (error) {
        console.error("Error fetching banner image:", error);
      }
    };

    fetchBannerImage();
  }, []);

  return (
    <section className="py-32 bg-[#FFF5EE] relative hidden lg:block">
      <div className="container mx-auto px-4 mt-4 lg:px-20">
        <h2 className="text-5xl font-semibold font-poppins mb-6 ml-16">
          Hire the best house
          <br />
          construction service
        </h2>
        <StatsForBanner />
      </div>

      {homeBannerImage && (
        <img
          src={homeBannerImage}
          alt="Home Banner"
          className="absolute right-0 bottom-0 lg:h-[400px] lg:w-[400px] md:w-[200px] md:h-[200px] lg:translate-y-[-100px] md:translate-y-[-220px] mr-[70px]"
        />
      )}
    </section>
  );
};
