import ContactMobile from "../ContactMobile";
import FAQ from "../FAQ";
import { Footer } from "../Footer";
import { Navbar2 } from "../Navbar2";
import NavbarMobile from "../NavbarMobile";
import { NavbarTab } from "../NavbarTab";
import ContactUs from "./ContactUs";
import { EMICalciComp } from "./EMICalciComp";
import { EMICalciCompMobile } from "./EMICalciCompMobile";
import { EMICalciCompTab } from "./EMICalciCompTab";
import FAQMobile from "./FAQMobile";
import { HeroEMI } from "./HeroEMI";
import React, { useState, useEffect } from "react";

export const EmiCalci = () => {
  const [viewport, setViewport] = useState("large");

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setViewport("mobile");
      } else if (width >= 768 && width < 1024) {
        setViewport("medium");
      } else {
        setViewport("large");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      {viewport === "large" && <Navbar2 />}
      {viewport === "medium" && <NavbarTab />}
      <HeroEMI />
      {viewport === "large" && <EMICalciComp />}
      {viewport === "medium" && <EMICalciCompTab />}
      {viewport === "mobile" && <EMICalciCompMobile />}
      <ContactUs />
      {viewport === "mobile" && <ContactMobile />}
      <FAQ />
      {viewport === "mobile" && <FAQMobile />}
      <Footer />
      {viewport === "mobile" && <NavbarMobile />}
    </>
  );
};

export default EmiCalci;
