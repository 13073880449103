import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const AddSocialMedia = () => {
  const [links, setLinks] = useState({
    platform: '',
    url: '',
  });

  const handleChange = (e) => {
    setLinks({ ...links, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://api.bhk99.com/api/social-media-links', links);
      toast.success('Social media link added successfully');
      setLinks({ platform: '', url: '' });
    } catch (error) {
      toast.error('Error adding social media link');
    }
  };

  return (
    <div className="p-6 bg-gray-100 rounded-lg shadow-md max-w-lg mx-auto">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4 text-center">Add Social Media Links</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">Platform</label>
          <input
            type="text"
            name="platform"
            value={links.platform}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter platform (e.g., Facebook)"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-bold mb-2">URL</label>
          <input
            type="url"
            name="url"
            value={links.url}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Enter URL"
            required
          />
        </div>
        <button type="submit" className="bg-orange-500 text-white px-4 py-2 rounded hover:bg-orange-600">
          Add Link
        </button>
      </form>
    </div>
  );
};
