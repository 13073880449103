import React, { useEffect, useState } from "react";
import axios from "axios"; // Import Axios
import { ToastContainer, toast } from "react-toastify"; // Import Toastify
import "react-toastify/dist/ReactToastify.css"; // Import Toastify CSS

const DeleteFAQForm = () => {
  const [faqs, setFaqs] = useState([]);
  const [selectedFAQ, setSelectedFAQ] = useState("");

  // Fetch FAQs from the server
  useEffect(() => {
    const fetchFAQs = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/fetchFAQ/faqs"
        ); // Update with your endpoint
        setFaqs(response.data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
        toast.error("Failed to fetch FAQs!");
      }
    };

    fetchFAQs();
  }, []);

  const handleDelete = async (e) => {
    e.preventDefault();

    if (!selectedFAQ) {
      alert("Please select an FAQ to delete.");
      return;
    }

    try {
      await axios.delete(
        `https://api.bhk99.com/deleteFAQ/faqs/${selectedFAQ}`
      );
      console.log("FAQ deleted successfully!");
      toast.success("FAQ deleted successfully!");

      // Refresh the FAQ list after deletion
      setFaqs(faqs.filter((faq) => faq._id !== selectedFAQ));

      // Clear the selection after deletion
      setSelectedFAQ("");
    } catch (error) {
      console.error("Error deleting FAQ:", error);
      toast.error("Failed to delete FAQ!");
    }
  };

  return (
    <div className="max-w-xl mx-auto p-6 bg-white rounded-lg shadow-md mt-8">
      <ToastContainer />

      <h2 className="text-2xl font-bold mb-4">Delete FAQ</h2>
      <form onSubmit={handleDelete}>
        {/* Dropdown for selecting FAQ */}
        <div className="mb-4">
          <label className="block text-lg font-medium mb-2">Select FAQ</label>
          <select
            value={selectedFAQ}
            onChange={(e) => setSelectedFAQ(e.target.value)}
            className="border border-gray-300 rounded-lg p-2 w-full"
          >
            <option value="">Select an FAQ</option>
            {faqs.map((faq) => (
              <option key={faq._id} value={faq._id}>
                {faq.question.replace(/<[^>]+>/g, "")}
              </option>
            ))}
          </select>
        </div>

        {/* Delete Button */}
        <button
          type="submit"
          className="w-full bg-red-500 text-white py-3 rounded-lg hover:bg-red-600 transition duration-200"
        >
          Delete FAQ
        </button>
      </form>
    </div>
  );
};

export default DeleteFAQForm;
