import React, { useState } from "react";
import {
  HiOutlineBuildingOffice2,
  HiOutlineCurrencyRupee,
} from "react-icons/hi2";
import { GrSteps } from "react-icons/gr";
import { SlCompass } from "react-icons/sl";
import { LiaBedSolid } from "react-icons/lia";
import { BsBricks } from "react-icons/bs";

const ThreeDTileTab = ({ tilesData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 2;

  const indexOfLastTile = currentPage * itemsPerPage;
  const indexOfFirstTile = indexOfLastTile - itemsPerPage;
  const currentTiles = tilesData.slice(indexOfFirstTile, indexOfLastTile);

  const totalPages = Math.ceil(tilesData.length / itemsPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="container mx-auto px-4">
      {/* Map through currentTiles to render each tile */}
      {currentTiles.map((tile) => (
        <div
          key={tile.autoIncrementId}
          className="bg-[#FFF5EE] h-[400px] w-[400px] p-4 rounded-2xl shadow-md mx-auto mb-6"
        >
          <div className="relative pb-[56.25%]">
            <iframe
              src={tile.threeDModelLink}
              className="absolute top-0 left-0 w-full h-full rounded-t-3xl border-none"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>

          {/* Like and Share Icons */}
          <div className="flex justify-between items-center mt-3">
            <div className="flex items-center gap-2">
              <div className="bg-white text-white p-1 rounded-full">
                <i className="fa fa-thumbs-up text-[#FE6929] text-sm"></i>
              </div>
              <p className="font-poppins text-[#FE6929] text-sm">
                {tile.likes || 0}
              </p>
            </div>
            <div className="bg-white text-[#FE6929] p-1 rounded-full">
              <i className="fa fa-share text-sm"></i>
            </div>
          </div>

          {/* New Icon Section in Grid with 3 icons per row */}
          <div className="grid grid-cols-3 gap-6 mt-3">
            <div className="flex flex-col items-center">
              <HiOutlineBuildingOffice2 className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {tile.plotDimensions}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <HiOutlineCurrencyRupee className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {tile.houseBudget}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <GrSteps className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {tile.numberOfFloors}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <LiaBedSolid className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {tile.numberOfBedrooms}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <SlCompass className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {tile.roadFacing}
              </p>
            </div>
            <div className="flex flex-col items-center">
              <BsBricks className="text-[#FE6929] text-xl" />
              <p className="font-poppins text-[#FE6929] text-xs">
                {tile.typeOfConstruction}
              </p>
            </div>
          </div>
        </div>
      ))}

      {/* Pagination Controls */}
      <div className="flex justify-between mt-4">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className="px-4 py-2 bg-[#FE6929] text-white rounded-md disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className="px-4 py-2 bg-[#FE6929] text-white rounded-md disabled:opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default ThreeDTileTab;
