import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddBankingPartner = () => {
  const [bankName, setBankName] = useState('');
  const [logo, setLogo] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('logo', logo);
    formData.append('bankName', bankName);

    try {
      const response = await fetch('https://api.bhk99.com/api/bankingpartners', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) throw new Error('Error adding banking partner');
      toast.success('Banking Partner added successfully!');
      setBankName('');
      setLogo(null);
    } catch (error) {
      toast.error('Error adding banking partner');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg w-80">
        <h2 className="text-2xl font-bold mb-4">Add Banking Partner</h2>
        <input
          type="text"
          placeholder="Bank Name"
          value={bankName}
          onChange={(e) => setBankName(e.target.value)}
          className="border p-2 rounded w-full mb-4"
          required
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setLogo(e.target.files[0])}
          className="border p-2 rounded w-full mb-4"
          required
        />
        <button type="submit" className="bg-orange-500 text-white hover:bg-orange-600 font-bold py-2 px-4 rounded w-full">
          Add Partner
        </button>
      </form>
      <ToastContainer />
    </div>
  );
};

export default AddBankingPartner;
  