import React, { useEffect, useState } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ActiveUsers = () => {
  const [users, setUsers] = useState([]);
  const [editingUser, setEditingUser] = useState(null);
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    designation: "",
    permissions: [],
  });
  const [error, setError] = useState("");

  const permissionsOptions = [
    "Service",
    "Our Project",
    "Cost Estimator",
    "Packages",
    "Inbox",
    "Users",
    "FAQ",
    "News",
    "Extras",
    "Banking partners",
  ];

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/api/users/get-users"
        );
        setUsers(response.data.users);
      } catch (error) {
        console.error("Error fetching users:", error);
        setError("Error fetching users");
      }
    };

    fetchUsers();
  }, []);

  const handleEditClick = (user) => {
    const confirmEdit = window.confirm(
      "Are you sure you want to edit this user?"
    );
    if (confirmEdit) {
      setEditingUser(user._id);
      setFormData({
        fullName: user.fullName,
        email: user.email,
        phone: user.phone,
        designation: user.designation,
        permissions: user.permissions,
      });
    }
  };

  const handleDeleteClick = async (userId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this user?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(
          `https://api.bhk99.com/api/users/${userId}`
        );
        setUsers((prevUsers) =>
          prevUsers.filter((user) => user._id !== userId)
        );
        toast.success("User deleted successfully");
      } catch (error) {
        console.error("Error deleting user:", error);
        toast.error("Failed to delete user");
      }
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "permissions") {
      const updatedPermissions = formData.permissions.includes(value)
        ? formData.permissions.filter((perm) => perm !== value)
        : [...formData.permissions, value];
      setFormData({ ...formData, permissions: updatedPermissions });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await axios.put(
        `https://api.bhk99.com/api/users/${editingUser}`,
        formData
      );
      setUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === editingUser ? response.data.user : user
        )
      );
      setEditingUser(null);
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        designation: "",
        permissions: [],
      });
      toast.success("User updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
      toast.error("Failed to update user");
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-2xl font-semibold mb-4">Active Users</h2>
      {error && <p className="text-red-500">{error}</p>}

      <table className="min-w-full bg-white border border-gray-300 shadow-md">
        <thead>
          <tr className="bg-gray-100 text-left">
            <th className="py-2 px-4 border-b">Name</th>
            <th className="py-2 px-4 border-b">Email</th>
            <th className="py-2 px-4 border-b">Phone</th>
            <th className="py-2 px-4 border-b">Designation</th>
            <th className="py-2 px-4 border-b">Permissions</th>
            <th className="py-2 px-4 border-b">Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user._id} className="hover:bg-gray-50">
              <td className="py-2 px-4 border-b">{user.fullName}</td>
              <td className="py-2 px-4 border-b">{user.email}</td>
              <td className="py-2 px-4 border-b">{user.phone}</td>
              <td className="py-2 px-4 border-b">{user.designation}</td>
              <td className="py-2 px-4 border-b">
                {Array.isArray(user.permissions)
                  ? user.permissions.join(", ")
                  : "No permissions"}
              </td>
              <td className="py-2 px-4 border-b flex space-x-2">
                <button
                  onClick={() => handleEditClick(user)}
                  className="bg-orange-500 hover:bg-orange-600 text-white py-1 px-3 rounded"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteClick(user._id)}
                  className="bg-red-500 hover:bg-red-600 text-white py-1 px-3 rounded"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingUser && (
        <div className="mt-6 p-4 border rounded shadow-md bg-white max-w-md mx-auto">
          <h3 className="text-xl font-semibold mb-4">Edit User</h3>
          <form className="grid gap-4">
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              placeholder="Full Name"
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              placeholder="Email"
            />
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              placeholder="Phone"
            />
            <input
              type="text"
              name="designation"
              value={formData.designation}
              onChange={handleInputChange}
              className="w-full px-3 py-2 border rounded"
              placeholder="Designation"
            />

            <h3 className="font-bold mb-2">Permissions</h3>
            {permissionsOptions.map((perm) => (
              <label key={perm} className="flex items-center mb-2">
                <input
                  type="checkbox"
                  name="permissions"
                  value={perm}
                  checked={formData.permissions.includes(perm)}
                  onChange={handleInputChange}
                  className="mr-2"
                />
                {perm}
              </label>
            ))}

            <button
              type="button"
              onClick={handleSaveChanges}
              className="bg-green-500 hover:bg-green-600 text-white py-2 px-4 rounded"
            >
              Save Changes
            </button>
          </form>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default ActiveUsers;
