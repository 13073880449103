import React, { useState } from "react";
import axios from "axios"; // Import axios for making API requests
import { toast } from "react-toastify"; // Import Toastify for notifications

const ContactUs = () => {
  const [formData, setFormData] = useState({
    Name: "",
    phone: "",
    email: "",
    location: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.bhk99.com/api/TalkToExpert",
        formData
      ); // Updated URL to include server port
      console.log("Message sent:", response.data);
      toast.success("Your message has been sent successfully!");
      setFormData({
        Name: "",
        phone: "",
        email: "",
        location: "",
      });
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message. Please try again.");
    }
  };

  return (
    <div
      onSubmit={handleSubmit}
      className=" my-8 p-6 bg-white  rounded-3xl border border-gray-200 lg:w-[500px] lg:h-[400px] md:w-[370px]  mx-auto hidden md:block"
    >
      <h2 className="text-xl font-semibold mb-4 text-center font-poppins">
        Talk to our Expert
      </h2>
      <form className="space-y-4">
        <div>
          <label
            htmlFor="name"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="text"
            id="name"
            name="Name"
            required
            placeholder="Name*"
            value={formData.Name}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <label
            htmlFor="mobile"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="text"
            id="mobile"
            name="phone"
            required
            placeholder="Mobile Number*"
            value={formData.phone}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="email"
            id="email"
            name="email"
            required
            placeholder="Email*"
            value={formData.email}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <label
            htmlFor="location"
            className="block text-sm font-medium text-gray-700"
          ></label>
          <input
            type="text"
            id="location"
            name="location"
            required
            placeholder="Location of your Plot*"
            value={formData.location}
            onChange={handleChange}
            className="mt-1 block w-full px-3 py-2 border-b-2 border-gray-300 font-poppins font-medium placeholder-gray-500 focus:outline-none sm:text-sm mb-4"
          />
        </div>

        <div>
          <button
            type="submit"
            className="w-full py-2 px-4 bg-[#FE6929] text-white font-semibold rounded-md shadow-sm"
          >
            Book FREE CONSULTATION
          </button>
        </div>
        <p className="text-xs font-bold text-center mt-4">
          *By submitting this form, I confirm that I have read and agreed to
          accept BHK99 privacy policy
        </p>
      </form>
    </div>
  );
};

export default ContactUs;
