import React, { useEffect, useState } from "react";
import axios from "axios";

const AddServiceHome = ({ children }) => {
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchServices = async (page) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://api.bhk99.com/api/services?page=${page}`
      );
      setServices(response.data.services);
      setTotalPages(response.data.totalPages);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching services:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices(page);
  }, [page]);

  return (
    <div>
      {loading ? <p>Loading...</p> : children({ services })}

      {/* Pagination Controls */}
      <div className="pagination-controls">
        {page > 1 && (
          <button onClick={() => setPage(page - 1)}>Previous</button>
        )}
        {page < totalPages && (
          <button onClick={() => setPage(page + 1)}>Next</button>
        )}
      </div>
    </div>
  );
};

export default AddServiceHome;
