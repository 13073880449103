import React, { useState, useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ServiceManage = () => {
  const [services, setServices] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [serviceToEdit, setServiceToEdit] = useState(null);
  const [serviceName, setServiceName] = useState("");
  const [serviceInfo, setServiceInfo] = useState("");
  const [serviceImage, setServiceImage] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://api.bhk99.com/api/services"
      );
      if (response.data && Array.isArray(response.data.services)) {
        setServices(response.data.services);
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      toast.error("Failed to load services.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (serviceId) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this service?"
    );
    if (confirmDelete) {
      try {
        await axios.delete(
          `https://api.bhk99.com/api/services/${serviceId}`
        );
        setServices(services.filter((service) => service._id !== serviceId));
        toast.success("Service deleted successfully!");
      } catch (error) {
        console.error("Error deleting service:", error);
        toast.error("Failed to delete service.");
      }
    }
  };

  const handleEdit = (service) => {
    setServiceToEdit(service._id);
    setServiceName(service.name);
    setServiceInfo(service.info);
    setPreviewImage(`data:image/jpeg;base64,${service.image}`);
    setServiceImage(null);
    setIsEditing(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    // Only append changed fields
    if (serviceName !== serviceToEdit.name) {
      formData.append("name", serviceName);
    }
    if (serviceInfo !== serviceToEdit.info) {
      formData.append("info", serviceInfo);
    }
    if (serviceImage) {
      formData.append("image", serviceImage);
    }

    try {
      const response = await axios.put(
        `https://api.bhk99.com/api/services/${serviceToEdit}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const updatedService = response.data;

      // Update the local state to reflect the changes immediately
      setServices((prevServices) =>
        prevServices.map((service) =>
          service._id === serviceToEdit ? updatedService : service
        )
      );
      setIsEditing(false);
      setServiceToEdit(null);
      setPreviewImage(null);
      toast.success("Service updated successfully!");
    } catch (error) {
      console.error("Error updating service:", error);
      toast.error("Failed to update service.");
    }
  };

  return (
    <div className="p-3">
      <ToastContainer />
      <div className="bg-white shadow-md p-6 rounded-lg w-full mx-auto">
        <h2 className="text-2xl font-bold mb-4">Manage Active Services</h2>

        {loading ? (
          <p>Loading services...</p>
        ) : (
          <ul className="mb-4">
            {Array.isArray(services) && services.length > 0 ? (
              services.map((service) =>
                service && service._id ? (
                  <li
                    key={service._id}
                    className="flex justify-between items-center mb-4"
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: service.name }}
                      className="font-semibold"
                    ></div>
                    <div>
                      <button
                        onClick={() => handleEdit(service)}
                        className="bg-orange-500 text-white py-1 px-3 rounded-lg hover:bg-orange-600 mr-2"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDelete(service._id)}
                        className="bg-orange-500 text-white py-1 px-3 rounded-lg hover:bg-orange-600"
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ) : null
              )
            ) : (
              <p>No services available.</p>
            )}
          </ul>
        )}

        {isEditing && (
          <form
            onSubmit={handleEditSubmit}
            className="bg-white shadow-md p-6 rounded-lg w-full"
          >
            <h3 className="text-xl font-bold mb-4">Edit Service</h3>

            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">
                Service Name
              </label>
              <input
                type="text"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
                required
              />
            </div>

            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">
                Service Image
              </label>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => {
                  const file = e.target.files[0];
                  if (file) {
                    const maxSizeInBytes = 3 * 1024 * 1024;
                    if (file.size > maxSizeInBytes) {
                      toast.error(
                        "File size exceeds 3 MB limit. Please choose a smaller file."
                      );
                      return;
                    }
                    setServiceImage(file);
                    const reader = new FileReader();
                    reader.onloadend = () => setPreviewImage(reader.result);
                    reader.readAsDataURL(file);
                  }
                }}
                className="w-full px-4 py-2 border rounded-lg focus:outline-none"
              />
              {previewImage && (
                <img
                  src={previewImage}
                  alt="Preview"
                  className="w-20 h-20 object-cover mt-2"
                />
              )}
            </div>

            <div className="mb-4">
              <label className="block text-sm font-bold mb-2">
                Service Information
              </label>
              <ReactQuill
                theme="snow"
                value={serviceInfo}
                onChange={setServiceInfo}
                className="h-40"
              />
            </div>

            <button
              type="submit"
              className="bg-[#FE6929] text-white py-2 px-4 rounded-lg hover:bg-[#ff8654] transition duration-200 mt-24 md:mt-18 lg:mt-16"
            >
              Update Service
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default ServiceManage;
