import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const DeleteProject = ({ onDelete }) => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  // Fetch projects from the API
  const fetchProjects = async () => {
    try {
      const response = await fetch(
        "https://api.bhk99.com/fetch/projects"
      ); // Adjust the path as necessary
      if (!response.ok) {
        throw new Error("Failed to fetch projects");
      }
      const data = await response.json();
      setProjects(data);
    } catch (error) {
      console.error("Error fetching projects:", error);
      toast.error("Failed to load projects.");
    }
  };

  useEffect(() => {
    fetchProjects(); // Call fetchProjects when the component mounts
  }, []);

  const handleProjectSelect = (e) => {
    const projectId = e.target.value;
    setSelectedProject(projectId ? parseInt(projectId) : null);
  };

  const handleDelete = async () => {
    if (selectedProject) {
      try {
        const response = await fetch(
          `https://api.bhk99.com/delete/deleteProject/${selectedProject}`,
          {
            method: "DELETE",
          }
        );

        if (!response.ok) {
          throw new Error("Failed to delete project");
        }

        onDelete(selectedProject); // Call the onDelete function to update the UI
        setSelectedProject(null);
        toast.success("Project deleted successfully!");
      } catch (error) {
        console.error("Error deleting project:", error);
        toast.error("Failed to delete project.");
      }
    }
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Delete Project</h2>
      <div className="mb-4">
        <label
          htmlFor="projectSelect"
          className="block text-sm font-medium text-gray-700"
        >
          Select Project
        </label>
        <select
          id="projectSelect"
          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
          onChange={handleProjectSelect}
        >
          <option value="">--Select a project--</option>
          {projects.map((project) => (
            <option
              key={project.autoIncrementId}
              value={project.autoIncrementId}
            >
              {project.title}
            </option>
          ))}
        </select>
      </div>
      {selectedProject && (
        <div className="flex flex-col">
          <p className="text-sm text-gray-700 mb-2">
            Are you sure you want to delete the project "
            {
              projects.find((proj) => proj.autoIncrementId === selectedProject)
                .title
            }
            "?
          </p>
          <button
            onClick={handleDelete}
            className="w-full p-2 bg-red-600 text-white font-poppins rounded-md border-2 border-transparent hover:bg-white hover:text-red-600 hover:border-red-600"
          >
            Delete Project
          </button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default DeleteProject;
