import React, { useEffect, useState } from "react";
import axios from "axios";

export const Hero = () => {
  const [hero3DImage, setHero3DImage] = useState("");

  useEffect(() => {
    const fetch3DHeroImage = async () => {
      try {
        const response = await axios.get(
          "https://api.bhk99.com/hero/getHero"
        );
        console.log(response.data);
        setHero3DImage(`data:image/jpeg;base64,${response.data.floor3Dhero}`);
      } catch (error) {
        console.error("Error fetching 3D hero image:", error);
      }
    };

    fetch3DHeroImage();
  }, []);

  return (
    <>
      <section
        className="relative h-[500px] md:h-[520px] flex flex-col items-start justify-start bg-center"
        style={{
          backgroundImage: `url(${hero3DImage || "images/hero3D.jpg"})`,
          backgroundSize: "cover",
        }}
      >
        <div className="absolute inset-0 bg-black opacity-50"></div>
        <div className="relative z-10 text-left text-white p-8 mt-[70px] lg:mt-[200px] lg:ml-24 md:mt-44 md:ml-14">
          <h1 className="text-4xl tracking-tight leading-10 font-bold font-poppins text-white sm:text-5xl sm:leading-none md:text-6xl lg:text-7xl lg:mb-[10px] md:mb-[10px]">
            3D House Floor Plans and Designs
          </h1>
          <span className="text-gray-300 text-sm font-poppins mt-4 mb-24">
            Find the curated 3D floor plans from BHK99
          </span>
        </div>
      </section>
    </>
  );
};
