import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import ServiceDetails from "../Service Details/ServiceDetails";

const AddServiceDetailsHome = () => {
  const { serviceId } = useParams();
  const [serviceDetails, setServiceDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.get(
          `https://api.bhk99.com/api/services/${serviceId}`
        );
        if (response.data) {
          const { name, info, image } = response.data;
          setServiceDetails({ title: name, description: info, image });
        } else {
          setError("Service not found");
        }
      } catch (error) {
        setError("Error fetching service details");
      } finally {
        setLoading(false);
      }
    };

    fetchServiceDetails();
  }, [serviceId]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return <ServiceDetails service={serviceDetails} />;
};

export default AddServiceDetailsHome;
